import { Box, Button, Grid, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React from "react";
import { useTranslation } from "react-i18next";

import { config } from "../../../../../libs/config";

export const ParkingAdressItem = ({ header, content }) => {
  return (
    <Box
      sx={{
        padding: "0.3125rem 0.625rem",
        borderRadius: "8px",
        marginBottom: "1.25rem",
        backgroundColor: "rgba(196, 196, 196, 0.18)",
      }}
    >
      <Typography variant="body1" sx={{ fontSize: "0.8125rem" }}>
        {header}
      </Typography>
      <Typography variant="body1" fontWeight="900">
        {content}
      </Typography>
    </Box>
  );
};

export const ParkingAddress = ({
  address: { country, city, address_line_1: street, post_code: postCode },
  latitude,
  longitude,
}) => {
  const { t } = useTranslation();

  const onClickUrl = (url) => {
    return () => openInNewTab(url);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Box marginBottom="30px">
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: 700,
            }}
          >
            {t("parkings.tabs.general.address.title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {t("parkings.tabs.general.address.description")}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        display="flex"
        flexDirection="row"
        marginBottom="30px"
      >
        <Grid item lg={3} xs={6}>
          <ParkingAdressItem
            header={t("parkings.tabs.general.address.street")}
            content={street}
          />
        </Grid>
        <Grid item lg={3} xs={6}>
          <ParkingAdressItem
            header={t("parkings.tabs.general.address.postal_code")}
            content={postCode}
          />
        </Grid>
        <Grid item lg={3} md={3} xs={6}>
          <ParkingAdressItem
            header={t("parkings.tabs.general.address.city")}
            content={city}
          />
        </Grid>
        <Grid item lg={3} md={3} xs={6}>
          <ParkingAdressItem
            header={t("parkings.tabs.general.address.country")}
            content={country}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={4}>
          <ParkingAdressItem
            header={t("parkings.tabs.general.address.latitude")}
            content={`${Number.parseFloat(latitude).toFixed(6)} N`}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={4}>
          <ParkingAdressItem
            header={t("parkings.tabs.general.address.longitude")}
            content={`${Number.parseFloat(longitude).toFixed(6)} E`}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={4}>
          <Box>
            <Button
              variant="outlined"
              size="small"
              onClick={onClickUrl(
                `${config.GOOGLE_MAPS}${latitude}+${longitude}/`
              )}
              startIcon={<LocationOnIcon />}
            >
              {t("parkings.buttons.navigation")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
