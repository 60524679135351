import { Box, Button, RadioGroup } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { DescriptiveRadioButton } from "../../../../libs/components";
import { usePricingDispatcher, usePricingState } from "../AddPricingContext";

export const PricingTypeStep = () => {
  const { t } = useTranslation();
  const current = usePricingState(({ type }) => type);
  const [value, setValue] = React.useState(current);
  const dispatch = usePricingDispatcher();
  const handleChange = (updated) => {
    setValue(updated);
  };

  const onNextStep = () => {
    dispatch({ type: "setType", payload: value });
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginTop="40px"
        marginBottom="30px"
        bgcolor="rgba(196, 196, 196, 0.18)"
        padding="5px 15px"
        borderRadius={8}
      >
        <Box
          borderRight={1}
          fontFamily="Ubuntu"
          fontSize="20px"
          fontWeight="800"
          marginRight="10px"
          paddingRight="15px"
        >
          {t("pricing_setup.type")}
        </Box>
        <Box fontFamily="Ubuntu" fontSize="16px" fontWeight="200">
          {t("pricing_setup.type_choice")}
        </Box>
      </Box>
      <Box marginBottom="20px">
        <RadioGroup aria-label="pricing-type" value={value}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <DescriptiveRadioButton
              onChange={handleChange}
              value="basic"
              label={t("pricing_setup.normal")}
              description={t("pricing_setup.normal_desc")}
            />
            <DescriptiveRadioButton
              disabled
              onChange={handleChange}
              value="subscription"
              label={t("pricing_setup.sub")}
              description={t("pricing_setup.sub_desc")}
            />
          </Box>
        </RadioGroup>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <Button
          disabled={!value}
          id="btnModal"
          variant="contained"
          color="primary"
          size="large"
          onClick={onNextStep}
        >
          {t("buttons.next")}
        </Button>
      </Box>
    </Box>
  );
};
