import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useRemoveChargerRules } from "../../../../libs/chargers";

export const ConfirmDeleteRulesDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();
  const del = useRemoveChargerRules(content.id, content.chargerId);

  const handleDelete = () => {
    del();
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Usuwanie reguły ładowania</DialogTitle>
        <DialogContent>
          <Typography>Czy na pewno chcesz usunąć regułę ładowania?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("parkings.buttons.cancel")}</Button>
          <Button onClick={handleDelete}>{t("parkings.buttons.delete")}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
