import React from "react";
import MaterialReactTable from "material-react-table";
import { Delete, Edit } from "@material-ui/icons";
import {
  createTheme,
  ListItemIcon,
  MenuItem,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";

import { useToggleable } from "../../../../libs/utils";
import { AvailabilitiesEditDialog } from "../dialogs/AvailabilitiesEditDialog";
import { getWeekDays } from "../../../parkings/consts";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";

export const ParkingSpotRulesFormTable = ({ data, removeItem, updateItem }) => {
  const [open, { handleOpen, handleClose }] = useToggleable();
  const { t, i18n } = useTranslation();
  const [dialogContent, setDialogContent] = React.useState({});
  const displayTime = (value) => {
    return value ? moment(value, "HH:mm").format("HH:mm") : "";
  };
  const theme = useTheme();

  const columns = React.useMemo(() => [
    {
      accessorFn: (row) =>
        getWeekDays().find((item) => item.value === row.weekday).label,
      header: t("parking_spots.tables.weekday"),
    },
    {
      accessorFn: (row) => displayTime(row.start),
      header: t("parking_spots.tables.start"),
    },
    {
      accessorFn: (row) => displayTime(row.end),
      header: t("parking_spots.tables.end"),
    },
  ]);

  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        key={i18n.language}
        localization={t("tables", { returnObjects: true })}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enablePagination={false}
        enableRowVirtualization
        enableToolbarBottom={false}
        enableSorting
        enableRowActions
        positionActionsColumn="last"
        muiTableBodyCellEditTextFieldProps={{ variant: "outlined" }}
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={`${row.id}-edit`}
            onClick={() => {
              setDialogContent({ ...row.original, id: row.id });
              handleOpen();
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            {t("parking_spots.tables.edit")}
          </MenuItem>,
          <MenuItem
            key={`${row.id}-delete`}
            onClick={() => {
              removeItem(row.id);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            {t("parking_spots.tables.delete")}
          </MenuItem>,
        ]}
      />
      <AvailabilitiesEditDialog
        open={open}
        handleClose={handleClose}
        content={dialogContent}
        updateItem={updateItem}
      />
    </ThemeProvider>
  );
};
