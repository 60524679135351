import React from "react";
import { Box, FormHelperText, Input, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const GenericTextField = ({
  name,
  defaultValue = "",
  label,
  placeholder,
  id = "",
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
        <Box
          sx={{
            maxWidth: "15rem",
            width: "100%",
            marginRight: "2rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
              color: "#343434",
              marginBottom: "0.5rem",
            }}
          >
            {label}
          </Typography>
          <Input
            {...fieldProps}
            inputRef={ref}
            fullWidth
            id={id}
            placeholder={placeholder}
            error={!!error}
          />
          {error?.message && (
            <FormHelperText error>{error?.message}</FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default GenericTextField;
