import { Box, Grid } from "@material-ui/core";
import React from "react";

export const ParkingHistoryListItem = ({
  parkingSpot,
  parkingName,
  licencePlate,
  parkingStart,
  parkingEnd,
  amountPaid,
  currency,
}) => {
  return (
    <Box
      marginTop="30px"
      paddingBottom="20px"
      borderColor="primary.light"
      borderBottom={1}
      color="secondary.dark"
      display="flex"
      alignItems="center"
    >
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <Box fontSize="16px" color="#252733">
            <Box fontWeight="600" color="primary.main">
              {parkingName}
            </Box>
            <Box fontSize="14px" fontWeight="400">
              {parkingSpot}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box fontSize="16px" fontWeight="400" color="black">
            {licencePlate}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box fontSize="16px" fontWeight="400" color="black">
            {parkingStart}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box fontSize="16px" fontWeight="400" color="black">
            {parkingEnd}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box fontSize="16px" color="black">
            {amountPaid}
            {currency}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
