import "react-toastify/dist/ReactToastify.css";

import React from "react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { useAccessToken } from "./libs/auth";
import { mainPaths } from "./libs/routes/mainPaths";
import { PATHS } from "./libs/routes/paths";

export default function App() {
  return <AppContent />;
}

const AppContent = React.memo(() => {
  const getPaths = () => {
    const routes = [];
    for (const [key, value] of Object.entries(mainPaths)) {
      if (value.public) {
        routes.push(
          <AuthRoute
            exact={value?.exact ?? false}
            key={key}
            path={value.path}
            component={value.component}
          />
        );
      } else {
        routes.push(
          <PrivateRoute
            key={key}
            path={value.path}
            component={value.component}
          />
        );
      }
    }
    return routes;
  };
  return (
    <>
      <ToastContainer
        limit={3}
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
      />
      <Router>
        <Switch>
          {getPaths()}
          <Route key="not-found" path="*">
            <Redirect to={{ pathname: `${PATHS.base.login}` }} />
          </Route>
        </Switch>
      </Router>
    </>
  );
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  const accessToken = useAccessToken();

  return (
    <Route
      {...rest}
      render={(props) =>
        !accessToken ? (
          <Redirect
            to={{
              pathname: `${mainPaths.login.path}`,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const accessToken = useAccessToken();

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken ? (
          <Redirect
            to={{
              pathname: `${mainPaths.mainPage.path}`,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
