import React from "react";
import MaterialReactTable from "material-react-table";
import { Delete, Share, Visibility } from "@material-ui/icons";
import {
  createTheme,
  IconButton,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as locales from "@mui/material/locale";
import { generatePath, useHistory } from "react-router-dom";

import { ConfirmDeleteSpotDialog } from "../ConfirmDeleteSpotDialog";
import { useToggleable } from "../../../../libs/utils";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";
import { ShareFormDialog } from "../../../parking-spots/components/dialogs/ShareFormDialog";
import { PATHS } from "../../../../libs/routes/paths";

export const ParkingSpotsTable = ({ data }) => {
  const [deleteDialogContent, setDeleteDialogContent] = React.useState({});
  const [shareDialogContent, setShareDialogContent] = React.useState({});

  const { t, i18n } = useTranslation();
  const [
    deleteOpen,
    { handleOpen: handleDeleteOpen, handleClose: handleDeleteClose },
  ] = useToggleable();
  const [
    shareOpen,
    { handleOpen: handleShareOpen, handleClose: handleShareClose },
  ] = useToggleable();
  const theme = useTheme();
  const history = useHistory();

  const columns = React.useMemo(() => [
    {
      accessorKey: "parking_spot_number",
      header: t("parkings.tables.number"),
    },
    {
      accessorKey: "owner_phone_number",
      header: t("parkings.tables.owner_phone_number"),
    },
    {
      accessorFn: (row) => new Date(row.created_at),
      muiTableHeadCellFilterTextFieldProps: {
        type: "datetime",
      },
      sortingFn: "datetime",
      Cell: ({ cell }) =>
        moment(cell.getValue(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      header: t("parkings.tables.created_date"),
    },
    {
      id: "actions",
      header: t("tables.actions"),
      columnDefType: "display",
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      enableColumnOrdering: false,

      Cell: ({ row }) => (
        <>
          {row.original.permissions.can_edit && (
            <IconButton
              key={`${row.id}-view`}
              onClick={() => {
                const url = generatePath(PATHS.parkingSpots.detail, {
                  parkingSpotId: row.original.id,
                });
                history.push(`${PATHS.base.main}${url}`);
              }}
              sx={{ m: 0 }}
            >
              <Visibility />
            </IconButton>
          )}
          {row.original.permissions.can_share && (
            <IconButton
              key={`${row.id}-share`}
              onClick={() => {
                setShareDialogContent({
                  id: row.original.id,
                  name: row.original.parking_spot_number,
                });
                handleShareOpen();
              }}
              sx={{ m: 0 }}
            >
              <Share />
            </IconButton>
          )}
          {row.original.permissions.can_delete && (
            <IconButton
              key={`${row.id}-delete`}
              onClick={() => {
                setDeleteDialogContent({
                  id: row.original.id,
                  owner: row.original.owner_phone_number,
                });
                handleDeleteOpen();
              }}
              sx={{ m: 0 }}
            >
              <Delete />
            </IconButton>
          )}
        </>
      ),
    },
  ]);

  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        key={i18n.language}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableSorting
        enableRowActions={false}
        localization={t("tables", { returnObjects: true })}
      />
      {deleteOpen && (
        <ConfirmDeleteSpotDialog
          key={`delete-${deleteDialogContent.id}`}
          open={open}
          handleClose={handleDeleteClose}
          content={deleteDialogContent}
        />
      )}
      {shareOpen && (
        <ShareFormDialog
          key={`share-${shareDialogContent.id}`}
          open={open}
          handleClose={handleShareClose}
          content={shareDialogContent}
        />
      )}
    </ThemeProvider>
  );
};
