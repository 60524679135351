import { Add } from "@material-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@mui/material";
import React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select from "react-select";

import { useGroups } from "../../../../../libs/groups";
import {
  useEditParkingSpotRules,
  useGetParkingSpotRule,
} from "../../../../../libs/parking-spots";
import { usePricings } from "../../../../../libs/price-lists";
import { ParkingSpotRulesFormTable } from "../../tables/ParkingSpotRulesFormTable";

import { AvailabilitiesArrayField } from "./AvailabilitiesArrayField";

export const ParkingSpotRulesEdit = () => {
  const { parkingSpotId, ruleId } = useParams();
  const { t, i18n } = useTranslation();
  const { data: parkingRuleData, isSuccess: parkingRuleIsSuccess } =
    useGetParkingSpotRule(parkingSpotId, ruleId);
  const editParkingRules = useEditParkingSpotRules(parkingSpotId, ruleId);
  const { data: groupData, isSuccess: groupIsSuccess } = useGroups();
  const { data: pricingData, isSuccess: pricingIsSuccess } = usePricings();
  const [groups, setGroups] = React.useState([]);
  const [pricings, setPricings] = React.useState([]);
  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    defaultValues: {
      allGroups: false,
      availabilities: [],
    },
  });
  const { fields, append, remove, replace, update } = useFieldArray({
    control: methods.control,
    name: "availabilities",
  });
  const allGroups = methods.watch("allGroups", false);

  React.useEffect(() => {
    if (groupIsSuccess) {
      setGroups([...(groupData?.data ?? [])]);
    }
  }, [groupIsSuccess]);

  React.useEffect(() => {
    if (allGroups) {
      methods.setValue("groups", {
        id: 0,
        name: t("parking_spots.tables.all"),
      });
    } else {
      methods.setValue("groups", []);
    }
  }, [allGroups, i18n.language]);

  React.useEffect(() => {
    if (pricingIsSuccess) {
      setPricings(pricingData?.data ?? []);
    }
  }, [pricingIsSuccess]);

  React.useEffect(() => {
    if (parkingRuleIsSuccess && groupIsSuccess) {
      const ruleGroups = parkingRuleData?.data?.groups ?? [];
      const rulePriceList = parkingRuleData?.data?.price_list ?? null;
      if (ruleGroups.length === 0) {
        methods.setValue("allGroups", true);
      }
      methods.setValue("groups", ruleGroups);
      methods.setValue(
        "priceList",
        pricings.find((item) => item.id === rulePriceList.id)
      );
      methods.setValue("name", parkingRuleData?.data?.name ?? "");
      replace(parkingRuleData?.data?.availabilities ?? []);
    }
  }, [parkingRuleIsSuccess, groupIsSuccess, groups, pricings]);

  const onSubmit = (payload) => {
    const data = {};
    data.availabilities = payload.availabilities;
    data.parking_spot_ids = [parkingSpotId];
    data.groups = payload.allGroups
      ? []
      : payload.groups.map((item) => item.id);
    data.name = payload.name;
    data.price_list = payload.priceList.id;

    editParkingRules(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        >
          <Button
            startIcon={<Add />}
            variant="contained"
            type="submit"
            color="primary"
          >
            {t("parking_spots.buttons.save")}
          </Button>
        </Box>
        <Grid container>
          <Grid container item sm={12} md={6}>
            <Grid item xs={12} sx={{ marginBottom: "2.5rem" }}>
              <Controller
                name="name"
                control={methods.control}
                rules={{
                  required: `${t("parking_spots.fields.ruleName.required")}`,
                }}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <Box
                    sx={{
                      maxWidth: "15rem",
                      width: "100%",
                      marginRight: "2rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        color: "#343434",
                        marginBottom: "1.5rem",
                      }}
                    >
                      1. {t("parking_spots.fields.ruleName.label")}
                    </Typography>
                    <Input
                      {...fieldProps}
                      inputRef={ref}
                      fullWidth
                      id="rule-name"
                      placeholder={t(
                        "parking_spots.fields.ruleName.placeholder"
                      )}
                      error={!!error}
                    />
                    {error?.message && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ maxWidth: "31.25rem", marginBottom: "2.5rem" }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#343434",
                    marginBottom: "1.5rem",
                  }}
                >
                  2. {t("parking_spots.fields.groups.label")}
                </Typography>
                <FormControlLabel
                  key={allGroups}
                  control={
                    <Controller
                      name="allGroups"
                      control={methods.control}
                      render={({ field }) => {
                        return (
                          <>
                            <Checkbox {...field} defaultChecked={allGroups} />
                          </>
                        );
                      }}
                    />
                  }
                  label={t("parking_spots.fields.allGroups.label")}
                />
                <Controller
                  name="groups"
                  control={methods.control}
                  rules={{
                    required: !allGroups
                      ? t("parking_spots.fields.groups.required")
                      : false,
                  }}
                  defaultValue=""
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        {...fieldProps}
                        isDisabled={allGroups}
                        inputRef={ref}
                        isMulti={!allGroups}
                        options={groups}
                        getOptionLabel={(option) => option.display_name}
                        getOptionValue={(option) => option.id}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {error?.message && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "2.5rem" }}>
              <Controller
                name="priceList"
                control={methods.control}
                rules={{
                  required: t("parking_spots.fields.priceList.required"),
                }}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <Box sx={{ maxWidth: "31.25rem" }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        color: "#343434",
                        marginBottom: "1.5rem",
                      }}
                    >
                      3. {t("parking_spots.fields.priceList.label")}
                    </Typography>
                    <Select
                      {...fieldProps}
                      inputRef={ref}
                      options={pricings}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {error?.message && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <AvailabilitiesArrayField append={append} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ParkingSpotRulesFormTable
              data={fields}
              removeItem={remove}
              updateItem={update}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
