import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import MaterialReactTable from "material-react-table";
import { format } from "date-fns";

import { useTransactionsHistory } from "../../../libs/transactions";
import { config } from "../../../libs/config";

export const VirtualWalletPaymentsHistory = () => {
  const { isFetched, data } = useTransactionsHistory();
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "reason",
        header: t("wallet.transaction_type"),
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <Typography variant="body1">
                {t(`parking_type.${row.original.reason.toLowerCase()}`)}
              </Typography>

              <Typography
                variant="caption"
                sx={{
                  fontWeight: 500,
                  color: "#9FA2B4",
                }}
              >
                {format(
                  Date.parse(row.original.created_at),
                  config.DATE_TIME_FORMAT
                )}
              </Typography>
            </>
          );
        },
      },
      {
        accessorKey: "partner",
        header: t("wallet.price"),
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <Typography
                variant="body1"
                color={
                  row.original.user_price.price > 0 ? "primary.main" : "red"
                }
              >
                {row.original.user_price.price}&nbsp;
                {row.original.user_price.currency}
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: 200 }}
                color={row.original.price.price > 0 ? "primary.main" : "red"}
              >
                {row.original.price.price}&nbsp;
                {row.original.price.currency}
              </Typography>
            </>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      {isFetched && (
        <MaterialReactTable
          columns={columns}
          data={data.data}
          enableColumnActions={false}
          enableColumnDragging={false}
          enableHiding={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableRowActions={false}
          positionActionsColumn="last"
          enableSorting={false}
          enableColumnFilters={false}
          enableGlobalFilter={false}
        />
      )}
    </>
  );
};
