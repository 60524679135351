import React from "react";
import { Controller, useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "yup-phone";
import { Box } from "@mui/system";
import Select from "react-select";
import {
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Add } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAddParkingSpotAccesses } from "../../../../../libs/parking-spots";
import i18n from "../../../../../i18n";
import { useGroups } from "../../../../../libs/groups";
import {
  parkingSpotPermissions,
  parkingSpotPermissionsCheckboxesList,
} from "../../../consts";

import { PermissionCheckboxes } from "./PermissionCheckboxes";

const validationSchema = () =>
  yup
    .object()
    .required()
    .optional()
    .shape({
      phone_number: yup.string().when("type", {
        is: "user",
        then: yup
          .string()
          .required(i18n.t("parking_spots.fields.phone_number.required"))
          .phone(
            "",
            false,
            i18n.t("parking_spots.fields.phone_number.invalid")
          ),
        otherwise: yup.string(),
      }),
      group: yup
        .object()
        .nullable()
        .shape({})
        .when("type", {
          is: "group",
          then: yup
            .object()
            .shape({})
            .nullable()
            .required(i18n.t("parking_spots.fields.groups.singleRequired")),
        }),
    });

export const ParkingSpotAccessesAdd = () => {
  const { parkingSpotId } = useParams();
  const { t } = useTranslation();
  const addSpotAccess = useAddParkingSpotAccesses(parkingSpotId);
  const defaultPermissions = React.useMemo(
    () =>
      parkingSpotPermissionsCheckboxesList
        .filter((item) => item.default)
        .map((item) => item.value),
    [parkingSpotPermissionsCheckboxesList]
  );
  const [checkedItems, setCheckedItems] = React.useState(defaultPermissions);
  const [groups, setGroups] = React.useState([]);
  const { data: groupData, isSuccess: groupIsSuccess } = useGroups();

  const localeValidationSchema = React.useMemo(
    () => validationSchema(),
    [i18n.language]
  );

  const accessAddingMethods = useForm({
    defaultValues: {
      phone_number: "",
      permissionsType: "default",
      type: "user",
      permissions: defaultPermissions,
    },
    criteriaMode: "all",
    mode: "onSubmit",
    resolver: yupResolver(localeValidationSchema),
  });

  const type = accessAddingMethods.watch("type");

  const onSubmit = async (payload) => {
    const data = {};
    data.permissions = [...checkedItems];
    if (payload.type === "user") {
      data.phone_number = payload.phone_number;
    } else {
      data.group_id = payload.group.id;
    }
    addSpotAccess(data);
  };

  React.useEffect(() => {
    if (groupIsSuccess) {
      setGroups([...(groupData?.data ?? [])]);
    }
  }, [groupIsSuccess]);

  return (
    <FormProvider {...accessAddingMethods}>
      <form onSubmit={accessAddingMethods.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        >
          <Button
            startIcon={<Add />}
            variant="contained"
            type="submit"
            color="primary"
          >
            {t("parking_spots.buttons.save")}
          </Button>
        </Box>
        <Grid container sx={{ columnGap: "3rem" }}>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: "2rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 700,
                color: "#343434",
                marginBottom: "1.5rem",
              }}
            >
              1. {t("parking_spots.fields.type.label")}
            </Typography>
            <Grid item xs={12} lg={12}>
              <Controller
                name="type"
                control={accessAddingMethods.control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      label={t("parking_spots.fields.type.userLabel")}
                      control={<Radio {...field} value="user" />}
                    />
                    <FormControlLabel
                      label={t("parking_spots.fields.type.groupLabel")}
                      control={<Radio {...field} value="group" />}
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
          </Grid>
          {type === "user" && (
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                marginBottom: "2rem",
              }}
            >
              <Controller
                name="phone_number"
                control={accessAddingMethods.control}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <Box sx={{ maxWidth: "fit-content" }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        color: "#343434",
                        marginBottom: "1.5rem",
                      }}
                    >
                      2. {t("parking_spots.fields.phone_number.label")}
                    </Typography>
                    <Input
                      {...fieldProps}
                      inputRef={ref}
                      fullWidth
                      placeholder={t(
                        "parking_spots.fields.phone_number.placeholder"
                      )}
                      error={!!error}
                    />
                    {error?.message && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
          )}
          {type === "group" && (
            <Grid item xs={12}>
              <Box sx={{ maxWidth: "31.25rem", marginBottom: "2.5rem" }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#343434",
                    marginBottom: "1.5rem",
                  }}
                >
                  2. {t("parking_spots.fields.groups.label")}
                </Typography>
                <Controller
                  name="group"
                  control={accessAddingMethods.control}
                  rules={{
                    required: t("parking_groups.fields.groups.singleRequired"),
                  }}
                  defaultValue=""
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        {...fieldProps}
                        isDisabled={false}
                        inputRef={ref}
                        isMulti={false}
                        options={groups}
                        placeholder={t("parkings.fields.groups.placeholder")}
                        getOptionLabel={(option) => option.display_name}
                        getOptionValue={(option) => option.id}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {error?.message && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
            </Grid>
          )}
          <Grid item container xs={12} lg={8}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 700,
                color: "#343434",
                marginBottom: "1.5rem",
              }}
            >
              2. {t("groups.add_permissions")}
            </Typography>
            <Grid item container spacing={3}>
              {[
                parkingSpotPermissions.assignPermissionsParkingSpot.permission,
              ].includes(
                parkingSpotPermissions.assignPermissionsParkingSpot.permission
              ) && (
                <PermissionCheckboxes
                  permissionList={parkingSpotPermissionsCheckboxesList}
                  checkedItems={checkedItems}
                  setCheckeditems={setCheckedItems}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
