import { useQuery } from "react-query";

import { useApi } from "../../libs/api";
import { config } from "../../libs/config";

export const usePromotionPrice = () => {
  const { get } = useApi();

  return useQuery(
    "promotion-price",
    get(`${config.endpoints.PROMOTION_PRICE}`)
  );
};

export const useParkingHistory = (parkingId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-history-reservations", parkingId],
    get(`${config.endpoints.PARTNER_RESERVATIONS}${parkingId}/reservations/`),
    {
      enabled: !!parkingId,
    }
  );
};
