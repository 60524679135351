import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import {
  useAddParkingSpotSubscription,
  useEditParkingSpotSubscription,
} from "../../../../libs/parkings";

export const RestrictionsDialog = ({
  open,
  handleClose,
  content,
  payload,
  parkingId,
  subscriptionId,
  isEdit,
}) => {
  const { t } = useTranslation();
  const addSpotAccess = useAddParkingSpotSubscription(parkingId);
  const editSpotSubscription = useEditParkingSpotSubscription(
    subscriptionId,
    parkingId
  );
  const handleSubmit = () => {
    if (isEdit) {
      editSpotSubscription(payload);
    } else {
      addSpotAccess(payload);
    }
    handleClose();
  };

  const getRestriction = (item, index) => {
    return (
      <Typography variant="body2" key={index + 1} style={{ color: "#FF0000" }}>
        {index + 1}. {format(new Date(item.start), "yyyy-MM-dd HH:mm")} -{" "}
        {format(new Date(item.end), "yyyy-MM-dd HH:mm")}
      </Typography>
    );
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("parkings.dialogs.restrictions_title")}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" style={{ marginBottom: "1.5rem" }}>
            {t("parkings.dialogs.restrictions_text_1")}
          </Typography>
          {content.map((item, index) => {
            return getRestriction(item, index);
          })}
          <Typography variant="body2" style={{ marginTop: "1.5rem" }}>
            {t("parkings.dialogs.restrictions_text_2")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("parkings.buttons.back")}</Button>
          <Button onClick={handleSubmit}>{t("parkings.buttons.accept")}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
