import { PATHS } from "../../../../libs/routes/paths";

import { ParkingSpotAdd } from "./parking_spots/ParkingSpotAdd";
import { ParkingSpotList } from "./parking_spots/ParkingSpotList";

export const PARKINGS_SPOTS = {
  list: {
    path: PATHS.parkings.tabs.parkingSpots.list,
    component: ParkingSpotList,
    exact: true,
  },
  create: {
    path: PATHS.parkings.tabs.parkingSpots.create,
    component: ParkingSpotAdd,
  },
};

export const parkingSpotPaths = [...Object.values(PARKINGS_SPOTS)];
