import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { TitlePageTopBar } from "../../components/commons/TitlePageTopBar";
// import { AsyncDataWrapper } from "../libs/api";

import { ListForParkingHistory } from "./components/ListForParkingHistory";

export const ParkingHistory = () => {
  const { t } = useTranslation();
  return (
    <Box
      fontFamily="Ubuntu"
      fontSize="24px"
      fontWeight="600"
      marginBottom="100px"
      height="100vh"
    >
      <Box>
        <TitlePageTopBar label={t("parking_history.title")} />
      </Box>
      <ListForParkingHistory subTitle={t("parking_history.sub_title")} />
    </Box>
  );
};
