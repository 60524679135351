import Grid from "@mui/material/Grid";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Hidden, Tab, Tabs } from "@mui/material";

import { useUserDetails } from "../../libs/user";
import { GenericContentBox } from "../../components/commons/GenericContentBox";
import { VirtualWallet } from "../user-transactions-history/VirtualWallet";

import { BasicData } from "./basic";
import { YourCards } from "./loyalty-cards";
import { YourVehicles } from "./vehicles";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const UserProfile = () => {
  const { data, isSuccess } = useUserDetails();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("user_panel_breadcrumb"),
        path: "",
      },
    ],
    []
  );
  return (
    <GenericContentBox breadcrumbs={breadcrumbs} title={t("user_panel")}>
      <Grid
        container
        spacing={0}
        columnSpacing={3}
        rowSpacing={{ md: 4 }}
        sx={{ marginTop: "2rem" }}
      >
        <Grid
          item
          container
          md={12}
          lg={2}
          sx={{ marginRight: "1rem", alignItems: "baseline" }}
        >
          {isSuccess && <BasicData {...data.data} />}
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Hidden lgUp>
          <Grid item xs={12}>
            <Divider orientation="horizontal" />
          </Grid>
        </Hidden>

        <Grid container item md={12} lg={9}>
          <Grid item xs={12}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="profile tabs"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  label={t("vehicles.vehicles_title")}
                  sx={{ marginRight: "3rem" }}
                />
                <Tab
                  label={t("loyalty_cards.tab_title")}
                  sx={{ marginRight: "3rem" }}
                />
                <Tab label={t("wallet.tab_title")} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <YourVehicles />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <YourCards />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <VirtualWallet />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </GenericContentBox>
  );
};
