import React from "react";
import MaterialReactTable from "material-react-table";
import {
  createTheme,
  IconButton,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { Visibility } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";

import { PATHS } from "../../../libs/routes/paths";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../libs/utils/consts";

export const ParkingsListTable = ({ data }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const columns = [
    {
      accessorFn: (row) => (
        <img style={{ maxHeight: "3.125rem" }} alt="" src={row.main_image} />
      ),
      header: t("parkings.tables.photo"),
      enableColumnFilter: false,
      enableColumnOrdering: false,
    },
    {
      accessorKey: "name",
      header: t("parkings.tables.name"),
    },
    {
      accessorFn: (row) => (
        <>
          <Typography variant="body2">{row.address.address_line_1}</Typography>
          <Typography variant="body2">
            {row.address.post_code}, {row.address.city}
          </Typography>
        </>
      ),
      header: t("parkings.tables.address"),
      enableColumnOrdering: false,
    },
    {
      accessorFn: (row) => row?.parking_spots_count ?? 0,
      header: t("parkings.tables.parking_spots_count"),
    },
    {
      id: "actions",
      header: t("tables.actions"),
      columnDefType: "display",
      enableColumnOrdering: false,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ row }) => (
        <>
          <IconButton
            key={`${row.id}-view`}
            onClick={() => {
              const url = generatePath(PATHS.parkings.detail, {
                parkingId: row.original.id,
              });
              history.push(`${PATHS.base.main}${url}`);
            }}
          >
            <Visibility />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        key={i18n.language}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableRowActions={false}
        enableSorting
        localization={t("tables", { returnObjects: true })}
      />
    </ThemeProvider>
  );
};
