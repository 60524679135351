import { Box, Button, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

import { TitlePageTopBarBusiness } from "../../components/commons/TitlePageTopBarBusiness";
import { AsyncDataWrapper } from "../../libs/api";
import { usePartnerDetails } from "../../libs/user";
import { config } from "../../libs/config";

import { PromotionListItem } from "./PromotionListItem";
import { usePromotionPrice } from "./data";

export const DiscountPage = () => {
  const { t } = useTranslation();
  const { data, isSuccess } = usePromotionPrice();
  const result = usePartnerDetails();

  const mobileActive = useMediaQuery("(max-width: 414px)");
  const [promoList, setPromoList] = useState([]);
  const [activePromo, setActivePromo] = useState({});

  React.useEffect(() => {
    if (isSuccess) {
      setPromoList(data?.data ?? []);
      setActivePromo(data?.data?.[0] ?? {});
    }
  }, [isSuccess]);
  const handleChange = (id) => {
    const promo = promoList.find((item) => item.qr_promo === id);
    setActivePromo(promo || {});
  };

  //TODO: add translations
  //TODO: content is to big and not scrollable - in my case I didn't see the text below the QR code by default
  return (
    <Box>
      <AsyncDataWrapper result={result}>
        {({ data }) => (
          <TitlePageTopBarBusiness
            label={t("business_client.title")}
            result={data}
            returnButton={
              <Box marginRight="10px" color="primary.main" borderRight={1}>
                <Link to="/welcome-page" style={{ textDecoration: "none" }}>
                  <Button startIcon={<ArrowBack />} size="medium">
                    {t("buttons.return")}
                  </Button>
                </Link>
              </Box>
            }
          />
        )}
      </AsyncDataWrapper>
      {promoList.length > 0 && (
        <Box
          fontFamily="Ubuntu"
          fontSize="16px"
          fontWeight="600"
          className="top-title-bar"
        >
          <Box
            display="flex"
            flexDirection={(mobileActive && "column") || "row"}
            alignItems="center"
          >
            <Box>{t("business_client.qr_options")}:</Box>

            <Box display="flex" flexDirection="row">
              {promoList.map((item) => (
                <PromotionListItem
                  name={item.price_list_name}
                  //TODO: should not pass empty key
                  key={item.qr_promo}
                  id={item.qr_promo}
                  handleChange={handleChange}
                />
              ))}
            </Box>
          </Box>

          {Object.values(activePromo).length > 0 && (
            <Box
              display="flex"
              justifyContent="center"
              marginBottom={(mobileActive && "10px") || "30px"}
            >
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  margin={(mobileActive && "10px 0 20px 0") || "60px 0 20px 0"}
                  fontSize="1.5rem"
                >
                  {t("business_client.qr_title")}
                </Box>

                <Box
                  color="primary.main"
                  fontWeight="800"
                  fontSize={(mobileActive && "1.5rem") || "2.5rem"}
                  textAlign="center"
                  margin={(mobileActive && "0px 0 5px 0") || "0px 0 20px 0"}
                >
                  {activePromo?.price_list_name ?? "-"}
                </Box>
                {activePromo?.qr_expiration_date ? (
                  <>
                    <Box>{t("business_client.qr_expiration")}:</Box>
                    <Box
                      margin={
                        (mobileActive && "0px 0 20px 0") || "0px 0 70px 0"
                      }
                    >
                      {format(
                        Date.parse(activePromo.qr_expiration_date),
                        config.DATE_FORMAT
                      )}
                    </Box>
                  </>
                ) : (
                  <Box
                    margin={(mobileActive && "0px 0 20px 0") || "0px 0 70px 0"}
                  />
                )}

                <QRCode
                  value={activePromo.qr_promo}
                  size={(mobileActive && 200) || 300}
                />

                <Box
                  fontSize={(mobileActive && "2.0rem") || "3.5rem"}
                  fontFamily="Open sans"
                  fontWeight="200"
                  margin={(mobileActive && "5px 0") || "20px 0"}
                >
                  {activePromo.qr_promo}
                </Box>
              </Box>
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Box
              fontSize={(mobileActive && "1.5rem") || "2rem"}
              fontFamily="Open sans"
              fontWeight="800"
              marginBottom="10px"
            >
              {t("business_client.qr_subtitle_1")}
            </Box>
            {!mobileActive && (
              <Box fontSize="1.5rem" fontFamily="Open sans" fontWeight="200">
                {t("business_client.qr_subtitle_2")}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
