import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Input,
  Typography,
  Grid,
  ButtonGroup,
} from "@mui/material";
import { Add } from "@material-ui/icons";
import React from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { generatePath, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";

import {
  useAddUserToGroup,
  useDeleteUserFromGroup,
  useEditGroup,
  useEditUserGroupPermission,
  useGetGroup,
} from "../../libs/groups";
import { GenericContentBox } from "../../components/commons/GenericContentBox";
import { PATHS } from "../../libs/routes/paths";

import { PermissionCheckboxes } from "./components/PermissionCheckboxes";
import { groupPermissions, groupPermissionsCheckboxesList } from "./consts";
import { UserGroupTable } from "./components/UserGroupTable";

const validationUserAddingSchema = yup
  .object()
  .required()
  .shape({
    phone_number: yup
      .string()
      .required("Phone number is required")
      .phone("", false, "Must be a valid phone number"),
  });

export const GroupEdit = () => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const [editGroupName, setEditGroupName] = React.useState(false);
  const [groupName, setGroupName] = React.useState("");
  const { data, isSuccess } = useGetGroup(groupId);
  const [users, setUsers] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const addUser = useAddUserToGroup(groupId, setUsers);
  const deleteUser = useDeleteUserFromGroup(groupId, setUsers);
  const editGroup = useEditGroup(groupId);
  const editUserGroupPermissions = useEditUserGroupPermission(
    groupId,
    setUsers
  );

  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("groups.breadcrumbs.main"),
        path: `${PATHS.base.main}${PATHS.groups.list}`,
      },
      {
        title: `${data?.data?.display_name}`,
        path: `${PATHS.base.main}${generatePath(PATHS.groups.detail, {
          groupId,
        })}`,
      },
      { title: t("groups.breadcrumbs.edit"), path: "" },
    ],
    [data]
  );
  const defaultPermissions = React.useMemo(
    () => groupPermissionsCheckboxesList.find((item) => item.default),
    [groupPermissionsCheckboxesList]
  );
  const [checkedItems, setCheckedItems] = React.useState([
    defaultPermissions.value,
  ]);

  const canDelete = React.useMemo(
    () => permissions.includes(groupPermissions.deleteUserFromGroup.permission),
    [permissions, groupPermissions]
  );

  const showActionRow = React.useMemo(
    () =>
      permissions.some((item) =>
        [
          groupPermissions.setPermissionsToUser.permission,
          groupPermissions.deleteUserFromGroup.permission,
        ].includes(item)
      ),
    [groupPermissions, permissions]
  );
  const { handleSubmit, control } = useForm({
    criteriaMode: "onSubmit",
    mode: "onSubmit",
    // resolver: yupResolver(validationSchema),
  });

  const userAddingMethods = useForm({
    defaultValues: {
      userType: "user",
      permissions: [defaultPermissions.value],
    },
    criteriaMode: "onSubmit",
    mode: "onSubmit",
    resolver: yupResolver(validationUserAddingSchema),
  });

  React.useEffect(() => {
    if (isSuccess) {
      setUsers(data?.data?.users ?? []);
      setGroupName(data?.data?.display_name);
      setPermissions(data?.data?.permissions ?? []);
    }
  }, [isSuccess]);

  const onAddingUserSubmit = userAddingMethods.handleSubmit((payload) => {
    const data = {};
    data.permissions = payload.userType === "admin" ? [...checkedItems] : [];
    data.phone_number = payload.phone_number;
    addUser(data);
    userAddingMethods.reset();
    setCheckedItems([defaultPermissions.value]);
  });

  const onSubmit = handleSubmit((payload) => {
    const data = { ...payload, display_name: payload.display_name };
    editGroup(data);
  });

  return (
    <GenericContentBox title={t("groups.main_title")} breadcrumbs={breadcrumbs}>
      <form style={{ marginBottom: "1.5rem" }} onSubmit={onSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: `${editGroupName ? "flex-end" : "space-between"}`,
          }}
        >
          {!editGroupName && (
            <Box sx={{ display: "inline-flex" }}>
              <Typography variant="h5" color="primary" mr="1rem">
                {groupName}
              </Typography>
              {permissions.includes(groupPermissions.editGroup.permission) && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setEditGroupName(true)}
                >
                  {t("groups.buttons.edit_name")}
                </Button>
              )}
            </Box>
          )}
          <ButtonGroup>
            <Button
              variant="text"
              onClick={() => {
                history.back();
              }}
              color="primary"
            >
              {t("groups.buttons.back")}
            </Button>
            {editGroupName && (
              <Button
                startIcon={<Add />}
                variant="contained"
                type="submit"
                color="primary"
              >
                {t("groups.buttons.save_changes")}
              </Button>
            )}
          </ButtonGroup>
        </Box>
        {editGroupName && (
          <Box
            sx={{
              maxWidth: "15rem",
              width: "100%",
            }}
          >
            <Controller
              name="name"
              control={control}
              defaultValue={groupName}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      color: "#343434",
                      marginBottom: "1.5rem",
                    }}
                  >
                    1. {t("groups.group_name")}
                  </Typography>
                  <Input
                    {...fieldProps}
                    inputRef={ref}
                    fullWidth
                    id="group-name"
                    placeholder={t("groups.group_name_field")}
                    aria-describedby="group-name-error-text"
                    error={!!error}
                  />
                  {error?.message && (
                    <FormHelperText error id="group-name-error-text">
                      {error?.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Box>
        )}
      </form>

      <Divider sx={{ marginBottom: "2.5rem" }} />
      {permissions.includes(groupPermissions.addUserToGroup.permission) && (
        <>
          <FormProvider {...userAddingMethods}>
            <form onSubmit={onAddingUserSubmit}>
              <Grid
                container
                columnSpacing={{ xs: 1 }}
                rowSpacing={{ sm: 3, lg: 5 }}
                columnGap="3rem"
                sx={{
                  marginBottom: "2.5rem",
                }}
              >
                <Grid item xs={12} sm="auto">
                  <Controller
                    name="phone_number"
                    control={userAddingMethods.control}
                    defaultValue=""
                    render={({
                      field: { ref, ...fieldProps },
                      fieldState: { error },
                    }) => (
                      <Box sx={{ marginBottom: "2.5rem" }}>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: 700,
                            color: "#343434",
                            marginBottom: "1.5rem",
                          }}
                        >
                          2. {t("groups.buttons.add_user")}
                        </Typography>
                        <Input
                          {...fieldProps}
                          inputRef={ref}
                          fullWidth
                          id="group-name"
                          placeholder={t("groups.table.phone_number")}
                          error={!!error}
                        />
                        {error?.message && (
                          <FormHelperText id="group-name-error-text">
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Box>
                    )}
                  />
                  <Button
                    startIcon={<Add />}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    {t("groups.buttons.add_user")}
                  </Button>
                </Grid>
                <Grid item container xs={12} lg={8}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      color: "#343434",
                      marginBottom: "1.5rem",
                    }}
                  >
                    3. {t("groups.add_permissions")}
                  </Typography>
                  <Grid item container spacing={3}>
                    {permissions.includes(
                      groupPermissions.setPermissionsToUser.permission
                    ) ? (
                      <PermissionCheckboxes
                        permissionList={groupPermissionsCheckboxesList}
                        checkedItems={checkedItems}
                        setCheckeditems={setCheckedItems}
                      />
                    ) : (
                      <Controller
                        name="userType"
                        control={control}
                        defaultValue="user"
                        render={({ field: { ref, ...fieldProps } }) => (
                          <input {...fieldProps} ref={ref} type="hidden" />
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          <Divider sx={{ marginBottom: "2.5rem" }} />
        </>
      )}
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: 700,
          color: "#343434",
          marginBottom: "1.5rem",
        }}
      >
        4. {t("groups.users_list")}
      </Typography>
      <UserGroupTable
        key={permissions.length}
        users={users}
        permissions={permissions}
        deleteUser={deleteUser}
        enableRowActions={showActionRow}
        enableRowSelection={canDelete}
        editUserGroupPermissions={editUserGroupPermissions}
      />
    </GenericContentBox>
  );
};
