import { Add } from "@material-ui/icons";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

import { useGetParkingSpotAccesses } from "../../../../../libs/parking-spots";
import { PATHS } from "../../../../../libs/routes/paths";
import { ParkingSpotAccessesTable } from "../../tables/ParkingSpotAccessesTable";

export const ParkingSpotAccessesList = () => {
  const { t } = useTranslation();

  const { parkingSpotId } = useParams();
  const { data, isSuccess } = useGetParkingSpotAccesses(parkingSpotId);
  const detailUrl = generatePath(PATHS.parkingSpots.detail, { parkingSpotId });
  return (
    <>
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: 700,
          }}
        >
          Współwłaściciele
        </Typography>
        <Button
          component={Link}
          to={`${PATHS.base.main}${detailUrl}${PATHS.parkingSpots.tabs.parkingSpotAccesses.main}${PATHS.parkingSpots.tabs.parkingSpotAccesses.create}`}
          startIcon={<Add />}
          variant="text"
          color="primary"
          sx={{
            fontSize: "1rem",
          }}
        >
          {t("parking_spots.buttons.add_access")}
        </Button>
      </Box>
      {isSuccess && <ParkingSpotAccessesTable data={data?.data ?? []} />}
    </>
  );
};
