import "./ParkingSubscriptionDetailModal.css";
import React from "react";
import { Popup, Button } from "@mobiscroll/react";
import { parseISO, format } from "date-fns";
import { generatePath, Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHS } from "../../../../../libs/routes/paths";

export const ParkingSubscriptionDetailModal = ({
  isOpen,
  anchor,
  closePopup,
  eventData,
  setDeleteDialogContent,
  handleDeleteOpen,
}) => {
  const { parkingId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const getReservationRange = () => {
    return `${parseISO(eventData?.from)?.toLocaleDateString() ?? "-"} - ${
      parseISO(eventData?.to)?.toLocaleDateString() ?? "-"
    }`;
  };

  const onRemove = () => {
    setDeleteDialogContent({
      id: eventData.id,
      reservationRange: getReservationRange(),
      reservationType: eventData.resource_type,
    });
    handleDeleteOpen();
    closePopup();
  };

  return (
    <Popup
      display="anchored"
      onClose={closePopup}
      isOpen={isOpen}
      anchor={anchor}
      theme="material"
      themeVariant="light"
      contentPadding={false}
      width={350}
      cssClass="md-tooltip"
    >
      <div>
        <div
          className={`${
            eventData.actions
              ? "md-tooltip-header"
              : "md-tooltip-external-header"
          }`}
        >
          <span className="md-tooltip-name-age">{eventData?.title ?? "-"}</span>
          <span className="md-tooltip-time">
            {format(parseISO(eventData?.start), "HH:mm") ?? "-"} -{" "}
            {format(parseISO(eventData?.end), "HH:mm") ?? "-"}
          </span>
        </div>
        <div className="md-tooltip-info">
          <div className="md-tooltip-title">
            {t("parkings.dialogs.subscriptions_license_plate")}:{" "}
            <span className="md-tooltip-status md-tooltip-text">
              {eventData?.license_plate ?? "-"}
            </span>
          </div>
          <div className="md-tooltip-title">
            {t("parkings.dialogs.subscriptions_phone_number")}:{" "}
            <span className="md-tooltip-reason md-tooltip-text">
              {eventData?.phone_number ?? "-"}
            </span>
          </div>
          <div className="md-tooltip-title">
            {t("parkings.dialogs.subscriptions_duration")}:{" "}
            <span className="md-tooltip-location md-tooltip-text">
              {getReservationRange()}
            </span>
          </div>
            <>
              {eventData.can_edit && (
                  <Button
                  color="secondary"
                  className="md-tooltip-view-button"
                  component={Link}
                  onClick={() => {
                    const detailUrl = generatePath(PATHS.parkings.detail, {
                      parkingId,
                    });
                    const editUrl = generatePath(
                        PATHS.parkings.tabs.parkingSubscriptions.edit,
                        {
                          subscriptionId: eventData.id,
                        }
                    );
                    history.push(
                        `${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingSubscriptions.main}${editUrl}`
                    );
                  }}
              >
                {t("parkings.buttons.edit")}
              </Button>
              )}
              {eventData.can_delete && (
                  <Button
                  color="danger"
                  variant="outline"
                  className="md-tooltip-delete-button"
                  onClick={onRemove}
              >
                {t("parkings.buttons.remove_reservation")}
              </Button>
              )}
            </>
        </div>
      </div>
    </Popup>
  );
};
