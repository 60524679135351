import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

import {useRemoveParkingReservation, useRemoveParkingSubscription} from "../../../../libs/parkings";

export const ConfirmDeleteCalendarEventDialog = ({
  open,
  handleClose,
  content,
  setShouldRefetch,
}) => {
  const { t } = useTranslation();
  const { reservationRange } = content;
  const removeSubscription = useRemoveParkingSubscription(content?.id ?? null);
  const removeReservation = useRemoveParkingReservation(content?.id ?? null);
  const handleDelete = () => {
    if (content.reservationType === "RESERVATION") {
      removeReservation();
    } else {
      removeSubscription();
    }
    handleClose();
    setShouldRefetch(true);
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {t("parkings.dialogs.delete_subscription_title")}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ marginBottom: "0.625rem" }}>
            {t("parkings.dialogs.delete_subscription_text")}
          </Typography>
          <Typography>
            <Trans
              i18nKey="parkings.dialogs.delete_subscription_with_reservation_range"
              userCount={content.reservationRange}
            >
              Czas trwania: {{ reservationRange }}
            </Trans>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("parkings.buttons.cancel")}</Button>
          <Button onClick={handleDelete}>{t("parkings.buttons.delete")}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
