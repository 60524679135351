import React from "react";
import MaterialReactTable from "material-react-table";
import { Delete, Edit } from "@material-ui/icons";
import {
  createTheme,
  Grid,
  IconButton,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as locales from "@mui/material/locale";

import { useToggleable } from "../../../../libs/utils";
import { AvailabilitiesEditDialog } from "../dialogs/AvailabilitiesEditDialog";
import { getWeekDays } from "../../consts";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";

export const ParkingRulesFormTable = ({ data, removeItem, updateItem }) => {
  const [open, { handleOpen, handleClose }] = useToggleable();
  const { t, i18n } = useTranslation();
  const [dialogContent, setDialogContent] = React.useState({});
  const theme = useTheme();

  const displayTime = (value) => {
    return value ? moment(value, "HH:mm").format("HH:mm") : "";
  };
  const columns = React.useMemo(() => [
    {
      accessorFn: (row) =>
        getWeekDays().find((item) => item.value === row.weekday).label,
      header: t("parkings.tables.weekday"),
    },
    {
      accessorFn: (row) => displayTime(row.start),
      header: t("parkings.tables.start"),
    },
    {
      accessorFn: (row) => displayTime(row.end),
      header: t("parkings.tables.end"),
    },
    {
      id: "actions",
      header: t("tables.actions"),
      columnDefType: "display",
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      enableColumnOrdering: false,

      Cell: ({ row }) => (
        <>
          <IconButton
            key={`${row.id}-edit`}
            onClick={() => {
              setDialogContent({ ...row.original, id: row.id });
              handleOpen();
            }}
            sx={{ m: 0 }}
          >
            <Edit />
          </IconButton>
          <IconButton
            key={`${row.id}-delete`}
            onClick={() => {
              removeItem(row.id);
            }}
            sx={{ m: 0 }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ]);

  return (
    <Grid item xs={12}>
      <ThemeProvider
        theme={createTheme(
          theme,
          locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
        )}
      >
        <MaterialReactTable
          key={i18n.language}
          localization={t("tables", { returnObjects: true })}
          columns={columns}
          data={data}
          enableColumnActions={false}
          enableColumnDragging={false}
          enableHiding={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enablePagination={false}
          enableRowVirtualization
          enableToolbarBottom={false}
          enableSorting
          enableRowActions={false}
          muiTableBodyCellEditTextFieldProps={{ variant: "outlined" }}
        />
        <AvailabilitiesEditDialog
          open={open}
          handleClose={handleClose}
          content={dialogContent}
          updateItem={updateItem}
        />
      </ThemeProvider>
    </Grid>
  );
};
