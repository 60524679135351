import { Add } from "@material-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@mui/material";
import React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select from "react-select";

import { useGroups } from "../../../../../libs/groups";
import {
  useAddParkingRules,
  useGetParkingSpots,
} from "../../../../../libs/parkings";
import { usePricings } from "../../../../../libs/price-lists";
import { ParkingRulesFormTable } from "../../tables/ParkingRulesFormTable";

import { AvailabilitiesArrayField } from "./AvailabilitiesArrayField";
import { ParkingSpotTransferListField } from "./ParkingSpotTransferListField";

export const ParkingRulesAdd = () => {
  const { parkingId } = useParams();
  const { t, i18n } = useTranslation();
  const addParkingRules = useAddParkingRules(parkingId);
  const { data: groupData, isSuccess: groupIsSuccess } = useGroups();
  const { data: pricingData, isSuccess: pricingIsSuccess } = usePricings();
  const { data: spotsData, isSuccess: spotsIsSuccess } =
    useGetParkingSpots(parkingId);
  const [spots, setSpots] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [pricings, setPricings] = React.useState([]);
  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    defaultValues: {
      allGroups: false,
      availabilities: [],
    },
  });

  const { fields, append, remove, update } = useFieldArray({
    control: methods.control,
    name: "availabilities",
  });
  const allGroups = methods.watch("allGroups", false);

  React.useEffect(() => {
    if (groupIsSuccess) {
      setGroups([...(groupData?.data ?? [])]);
    }
  }, [groupIsSuccess]);

  React.useEffect(() => {
    if (spotsIsSuccess) {
      setSpots([
        ...(spotsData?.data?.filter((item) => item.permissions.can_edit) ?? []),
      ]);
    }
  }, [spotsIsSuccess]);

  React.useEffect(() => {
    if (allGroups) {
      methods.setValue("groups", { id: 0, name: t("parkings.tables.all") });
    } else {
      methods.setValue("groups", []);
    }
  }, [allGroups, i18n.language]);

  React.useEffect(() => {
    if (pricingIsSuccess) {
      setPricings(pricingData?.data ?? []);
    }
  }, [pricingIsSuccess]);

  const onSubmit = (payload) => {
    const data = {};
    data.availabilities = payload.availabilities;
    data.groups = payload.allGroups
      ? []
      : payload.groups.map((item) => item.id);
    data.price_list = payload.priceList.id;
    data.name = payload.name;
    data.parking_spot_ids = payload.parkingSpots.map((item) => item.id);
    data.parking_id = parkingId;
    addParkingRules(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        >
          <Button
            startIcon={<Add />}
            variant="contained"
            type="submit"
            color="primary"
          >
            {t("parkings.buttons.save")}
          </Button>
        </Box>
        <Grid container>
          <Grid container item sm={12} lg={7} xl={6}>
            <Grid item xs={12} sx={{ marginBottom: "2.5rem" }}>
              <Controller
                name="name"
                control={methods.control}
                rules={{
                  required: `${t("parkings.fields.ruleName.required")}`,
                }}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <Box
                    sx={{
                      maxWidth: "15rem",
                      width: "100%",
                      marginRight: "2rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        color: "#343434",
                        marginBottom: "1.5rem",
                      }}
                    >
                      1. {t("parkings.fields.ruleName.label")}
                    </Typography>
                    <Input
                      {...fieldProps}
                      inputRef={ref}
                      fullWidth
                      id="rule-name"
                      placeholder={t("parkings.fields.ruleName.placeholder")}
                      error={!!error}
                    />
                    {error?.message && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ maxWidth: "31.25rem", marginBottom: "2.5rem" }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#343434",
                    marginBottom: "1.5rem",
                  }}
                >
                  2. {t("parkings.fields.groups.label")}
                </Typography>
                <FormControlLabel
                  control={
                    <Controller
                      name="allGroups"
                      control={methods.control}
                      render={({ field }) => {
                        return (
                          <>
                            <Checkbox {...field} />
                          </>
                        );
                      }}
                    />
                  }
                  label={t("parkings.fields.allGroups.label")}
                />
                <Controller
                  name="groups"
                  control={methods.control}
                  rules={{
                    required: !allGroups
                      ? t("parkings.fields.groups.required")
                      : false,
                  }}
                  defaultValue=""
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        {...fieldProps}
                        isDisabled={allGroups}
                        inputRef={ref}
                        isMulti={!allGroups}
                        options={groups}
                        placeholder={t("parkings.fields.groups.placeholder")}
                        getOptionLabel={(option) => option.display_name}
                        getOptionValue={(option) => option.id}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {error?.message && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "2.5rem" }}>
              <Controller
                name="priceList"
                control={methods.control}
                rules={{ required: t("parkings.fields.priceList.required") }}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <Box sx={{ maxWidth: "31.25rem" }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        color: "#343434",
                        marginBottom: "1.5rem",
                      }}
                    >
                      3. {t("parkings.fields.priceList.label")}
                    </Typography>
                    <Select
                      {...fieldProps}
                      inputRef={ref}
                      options={pricings}
                      getOptionLabel={(option) => option.name}
                      placeholder={t("parkings.fields.priceList.placeholder")}
                      getOptionValue={(option) => option.id}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {error?.message && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
            <Grid container item xs={12} sx={{ mb: "1.5rem" }}>
              <ParkingSpotTransferListField
                chosenParkingSpots={[]}
                parkingSpots={spots}
              />
            </Grid>
          </Grid>
          <Grid item lg={12} xl={6} container sx={{ height: "fit-content" }}>
            <AvailabilitiesArrayField append={append} customNumber={5} />
            <ParkingRulesFormTable
              data={fields}
              removeItem={remove}
              updateItem={update}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
