import React from "react";
import {
  createTheme,
  IconButton,
  ThemeProvider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FlashOff, FlashOn, Power, PowerOff } from "@material-ui/icons";
import * as locales from "@mui/material/locale";
import MaterialReactTable from "material-react-table";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";
import { ConfirmStartTransactionDialog } from "../dialogs/ConfirmStartTransactionDialog";
import { useToggleable } from "../../../../libs/utils";
import { ConfirmStopTransactionDialog } from "../dialogs/ConfirmStopTransactionDialog";
import { ConfirmChangeStatusDialog } from "../dialogs/ConfirmChangeStatusDialog";

export const ConnectorsListTable = ({ data }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [dialogStartContent, setDialogStartContent] = React.useState({});
  const [dialogStopContent, setDialogStopContent] = React.useState({});
  const [dialogChangeStatusContent, setDialogChangeStatusContent] =
    React.useState({});

  const [
    startOpen,
    { handleOpen: handleStartOpen, handleClose: handleStartClose },
  ] = useToggleable();
  const [
    stopOpen,
    { handleOpen: handleStopOpen, handleClose: handleStopClose },
  ] = useToggleable();

  const [
    changeStatusOpen,
    {
      handleOpen: handleChangeStatusOpen,
      handleClose: handleChangeStatusClose,
    },
  ] = useToggleable();

  const { chargerId } = useParams();
  const columns = React.useMemo(() => [
    {
      accessorKey: "port",
      header: t("chargers.tables.name"),
      Cell: ({ row }) => {
        return (
          <Typography variant="body2">
            {`${t("chargers.tables.connector")} ${row.original.port}`}
          </Typography>
        );
      },
    },
    {
      accessorKey: "status",
      header: t("chargers.tables.status"),
      Cell: ({ row }) => {
        const status = row.original?.status?.toUpperCase() ?? "AVAILABLE";
        return (
          <Typography
            variant="body2"
            color={status === "AVAILABLE" ? "#00CE2D" : "red"}
          >
            {status}
          </Typography>
        );
      },
    },
    {
      id: "actions",
      header: t("tables.actions"),
      columnDefType: "display",
      enableColumnOrdering: false,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ row }) => (
        <>
          {row.original?.status?.toUpperCase() === "AVAILABLE" ? (
            <>
              <Tooltip title={t("chargers.tooltips.starTransaction")}>
                <IconButton
                  key={`${row.id}-start`}
                  onClick={() => {
                    setDialogStartContent({
                      id: row.original.id,
                      chargerId,
                    });
                    handleStartOpen();
                  }}
                >
                  <FlashOn />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("chargers.tooltips.powerOffConnector")}>
                <IconButton
                  key={`${row.id}-offline`}
                  onClick={() => {
                    setDialogChangeStatusContent({
                      id: row.original.id,
                      status: false,
                      chargerId,
                    });
                    handleChangeStatusOpen();
                  }}
                >
                  <PowerOff />
                </IconButton>
              </Tooltip>
            </>
          ) : row.original?.status?.toUpperCase() === "UNAVAILABLE" ? (
            <Tooltip title={t("chargers.tooltips.powerOnConnector")}>
              <IconButton
                key={`${row.id}-online`}
                onClick={() => {
                  setDialogChangeStatusContent({
                    id: row.original.id,
                    status: true,
                    chargerId,
                  });
                  handleChangeStatusOpen();
                }}
              >
                <Power />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t("chargers.tooltips.stopTransaction")}>
              <IconButton
                key={`${row.id}-stop`}
                onClick={() => {
                  setDialogStopContent({
                    id: row.original.id,
                    chargerId,
                  });
                  handleStopOpen();
                }}
              >
                <FlashOff />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ]);
  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        columns={columns}
        data={data}
        localization={t("tables", { returnObjects: true })}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableRowActions={false}
        positionActionsColumn="last"
        enableSorting={false}
        enableColumnFilters={false}
        enableGlobalFilter={false}
      />
      {startOpen && (
        <ConfirmStartTransactionDialog
          key={dialogStartContent.id}
          open={startOpen}
          handleClose={handleStartClose}
          content={dialogStartContent}
        />
      )}
      {stopOpen && (
        <ConfirmStopTransactionDialog
          key={dialogStopContent.id}
          open={stopOpen}
          handleClose={handleStopClose}
          content={dialogStopContent}
        />
      )}
      {changeStatusOpen && (
        <ConfirmChangeStatusDialog
          key={dialogChangeStatusContent.id}
          open={changeStatusOpen}
          handleClose={handleChangeStatusClose}
          content={dialogChangeStatusContent}
        />
      )}
    </ThemeProvider>
  );
};
