import { useQuery } from "react-query";

import { useApi } from "../api";
import { config } from "../config";

export const useParkingHistory = () => {
  const { get } = useApi();
  // dopisać odpowiedni endpoint
  return useQuery("parking-history", get(config.endpoints.RESERVATIONS));
};
