import React from "react";
import MaterialReactTable from "material-react-table";
import { Delete, Edit } from "@material-ui/icons";
import {
  createTheme,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as locales from "@mui/material/locale";

import { useToggleable } from "../../../../libs/utils";
import { PATHS } from "../../../../libs/routes/paths";
import { ConfirmDeleteRulesDialog } from "../dialogs/ConfirmDeleteRulesDialog";
import { getWeekDays } from "../../consts";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";

export const ParkingRulesTable = ({ data }) => {
  const { parkingId } = useParams();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const history = useHistory();

  const [dialogContent, setDialogContent] = React.useState({});

  const [open, { handleOpen, handleClose }] = useToggleable();

  const columns = React.useMemo(() => [
    {
      accessorFn: (row) => row.name,
      header: t("parkings.tables.name"),
    },
    {
      accessorFn: (row) =>
        row.groups.length > 0
          ? row.groups.map((item) => item.display_name)?.join(", ")
          : t("parkings.tables.all"),
      header: t("parkings.tables.groups"),
    },
    {
      accessorFn: (row) => row.price_list.name,
      header: t("parkings.tables.price_list"),
    },
    {
      id: "actions",
      header: t("tables.actions"),
      columnDefType: "display",
      enableColumnOrdering: false,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ row }) => (
        <>
          <IconButton
            key={`${row.id}-edit`}
            onClick={() => {
              const parkingUrl = generatePath(PATHS.parkings.detail, {
                parkingId,
              });
              const editUrl = generatePath(
                PATHS.parkings.tabs.parkingRules.edit,
                {
                  ruleId: row.original.id,
                }
              );
              history.push(
                `${PATHS.base.main}${parkingUrl}${PATHS.parkings.tabs.parkingRules.main}${editUrl}`
              );
            }}
            sx={{ m: 0 }}
          >
            <Edit />
          </IconButton>
          <IconButton
            key={`${row.id}-delete`}
            onClick={() => {
              setDialogContent({
                id: row.original.id,
                parkingId,
              });
              handleOpen();
            }}
            sx={{ m: 0 }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ]);

  const displayTime = (value) => {
    return value ? moment(value, "HH:mm").format("HH:mm") : "";
  };

  const displayAvailabilities = (availabilities) => {
    if (availabilities.length > 0) {
      return availabilities.map((item, i) => {
        return (
          <Grid key={`availability-${i}`} item xs={12}>
            <Typography variant="body1">
              {getWeekDays().find((day) => day.value === item.weekday).label}:{" "}
              {displayTime(item.start)} - {displayTime(item.end)}
            </Typography>
          </Grid>
        );
      });
    }
    return <Typography variant="body1">{t("parkings.tables.none")}</Typography>;
  };

  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        key={i18n.language}
        localization={t("tables", { returnObjects: true })}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableSorting
        enableRowActions={false}
        renderDetailPanel={({ row }) => (
          <Grid container>
            {displayAvailabilities(row.original.availabilities)}
          </Grid>
        )}
      />
      {open && (
        <ConfirmDeleteRulesDialog
          key={dialogContent.id}
          open={open}
          handleClose={handleClose}
          content={dialogContent}
        />
      )}
    </ThemeProvider>
  );
};
