import { Add } from "@material-ui/icons";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { getWeekDays } from "../../../consts";
import i18n from "../../../../../i18n";

const validationSchema = () =>
  yup
    .object()
    .required()
    .shape({
      weekday: yup
        .string()
        .required(i18n.t("parkings.fields.weekday.required")),
      start: yup.string().required(i18n.t("parkings.fields.start.required")),
      end: yup
        .string()
        .required(i18n.t("parkings.fields.end.required"))
        .test(
          "end-less-then-start",
          i18n.t("parkings.fields.end.less_then_start"),
          (value, context) => {
            const { start } = context.parent;
            return moment(value, "HH:mm").isAfter(moment(start, "HH:mm"));
          }
        ),
    });

export const AvailabilitiesArrayField = ({ append, customNumber }) => {
  const { t } = useTranslation();
  const localeValidationSchema = React.useMemo(
    () => validationSchema(),
    [i18n.language]
  );
  const availabilitiesMethods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    resolver: yupResolver(localeValidationSchema),
  });

  const onSubmit = (payload) => {
    append({ ...payload });
    availabilitiesMethods.reset();
  };

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <Box
          sx={{
            width: "90%",
            marginBottom: ".5rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
              color: "#343434",
              marginRight: "1rem",
            }}
          >
            {customNumber || "3"}. {t("parkings.fields.availability.label")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} mb="1.5rem">
        <FormHelperText>
          {t("parkings.fields.availability.description")}
        </FormHelperText>
      </Grid>
      <Grid container item xs={12} columnGap="1rem" rowGap="1rem" mb="1.5rem">
        <Controller
          name="weekday"
          defaultValue=""
          control={availabilitiesMethods.control}
          render={({
            field: { ref, value, onChange, ...fieldProps },
            fieldState: { error },
          }) => (
            <Box sx={{ maxWidth: "10rem", width: "100%" }}>
              <Select
                {...fieldProps}
                inputRef={ref}
                options={getWeekDays()}
                value={
                  getWeekDays().find((item) => item.value === value) || null
                }
                onChange={(val) => onChange(val.value)}
                placeholder={t("parkings.fields.weekday.placeholder")}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {error?.message && (
                <FormHelperText error>{error?.message}</FormHelperText>
              )}
            </Box>
          )}
        />
        <Box sx={{ display: "inline-flex" }}>
          <Box sx={{ marginRight: "1rem" }}>
            <Controller
              name="start"
              control={availabilitiesMethods.control}
              defaultValue="00:00"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    {...fieldProps}
                    inputRef={ref}
                    size="small"
                    label={t("parkings.fields.start.label")}
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    sx={{
                      maxWidth: "11rem",
                      width: "100%",
                    }}
                  />
                  {error?.message && (
                    <FormHelperText error>{error?.message}</FormHelperText>
                  )}
                </>
              )}
            />
          </Box>
          <Box>
            <Controller
              name="end"
              control={availabilitiesMethods.control}
              defaultValue="23:59"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    {...fieldProps}
                    inputRef={ref}
                    label={t("parkings.fields.end.label")}
                    type="time"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    sx={{ maxWidth: "11rem", width: "100%" }}
                  />
                  {error?.message && (
                    <FormHelperText error>{error?.message}</FormHelperText>
                  )}
                </>
              )}
            />
          </Box>
          <Box>
            <Button
              startIcon={<Add />}
              variant="text"
              onClick={() => availabilitiesMethods.handleSubmit(onSubmit)()}
              color="primary"
            >
              {t("parkings.buttons.add")}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
