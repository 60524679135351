import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import { Avatar, Divider, Hidden, Typography } from "@mui/material";
import { Add, Person } from "@mui/icons-material";

import { useUpdateUserAvatar } from "./data";

const AddressItem = ({ title, data }) => {
  return (
    <>
      <Typography variant="caption">{title}</Typography>
      <Typography sx={{ fontWeight: "800" }}>{data}</Typography>
    </>
  );
};

export const BasicData = ({
  first_name: firstName,
  last_name: lastName,
  phone_number: phoneNumber,
  email,
  profile,
}) => {
  const {
    address: {
      country,
      city,
      post_code: postCode,
      address_line_1: street,
    } = {},
    avatar,
  } = profile || {};
  const avatarData = { dataUrl: avatar };
  const updateUserAvatar = useUpdateUserAvatar();
  const { t } = useTranslation();
  const onPhotoUpdate = ({ file } = {}) => {
    if (file) {
      const formData = new FormData();
      formData.append("avatar", file);

      updateUserAvatar(formData);
    }
  };

  return (
    <>
      <Grid item container rowGap={4} columnSpacing={{ xs: 3, sm: 3 }}>
        <Grid item sm={5} md lg={12}>
          <ImageUploading
            value={[avatarData]}
            onChange={([updated]) => onPhotoUpdate(updated)}
            dataURLKey="dataUrl"
          >
            {({ imageList: [image], onImageUpload }) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "fit-content",
                }}
              >
                {image ? (
                  <Avatar
                    src={image.dataUrl}
                    alt="avatar"
                    sx={{
                      width: "9.375rem",
                      height: "9.375rem",
                      objectFit: "cover",
                      border: "2px solid #1D71B8",
                      marginBottom: "1rem",
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      width: "9.375rem",
                      height: "9.375rem",
                      border: "2px solid #1D71B8",
                      objectFit: "cover",
                      marginBottom: "1rem",
                    }}
                  >
                    <Person />
                  </Avatar>
                )}
                <Button
                  onClick={onImageUpload}
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                >
                  {image
                    ? t("user_details.change_photo")
                    : t("user_details.add_photo")}
                </Button>
              </Box>
            )}
          </ImageUploading>
        </Grid>
        <Grid container item sm={6} md={8} lg={12} spacing={4}>
          <Grid item xs={12} md lg={12}>
            <AddressItem title={t("user_details.name")} data={firstName} />
            <AddressItem title={t("user_details.surname")} data={lastName} />
            <AddressItem
              title={t("user_details.mobile_nr")}
              data={phoneNumber}
            />
            <AddressItem title={t("user_details.email")} data={email} />
          </Grid>
          <Hidden lgDown>
            <Grid item xs={12}>
              <Divider orientation="horizontal" />
            </Grid>
          </Hidden>
          <Grid item xs={12} md lg={12}>
            <AddressItem title={t("user_details.street")} data={street} />
            <AddressItem
              title={t("user_details.postal_code")}
              data={postCode}
            />
            <AddressItem title={t("user_details.city")} data={city} />
            <AddressItem title={t("user_details.country")} data={country} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
