import React from "react";
import { Box } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

export const DescriptiveRadioButton = ({
  value,
  onChange,
  label,
  description,
  disabled
}) => {
  const select = () => onChange(value);

  return (
    <Box
      padding="20px"
      borderColor="#C4C4C4"
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={disabled ? () => {} : select}
    >
      <Radio disabled={disabled} color="primary" value={value} onChange={select} size="medium" />
      <Box marginLeft="10px">
        <Box fontSize="20px" fontWeight="800" color={`${disabled  && "gray"}`}>
          {label}
        </Box>
        <Box fontSize="16px" fontWeight="300" color={`${disabled  && "gray"}`}>
          {description}
        </Box>
      </Box>
    </Box>
  );
};
