import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Box } from "@mui/system";
import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { useAddLoyaltyCard, useGetUserVehicles } from "./data";

const validationSchema = yup
  .object()
  .required()
  .shape({
    number: yup.string().required("Numer karty jest wymagany"),
    partner: yup.string().required("Partner jest wymagany"),
    vehicle: yup.string().required("Pojazd jest wymagany"),
  });

const initialValues = {
  number: "",
  partner: "",
  vehicle: "",
};

export const AddLoyaltyCardForm = React.memo(
  ({ handleClose, defaultValues = initialValues }) => {
    const { t } = useTranslation();
    const addLoyaltyCard = useAddLoyaltyCard();
    const { handleSubmit, control, formState } = useForm({
      defaultValues,
      criteriaMode: "all",
      mode: "onChange",
      resolver: yupResolver(validationSchema),
    });

    const loyaltyProgramList = [
      { id: "NASZ_WROCLAW", partner: "Nasz Wrocław" },
    ];
    const [loyaltyProgram, setLoyaltyProgram] = useState("");

    const vehicleUserList = useGetUserVehicles();
    const [vehicle, setVehicle] = useState("");

    const onSubmit = handleSubmit((payload) => {
      addLoyaltyCard(payload);
      handleClose();
    });
    return (
      <form noValidate onSubmit={onSubmit}>
        <Box borderBottom={1} paddingBottom="20px" borderColor="#C4C4C4">
          <Grid container spacing={3} style={{ height: "100%" }}>
            <Grid item xs={6}>
              <Box marginBottom="20px">{t("loyalty_cards.card_number")} </Box>
              <Box display="flex" flexDirection="row">
                <Controller
                  name="number"
                  control={control}
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { invalid, error },
                  }) => (
                    <TextField
                      {...fieldProps}
                      id="number"
                      fullWidth
                      variant="outlined"
                      required
                      label={t("loyalty_cards.card_number_label")}
                      size="small"
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="16px"
                  fontWeight="400"
                  marginBottom="15px"
                >
                  {t("loyalty_cards.card_help")}
                </Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="14px"
                  fontWeight="200"
                  lineHeight="20px"
                  textAlign="justify"
                >
                  {t("loyalty_cards.card_help_text")}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          borderBottom={1}
          paddingBottom="20px"
          borderColor="#C4C4C4"
          marginTop="20px"
        >
          <Grid container spacing={3} style={{ height: "100%" }}>
            <Grid item xs={6}>
              <Box marginBottom="20px">{t("loyalty_cards.card_partner")}</Box>
              <Box display="flex" flexDirection="row">
                <Controller
                  name="partner"
                  control={control}
                  render={({
                    field: { onChange, ...fieldProps },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      {...fieldProps}
                      value={loyaltyProgram}
                      onChange={(_, newValue) => {
                        setLoyaltyProgram(newValue);
                        onChange(newValue ? newValue.id : "");
                      }}
                      fullWidth
                      disablePortal
                      id="partner"
                      options={loyaltyProgramList}
                      getOptionLabel={(option) => option?.partner || ""}
                      getOptionSelected={(option) => option?.partner}
                      sx={{ width: 300 }}
                      error={invalid}
                      helperText={error?.message}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          label={t("loyalty_cards.card_partner_label")}
                          required
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="16px"
                  fontWeight="400"
                  marginBottom="15px"
                >
                  {t("loyalty_cards.card_partner_help")}
                </Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="14px"
                  fontWeight="200"
                  lineHeight="20px"
                  textAlign="justify"
                >
                  {t("loyalty_cards.card_partner_text")}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          borderBottom={1}
          paddingBottom="20px"
          borderColor="#C4C4C4"
          marginTop="20px"
        >
          <Grid container spacing={3} style={{ height: "100%" }}>
            <Grid item xs={6}>
              <Box marginBottom="20px">{t("loyalty_cards.card_vehicle")}</Box>
              <Box display="flex" flexDirection="row">
                <Controller
                  name="vehicle"
                  control={control}
                  render={({
                    field: { onChange, ...fieldProps },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      {...fieldProps}
                      value={vehicle || ""}
                      onChange={(_, newValue) => {
                        setVehicle(newValue);
                        onChange(newValue ? newValue?.id : "");
                      }}
                      fullWidth
                      disablePortal
                      id="vehicle"
                      options={vehicleUserList?.data?.data ?? []}
                      getOptionLabel={(option) => option?.license_plate || ""}
                      getOptionSelected={(option) => option?.license_plate}
                      sx={{ width: 300 }}
                      error={invalid}
                      helperText={error?.message}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          label={t("loyalty_cards.card_vehicle_label")}
                          required
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="16px"
                  fontWeight="400"
                  marginBottom="15px"
                >
                  {t("loyalty_cards.card_partner_help")}
                </Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="14px"
                  fontWeight="200"
                  lineHeight="20px"
                  textAlign="justify"
                >
                  {t("loyalty_cards.card_vehicle_text")}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3} width="100%" display="flex" justifyContent="flex-end">
          <Button
            disabled={!formState.isValid}
            id="btnModal"
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            {t("buttons.next")}
          </Button>
        </Box>
      </form>
    );
  }
);
