import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import { ArrowBack } from "@material-ui/icons";

import { TitlePageTopBarBusiness } from "../../components/commons/TitlePageTopBarBusiness";
import { AsyncDataWrapper } from "../../libs/api";
import { usePartnerDetails } from "../../libs/user";

import { ReservationHistory } from "./ReservationHistory";
import { ParkingEntitlements } from "./ParkingEntitlements";

export const MaintainerPage = () => {
  const { t } = useTranslation();
  const result = usePartnerDetails();

  return (
    <Box fontFamily="Ubuntu" className="top-title-bar" marginBottom="54px">
      <AsyncDataWrapper result={result}>
        {({ data }) => (
          <Box>
            <TitlePageTopBarBusiness
              label={t("business_client.title")}
              result={data}
              returnButton={
                <Box marginRight="10px" color="primary.main" borderRight={1}>
                  <Link to="/welcome-page" style={{ textDecoration: "none" }}>
                    <Button startIcon={<ArrowBack />} size="medium">
                      {t("buttons.return")}
                    </Button>
                  </Link>
                </Box>
              }
            />
            <Box
              fontFamily="Ubuntu"
              fontSize="16px"
              fontWeight="600"
              className="top-title-bar"
            >
              <ParkingEntitlements result={data} />
              <ReservationHistory result={data} />
            </Box>
          </Box>
        )}
      </AsyncDataWrapper>
    </Box>
  );
};
