import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  useParkingSpotEntitlements,
  useParkingSpotHistory,
} from "../../../../../libs/parking-spots";
import { ParkingSpotActivitiesTable } from "../../tables/ParkingSpotActivitiesTable";
import { ParkingSpotHistoryTable } from "../../tables/ParkingSpotHistoryTable";

export const ParkingSpotActivities = ({ setTab, tabValue }) => {
  const { parkingSpotId } = useParams();
  const { t } = useTranslation();
  const { data, isSuccess } = useParkingSpotEntitlements(parkingSpotId);
  const { data: historyData, isSuccess: historyIsSuccess } =
    useParkingSpotHistory(parkingSpotId);

  React.useEffect(() => {
    setTab(tabValue);
  }, []);
  return (
    <>
      <Box sx={{ marginBottom: "2rem" }}>
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: 700,
          }}
        >
          {t("parking_spots.tabs.activities.title")}
        </Typography>
        <Typography variant="body1">
          {t("parking_spots.tabs.activities.activity_description")}
        </Typography>
        {isSuccess && <ParkingSpotActivitiesTable data={data?.data} />}
      </Box>
      <Box>
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: 700,
          }}
        >
          {t("parking_spots.tabs.activities.history_title")}
        </Typography>
        <Typography variant="body1">
          {t("parking_spots.tabs.activities.history_description")}
        </Typography>
        {historyIsSuccess && (
          <ParkingSpotHistoryTable data={historyData?.data} />
        )}
      </Box>
    </>
  );
};
