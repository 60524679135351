import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "yup-phone";
import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import Select from "react-select";

import {
  useAddEntryDevice,
  useAddParkingSpot,
  useEntryDevices,
} from "../../../../../libs/parkings";
import i18n from "../../../../../i18n";

const validationSchema = () =>
  yup
    .object()
    .required()
    .optional()
    .shape({
      parking_spot_number: yup
        .string()
        .required(i18n.t("parkings.fields.parking_spot_number.required")),
      addOwner: yup.bool(),
      owner_phone_number: yup.string().when("addOwner", {
        is: (addOwner) => addOwner,
        then: yup
          .string()
          .required(i18n.t("parkings.fields.owner_phone_number.required"))
          .phone(
            "",
            false,
            i18n.t("parkings.fields.owner_phone_number.invalid")
          ),
      }),
    });

export const ParkingSpotAdd = () => {
  const { parkingId } = useParams();
  const { t } = useTranslation();
  const [spotId, setSpotId] = React.useState(null);
  const addSpot = useAddParkingSpot(parkingId);
  const { data, isSuccess } = useEntryDevices(parkingId);
  const assignEntryDevices = useAddEntryDevice(spotId, parkingId);
  const [entryDevices, setEntryDevices] = React.useState([]);
  const localeValidationSchema = React.useMemo(
    () => validationSchema(),
    [i18n.language]
  );
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      parking_spot_number: "",
      owner_phone_number: "",
      addOwner: false,
    },
    criteriaMode: "all",
    mode: "onSubmit",
    resolver: yupResolver(localeValidationSchema),
  });
  const addOwner = watch("addOwner", false);

  React.useEffect(() => {
    if (isSuccess) {
      setEntryDevices(data?.data ?? []);
    }
  }, [isSuccess]);

  const onSubmit = async (payload) => {
    try {
      const data = {};
      data.parking_spot_number = payload.parking_spot_number;
      data.owner_phone_number = payload.addOwner
        ? payload.owner_phone_number
        : "";
      const response = await addSpot(data);
      const devices = payload.entryDevices.map((item) => ({ id: item.id }));
      setSpotId(response.data.id);
      assignEntryDevices(devices);
    } catch (error) {
      toast.error(error.response?.data?.errors?.[0]?.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        <Button
          startIcon={<Add />}
          variant="contained"
          type="submit"
          color="primary"
        >
          {t("groups.buttons.save")}
        </Button>
      </Box>
      <Grid container sx={{ columnGap: "3rem" }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginBottom: "2rem",
          }}
        >
          <Controller
            name="parking_spot_number"
            control={control}
            defaultValue=""
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error },
            }) => (
              <Box sx={{ maxWidth: "fit-content" }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#343434",
                    marginBottom: "1.5rem",
                  }}
                >
                  1. {t("parkings.fields.parking_spot_number.label")}
                </Typography>
                <Input
                  {...fieldProps}
                  inputRef={ref}
                  fullWidth
                  id="spot-name"
                  placeholder={t(
                    "parkings.fields.parking_spot_number.placeholder"
                  )}
                  error={!!error}
                />
                {error?.message && (
                  <FormHelperText error id="spot-name-error-text">
                    {error?.message}
                  </FormHelperText>
                )}
              </Box>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginBottom: "2rem",
          }}
        >
          <Box sx={{ width: "100%", marginBottom: "1rem" }}>
            <FormControlLabel
              control={
                <Controller
                  name="addOwner"
                  control={control}
                  render={({ field }) => {
                    return <Checkbox {...field} />;
                  }}
                />
              }
              label={t("parkings.fields.different_user.label")}
            />
          </Box>
          {addOwner && (
            <Controller
              name="owner_phone_number"
              control={control}
              defaultValue=""
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <Box sx={{ maxWidth: "fit-content" }}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      color: "#343434",
                      marginBottom: "1.5rem",
                    }}
                  >
                    2. {t("parkings.fields.owner_phone_number.label")}
                  </Typography>
                  <Input
                    {...fieldProps}
                    inputRef={ref}
                    fullWidth
                    id="group-name"
                    placeholder={t(
                      "parkings.fields.owner_phone_number.placeholder"
                    )}
                    error={!!error}
                  />
                  {error?.message && (
                    <FormHelperText error id="spot-name-error-text">
                      {error?.message}
                    </FormHelperText>
                  )}
                </Box>
              )}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginBottom: "2rem",
          }}
        >
          <Controller
            name="entryDevices"
            control={control}
            defaultValue=""
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error },
            }) => (
              <Box sx={{ maxWidth: "fit-content" }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#343434",
                    marginBottom: "1.5rem",
                  }}
                >
                  {addOwner ? 3 : 2}. {t("parkings.fields.entryDevices.label")}
                </Typography>
                <Select
                  {...fieldProps}
                  inputRef={ref}
                  isMulti
                  placeholder={t("parkings.fields.entryDevices.placeholder")}
                  options={entryDevices}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
                {error?.message && (
                  <FormHelperText error id="spot-entry-device-error-text">
                    {error?.message}
                  </FormHelperText>
                )}
              </Box>
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};
