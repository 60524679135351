import { LoginPage } from "../../pages/auth/login/LoginPage";
import { PasswordResetPage } from "../../pages/auth/password-reset/PasswordResetPage";
import { MainPageContent } from "../../pages/main-page-content/MainPageContent";

import { PATHS } from "./paths";

export const mainPaths = {
  login: {
    path: PATHS.base.login,
    component: LoginPage,
    public: true,
  },
  resetPassword: {
    path: PATHS.base.passwordReset,
    component: PasswordResetPage,
    public: true,
  },
  mainPage: {
    path: PATHS.base.main,
    component: MainPageContent,
    public: false,
    exact: true,
  },
};
