import {
  Box,
  createTheme,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import MaterialReactTable from "material-react-table";
import { Delete, Edit } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";

import { groupPermissions, groupPermissionsCheckboxesList } from "../consts";
import { useToggleable } from "../../../libs/utils";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../libs/utils/consts";

import { PermissionsDialog } from "./PermissionsDialog";

export const UserGroupTable = ({
  users,
  permissions,
  deleteUser,
  enableRowActions,
  enableRowSelection,
  editUserGroupPermissions,
}) => {
  const { t, i18n } = useTranslation();
  const [open, { handleOpen, handleClose }] = useToggleable();
  const [dialogContent, setDialogContent] = React.useState({});
  const theme = useTheme();

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "phone_number",
        header: t("groups.table.phone_number"),
      },
      {
        accessorKey: "email",
        header: t("groups.table.email"),
      },
      {
        accessorFn: (row) =>
          row.permissions.length > 0
            ? t("groups.checkboxes.admin")
            : t("groups.checkboxes.user"),
        header: t("groups.table.user_type"),
      },
      {
        accessorFn: (row) =>
          row.status === "pending"
            ? t("groups.table.pending")
            : t("groups.table.accepted"),
        header: t("groups.table.status"),
      },
    ],
    []
  );

  const displayUserPermissions = (permissions) => {
    const userPerm = groupPermissionsCheckboxesList.filter((item) => {
      return permissions.includes(item.value);
    });
    if (userPerm.length > 0) {
      return userPerm.map((item) => {
        return (
          <Grid key={item.value} item xs={12}>
            <Typography variant="body1">
              - {t(`groups.checkboxes.${item.value}`)}
            </Typography>
          </Grid>
        );
      });
    }
    return <Typography variant="body1">{t("groups.empty")}</Typography>;
  };

  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <Box>
        <MaterialReactTable
          key={i18n.language}
          columns={columns}
          data={users}
          enableColumnActions={false}
          enableColumnDragging={false}
          enableRowSelection={enableRowSelection}
          enableHiding={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableRowActions={enableRowActions}
          positionActionsColumn="last"
          enableSorting
          localization={t("tables", { returnObjects: true })}
          renderToolbarTopCustomActions={
            enableRowSelection
              ? ({ table }) => {
                  const handleDeleteUsers = () => {
                    const users =
                      table
                        .getSelectedRowModel()
                        ?.rows?.map((row) => row.original.phone_number) ?? [];
                    users.map((user) => deleteUser({ phone_number: user }));
                    table.resetRowSelection();
                  };
                  return (
                    <div>
                      <IconButton
                        onClick={handleDeleteUsers}
                        disabled={table.getSelectedRowModel().rows.length === 0}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  );
                }
              : () => {}
          }
          renderDetailPanel={({ row }) => (
            <Grid container>
              {displayUserPermissions(row.original.permissions)}
            </Grid>
          )}
          renderRowActionMenuItems={({ closeMenu, row }) => [
            permissions.includes(
              groupPermissions.setPermissionsToUser.permission
            ) && (
              <MenuItem
                key={`${row.id}-edit`}
                onClick={() => {
                  setDialogContent({
                    phoneNumber: row.original.phone_number,
                    permissions: row.original.permissions,
                    editUserGroupPermissions,
                  });
                  handleOpen();
                  closeMenu();
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                {t("groups.table.edit_permissions")}
              </MenuItem>
            ),
            permissions.includes(
              groupPermissions.deleteUserFromGroup.permission
            ) && (
              <MenuItem
                key={`${row.id}-delete`}
                onClick={() => {
                  deleteUser({ phone_number: row.original.phone_number });
                  closeMenu();
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                {t("groups.table.delete_user")}
              </MenuItem>
            ),
          ]}
        />
      </Box>
      <PermissionsDialog
        open={open}
        handleClose={handleClose}
        content={dialogContent}
      />
    </ThemeProvider>
  );
};
