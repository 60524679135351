import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useRemoveParkingSpot } from "../../../libs/parkings";

export const ConfirmDeleteSpotDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();
  const { parkingId } = useParams();
  const { owner } = content;
  const del = useRemoveParkingSpot(content.id, parkingId);

  const handleDelete = () => {
    del();
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("parkings.dialogs.delete_spot_title")}</DialogTitle>
        <DialogContent>
          {content.owner && (
            <Typography sx={{ marginBottom: "0.625rem" }}>
              <Trans
                i18nKey="parkings.dialogs.delete_spot_with_users_text"
                owner={content.owner}
              >
                Miejsce parkingowe obecnie posiada właściciela ({{ owner }}) ).
              </Trans>
            </Typography>
          )}
          <Typography>{t("parkings.dialogs.delete_spot_text")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("parkings.buttons.cancel")}</Button>
          <Button onClick={handleDelete}>{t("parkings.buttons.delete")}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
