import React from "react";
import { useTranslation } from "react-i18next";

import { useUserSpots } from "../../libs/parking-spots";
import { GenericContentBox } from "../../components/commons/GenericContentBox";

import { ParkingSpotsListTable } from "./components/ParkingSpotsListTable";

export const ParkingSpotsList = () => {
  const { data } = useUserSpots();
  const { t } = useTranslation();

  return (
    <GenericContentBox title={t("parking_spots.main_title")}>
      <ParkingSpotsListTable data={data?.data ?? []} />
    </GenericContentBox>
  );
};
