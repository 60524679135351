import React from "react";
import Box from "@mui/material/Box";
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";

import { PATHS } from "../../libs/routes/paths";

import { TitlePageTopBar } from "./TitlePageTopBar";

export const GenericContentBox = ({ title, children, breadcrumbs }) => {
  const [breadcrumbList, setBreadcrumbList] = React.useState([]);
  const { t } = useTranslation();
  React.useEffect(() => {
    const baseBreadcrumb = [
      <Link
        underline="hover"
        key="0"
        color="inherit"
        sx={{ display: "flex", alignItems: "center" }}
        href={`${PATHS.base.welcomePage}`}
      >
        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        {t("main_breadcrumb")}
      </Link>,
    ];
    const tmpBreadcrumbs =
      breadcrumbs?.map((item, index) =>
        item.path ? (
          <Link
            underline="hover"
            key={`${index + 1}`}
            color="inherit"
            href={`${item.path}`}
          >
            {item.title}
          </Link>
        ) : (
          <Typography key={`${index + 1}`} color="text.primary">
            {item.title}
          </Typography>
        )
      ) ?? [];
    setBreadcrumbList([...baseBreadcrumb, ...tmpBreadcrumbs]);
  }, [breadcrumbs]);

  return (
    <Box>
      <Box
        sx={{
          fontFamily: "Ubuntu",
          fontSize: "1.5rem",
          fontWeight: "600",
          marginBottom: "1.875rem",
        }}
      >
        <TitlePageTopBar label={title} />
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFF",
          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.25)",
          minHeight: "87vh",
          padding: "1.5625rem",
          borderRadius: "12px",
          fontFamily: "Ubuntu",
        }}
      >
        <Box sx={{ marginBottom: "1.5rem" }}>
          <MUIBreadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbList}
          </MUIBreadcrumbs>
        </Box>
        {children}
      </Box>
    </Box>
  );
};
