import React from "react";
import { Box, FormHelperText, Typography } from "@mui/material";
import Select from "react-select";
import { Controller, useFormContext } from "react-hook-form";

const GenericSelectField = ({
  name,
  rules = {},
  defaultValue = "",
  label,
  options,
  placeholder,
  isMulti = false,
  isDisabled = false,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
        <Box sx={{ maxWidth: "31.25rem" }}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
              color: "#343434",
              marginBottom: "1.5rem",
            }}
          >
            {label}
          </Typography>
          <Select
            {...fieldProps}
            inputRef={ref}
            options={options}
            isMulti={isMulti}
            isDisabled={isDisabled}
            getOptionLabel={(option) => option.name}
            placeholder={placeholder}
            getOptionValue={(option) => option.id}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
          {error?.message && (
            <FormHelperText error>{error?.message}</FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default GenericSelectField;
