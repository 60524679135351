import { Box } from "@material-ui/core";
// import { AccountCircle, Search } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as IconApp } from "../../svg/app.svg";
import { ReactComponent as IconHardware } from "../../svg/hardware.svg";
import { ReactComponent as IconParking } from "../../svg/parking.svg";
import { TitlePageTopBar } from "../../components/commons/TitlePageTopBar";

import { DashboardTileFAQ } from "./components/DashboardTileFAQ";

export const Faq = () => {
  const { t } = useTranslation();
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const onClickUrl = (url) => {
    return () => openInNewTab(url);
  };

  return (
    <Box>
      <TitlePageTopBar label={t("faq.title")} />

      <Box fontSize="30px" fontWeight="300">
        {t("faq.subtitle")}
      </Box>
      <Box marginTop="50px" fontSize="24px" fontWeight="700">
        {t("faq.description")}
      </Box>
      <Box marginTop="20px" fontSize="20px">
        {t("faq.choose_below")}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        marginTop="100px"
      >
        <DashboardTileFAQ
          icon={<IconApp />}
          sectionName={t("faq.app")}
          sectionDescription={t("faq.app_desc")}
          buttonClick={onClickUrl(
            "https://sharep.io/pl/faq/support-desk-application/"
          )}
          marginValue="30px 30px 30px 0"
        />
        <DashboardTileFAQ
          icon={<IconHardware />}
          sectionName={t("faq.device")}
          sectionDescription={t("faq.device_desc")}
          buttonClick={onClickUrl(
            "https://sharep.io/pl/faq/support-desk-hardware/"
          )}
          marginValue="30px 30px 30px 0"
        />
        <DashboardTileFAQ
          icon={<IconParking />}
          sectionName={t("faq.parking")}
          sectionDescription={t("faq.parking_desc")}
          buttonClick={onClickUrl(
            "https://sharep.io/pl/faq/support-desk-parking/"
          )}
          marginValue="30px 30px 30px 0"
        />
      </Box>
    </Box>
  );
};
