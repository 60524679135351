import { Add } from "@material-ui/icons";
import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

import { PATHS } from "../../../../../libs/routes/paths";
import { useChargerRules } from "../../../../../libs/chargers";
import { ChargerRulesTable } from "../../tables/ChargerRulesTable";

export const ChargerRulesList = () => {
  const { chargerId } = useParams();
  const { t } = useTranslation();

  const { data, isSuccess } = useChargerRules(chargerId);
  const detailUrl = generatePath(PATHS.chargers.detail, { chargerId });
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          component={Link}
          to={`${PATHS.base.main}${detailUrl}${PATHS.chargers.tabs.chargerRules.main}${PATHS.chargers.tabs.chargerRules.create}`}
          startIcon={<Add />}
          variant="text"
          color="primary"
          sx={{
            fontSize: "1rem",
          }}
        >
          {t("chargers.buttons.addRule")}
        </Button>
      </Box>
      {isSuccess && <ChargerRulesTable data={data?.data ?? []} />}
    </>
  );
};
