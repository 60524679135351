import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@mui/material";
import { Add } from "@material-ui/icons";

import { useGroups } from "../../../../../libs/groups";
import {
  useAddChargerRules,
  useCharger,
  useGetChargerPriceLists,
} from "../../../../../libs/chargers";
import GenericSelectField from "../../../../../components/forms/GenericSelectField";
import { GenericTransferListField } from "../../../../../components/forms/GenericTransferListField";

export const ChargerRulesAdd = () => {
  const { t, i18n } = useTranslation();

  const { chargerId } = useParams();
  const { data: groupData, isSuccess: groupIsSuccess } = useGroups();
  const { data: priceListData, isSuccess: priceListIsSuccess } =
    useGetChargerPriceLists();
  const { data: chargerData, isSuccess: chargerIsSuccess } =
    useCharger(chargerId);
  const addChargerRule = useAddChargerRules(chargerId);
  const [groups, setGroups] = React.useState([]);
  const [priceList, setPriceList] = React.useState([]);
  const [connectors, setConnectors] = React.useState([]);
  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    defaultValues: {
      allGroups: false,
    },
  });
  const allGroups = methods.watch("allGroups", false);

  React.useEffect(() => {
    if (allGroups) {
      methods.setValue("groups", { id: 0, name: t("parkings.tables.all") });
    } else {
      methods.setValue("groups", []);
    }
  }, [allGroups, i18n.language]);

  React.useEffect(() => {
    if (groupIsSuccess) {
      setGroups([...(groupData?.data ?? [])]);
    }
  }, [groupIsSuccess]);

  React.useEffect(() => {
    if (priceListIsSuccess) {
      setPriceList([...(priceListData?.data ?? [])]);
    }
  }, [priceListIsSuccess]);

  React.useEffect(() => {
    if (chargerIsSuccess) {
      setConnectors([...(chargerData?.data?.connectors ?? [])]);
    }
  }, [chargerIsSuccess]);

  const onSubmit = (payload) => {
    const data = {};
    data.connector_ids = payload.connectors.map((item) => item.id);
    data.group_ids = payload.allGroups
      ? []
      : payload.groups.map((item) => item.id);
    data.name = payload.name;
    data.price_list_id = payload.priceList.id;
    addChargerRule(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        >
          <Button
            startIcon={<Add />}
            variant="contained"
            type="submit"
            color="primary"
          >
            {t("chargers.buttons.save")}
          </Button>
        </Box>
        <Grid container>
          <Grid container item sm={12} lg={7} xl={6}>
            <Grid item xs={12} sx={{ marginBottom: "2.5rem" }}>
              <Controller
                name="name"
                control={methods.control}
                rules={{
                  required: `${t("chargers.fields.ruleName.required")}`,
                }}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <Box
                    sx={{
                      maxWidth: "15rem",
                      width: "100%",
                      marginRight: "2rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        color: "#343434",
                        marginBottom: "1.5rem",
                      }}
                    >
                      1. {t("chargers.fields.ruleName.label")}
                    </Typography>
                    <Input
                      {...fieldProps}
                      inputRef={ref}
                      fullWidth
                      id="rule-name"
                      placeholder={t("chargers.fields.ruleName.placeholder")}
                      error={!!error}
                    />
                    {error?.message && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <GenericSelectField
                label={`2. ${t("chargers.fields.groups.label")}`}
                isDisabled={allGroups}
                isMulti={!allGroups}
                name="groups"
                placeholder={t("chargers.fields.groups.placeholder")}
                rules={{
                  required: !allGroups
                    ? t("chargers.fields.groups.required")
                    : false,
                }}
                options={groups}
              />
              <FormControlLabel
                sx={{ marginBottom: "1.5rem" }}
                control={
                  <Controller
                    name="allGroups"
                    control={methods.control}
                    render={({ field }) => {
                      return (
                        <>
                          <Checkbox {...field} />
                        </>
                      );
                    }}
                  />
                }
                label={t("parkings.fields.allGroups.label")}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "2.5rem" }}>
              <GenericSelectField
                label={`3. ${t("chargers.fields.priceList.label")}`}
                name="priceList"
                placeholder={t("chargers.fields.priceList.placeholder")}
                rules={{ required: t("chargers.fields.priceList.required") }}
                options={priceList}
              />
            </Grid>
            <Grid container item xs={12}>
              <GenericTransferListField
                label={`4. ${t("chargers.fields.connectors.label")}`}
                name="connectors"
                options={connectors}
                chosenOptions={[]}
                optionsLabel="port"
                leftOptionsLabel={t(
                  "chargers.fields.connectors.leftOptionsLabel"
                )}
                rightOptionsLabel={t(
                  "chargers.fields.connectors.rightOptionsLabel"
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
