import { Add } from "@material-ui/icons";
import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

import { useGetParkingSpots, useParking } from "../../../../../libs/parkings";
import { PATHS } from "../../../../../libs/routes/paths";
import { ParkingSpotsTable } from "../../tables/ParkingSpotsTable";

export const ParkingSpotList = () => {
  const { parkingId } = useParams();
  const { t } = useTranslation();
  const { data: parkingData } = useParking(parkingId);
  const { data, isSuccess } = useGetParkingSpots(parkingId);
  const detailUrl = generatePath(PATHS.parkings.detail, { parkingId });
  return (
    <>
      {parkingData?.data?.permissions.can_edit && (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            component={Link}
            to={`${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingSpots.main}${PATHS.parkings.tabs.parkingSpots.create}`}
            startIcon={<Add />}
            variant="text"
            color="primary"
            sx={{
              fontSize: "1rem",
            }}
          >
            {t("parkings.buttons.add_spot")}
          </Button>
        </Box>
      )}
      {isSuccess && <ParkingSpotsTable data={data?.data ?? []} />}
    </>
  );
};
