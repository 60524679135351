import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { usePartnerParkingEntitlements } from "../parking-edit/data";

import { ParkingEntitlementsItem } from "./ParkingEntitlementsItem";

export const ParkingEntitlements = ({ result }) => {
  const { t } = useTranslation();
  const parkingItem = usePartnerParkingEntitlements(
    result.maintained_parkings[0].id
  );
  return (
    <Box
      bgcolor="white"
      borderRadius={12}
      marginBottom="20px"
      style={{
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.25)",
      }}
      padding="20px"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginBottom="30px"
      >
        <Box
          borderRight={1}
          fontFamily="Ubuntu"
          color="primary.main"
          fontSize="20px"
          fontWeight="400"
          marginRight="5px"
          paddingRight="5px"
        >
          {t("entitlements.title")}
        </Box>
        <Box fontFamily="Ubuntu" fontSize="16px" fontWeight="200">
          {t("entitlements.description")}
        </Box>
      </Box>

      <Box>
        <Box
          marginBottom="10px"
          bgcolor="white"
          borderRadius={8}
          marginTop="30px"
          color="primary.main"
          fontWeight="800"
          padding="10px 15px"
          textAlign="left"
        >
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Box fontSize="16px">{t("entitlements.vehicle_plates")}</Box>
            </Grid>
            <Grid item xs={2}>
              <Box fontSize="16px">{t("entitlements.parking_spot")}</Box>
            </Grid>
            <Grid item xs={3}>
              <Box fontSize="16px">{t("entitlements.start")}</Box>
            </Grid>
            <Grid item xs={3}>
              <Box fontSize="16px">{t("entitlements.end")}</Box>
            </Grid>
            <Grid item xs={2}>
              <Box fontSize="16px">{t("entitlements.reservation_type")}</Box>
            </Grid>
          </Grid>
        </Box>

        <Box style={{ overflow: "auto", height: "25vh" }}>
          {/* TODO: to much data manipulation in JSX. Create some variable (e.g. const = parkingEntitlements) outside of the JSX, do all the data manipulation there and just use it here */}
          {parkingItem?.data?.data
            ?.concat()
            .reverse()
            .map((item) => (
              <ParkingEntitlementsItem
                licensePlate={item.license_plate}
                parkingSpot={item.parking_spot || "---"}
                start={item.start}
                end={item.end}
                entitlementReason={item.entitlement_reason}
                //TODO: should not pass empty key
                key=""
              />
            ))}
        </Box>
      </Box>
    </Box>
  );
};
