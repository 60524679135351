import { Box } from "@material-ui/core";
import React from "react";

export const TitlePageTopBarUserCredentials = ({ userName, userSurname }) => {
  return (
    <Box>
      <Box marginLeft="20px">
        <Box fontSize="16px" fontWeight="500" color="black" textAlign="right">
          {userName} {userSurname}
        </Box>
        <Box fontSize="12px" fontWeight="200" color="#929292" textAlign="right">
          user
        </Box>
      </Box>
    </Box>
  );
};
