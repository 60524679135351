import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import OtpInput from "react-otp-input";

import { usePasswordResetVerify } from "../../hooks";

const validationSchema = yup
  .object()
  .required()
  .shape({
    code: yup
      .string()
      .required("Phone number is required")
      .test("len", "Must be exactly 5 characters", (val) => val.length === 5),
  });

export const ResetPasswordVerify = ({
  setStep,
  phoneNumber,
  setResetToken,
}) => {
  const passwordResetVerify = usePasswordResetVerify(setStep, setResetToken);
  const [otpValue, setOtpValue] = useState("");
  const { handleSubmit, control, setValue } = useForm({
    // defaultValues,
    criteriaMode: "onSubmit",
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const handleChange = (otp) => {
    setOtpValue(otp);
    setValue("code", otp);
  };
  const onSubmit = handleSubmit((payload) => {
    passwordResetVerify(payload);
  });

  const { t } = useTranslation();

  return (
    <Card
      sx={{
        maxWidth: 310,
        borderRight: "1px solid rgba(0, 0, 0, 0.25)",
        boxShadow: "none",
      }}
    >
      <form noValidate onSubmit={onSubmit}>
        <CardContent>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "3.5rem",
                fontWeight: "600",
                fontFamily: "Ubuntu",
              }}
              variant="h5"
            >
              {t("password_reset_box_title")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                fontWeight: "200",
              }}
            >
              {t("password_reset_verify_description")}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ marginBottom: "7.375rem" }}
            >
              <OtpInput
                type="number"
                numInputs={5}
                value={otpValue}
                // hasErrored
                onChange={handleChange}
                separator={<span> </span>}
                inputStyle={{
                  width: "2rem",
                  height: "3rem",
                }}
                containerStyle={{
                  marginTop: "0.5rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                // errorStyle="Mui-error"
              />
              <Controller
                name="code"
                control={control}
                defaultValue={otpValue}
                render={({ field: { ref, ...fieldProps } }) => (
                  <input {...fieldProps} ref={ref} type="hidden" />
                )}
              />
              <Controller
                name="phone_number"
                control={control}
                defaultValue={phoneNumber}
                render={({ field: { ref, ...fieldProps } }) => (
                  <input {...fieldProps} ref={ref} type="hidden" />
                )}
              />
            </Box>

            <ButtonGroup orientation="vertical" fullWidth>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
              >
                {t("buttons.password_reset_verify")}
              </Button>
            </ButtonGroup>
          </Box>
        </CardContent>
      </form>
    </Card>
  );
};
