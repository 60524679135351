import { CircularProgress, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import React from "react";

export const Loader = ({
  size = 40,
  thickness = 3,
  content = "",
  minHeight = "100%",
  flexDirection = "column",
  wrapperProps = {},
  contentProps = {},
}) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={flexDirection}
      color="white"
      minHeight={minHeight}
      {...wrapperProps}
    >
      <CircularProgress
        size={size}
        thickness={thickness}
        color="primary"
        data-test-id="loader"
      />
      {content && (
        <Typography
          variant="h3"
          color="inherit"
          style={{ marginTop: 40 }}
          {...contentProps}
        >
          {content}
        </Typography>
      )}
    </Box>
  );
};
export const TimeoutComponent = ({ delay, initialView, finalView }) => {
  const [component, setComponent] = React.useState(initialView);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setComponent(finalView);
    }, delay);
    return () => clearTimeout(timer);
  });
  return component || <></>;
};

export const DelayedLoader = ({ delay = 500, size, thickness, content }) => {
  return (
    <TimeoutComponent
      delay={delay}
      initialView={<></>}
      finalView={<Loader size={size} thickness={thickness} content={content} />}
    />
  );
};
