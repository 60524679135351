import { Grid, Box } from "@material-ui/core";
import React from "react";

// import { SideBusinessMenu } from "../business/SideBusinessMenu";

import { useUserType } from "../../libs/auth";
import {
  businessDashboardMenuList,
  regularDashboardMenuList,
} from "../../libs/routes/menus";

import { SideBarMenu } from "./SideBarMenu";
import { SideBarMenuPartner } from "./SideBarMenuPartner";
import { MainRegularPage } from "./MainRegularPage";
import { MainBusinessPage } from "./MainBusinessPage";

export const MainPageContent = () => {
  const userType = useUserType();
  return (
    <Box bgcolor="rgba(238, 247, 255, 0.5)" height="100%" overflow="scroll">
      <Grid container spacing={0} style={{ height: "100vh" }}>
        <Grid item lg={2} md={2} xs={2}>
          {userType === "REGULAR" && (
            <SideBarMenu menuList={regularDashboardMenuList} />
          )}
          {userType === "BUSINESS" && (
            <SideBarMenuPartner menuList={businessDashboardMenuList} />
          )}
        </Grid>

        <Grid item lg={10} md={10} xs={10}>
          {userType === "REGULAR" && <MainRegularPage />}
          {userType === "BUSINESS" && <MainBusinessPage />}
        </Grid>
      </Grid>
    </Box>
  );
};
