import { useMutation, useQuery, useQueryClient } from "react-query";

import { useApi } from "../../../libs/api";
import { config } from "../../../libs/config";

export const useLoyaltyCards = () => {
  const { get } = useApi();

  return useQuery("loyalty-cards", get(config.endpoints.LOYALTY_CARDS));
};

export const useGetUserVehicles = () => {
  const { get } = useApi();

  return useQuery("vehicles", get(config.endpoints.VEHICLES));
};

export const useAddLoyaltyCard = () => {
  const queryClient = useQueryClient();
  const { post } = useApi();
  const { mutate } = useMutation(post(config.endpoints.LOYALTY_CARDS), {
    onSuccess: () => {
      queryClient.refetchQueries("loyalty-cards");
    },
  });
  return mutate;
};

export const useRemoveCard = (cardId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(`${config.endpoints.LOYALTY_CARDS}${cardId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries("loyalty-cards");
      },
    }
  );
  return mutate;
};
