import { PATHS } from "../../../../libs/routes/paths";

import { GeneralInfo } from "./GeneralInfo";
import { ChargerRules } from "./charger_rules/ChargerRules";
import { ChargerActivities } from "./ChargerActivities";

export const CHARGER_TABS = {
  generalInfo: {
    path: PATHS.chargers.tabs.generalInfo,
    component: GeneralInfo,
    tabValue: 0,
    exact: true,
  },
  parkingRules: {
    path: PATHS.chargers.tabs.chargerRules.main,
    component: ChargerRules,
    tabValue: 1,
  },
  activities: {
    path: PATHS.chargers.tabs.activities,
    component: ChargerActivities,
    tabValue: 2,
  },
};
export const chargerTabsPaths = [...Object.values(CHARGER_TABS)];
