import { useMutation, useQuery, useQueryClient } from "react-query";

import { useApi } from "../api";
import { config } from "../config";

export const usePricings = () => {
  const { get } = useApi();

  return useQuery("pricings", get(config.endpoints.PRICINGS));
};

export const useAddPricings = () => {
  const { post } = useApi();
  const { mutateAsync } = useMutation(post(config.endpoints.PRICING_ITEM));
  return mutateAsync;
};

export const useRemovePricings = (id) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(`${config.endpoints.PRICING_ITEM}${id}/periods/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries("pricings");
      },
    }
  );
  return mutate;
};

export const useAddPricingPeriod = (priceListId) => {
  const queryClient = useQueryClient();
  const { patch } = useApi();
  const { mutate } = useMutation(
    patch(`deprecated/admin-panel/price-lists/${priceListId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries("pricings");
      },
    }
  );
  return mutate;
};
