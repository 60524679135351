import { Box, Button } from "@material-ui/core";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import React from "react";

export const PromotionListItem = ({ name, id, handleChange }) => {
  const onClickHandler = () => {
    handleChange(id);
  };
  return (
    <Box
      color="primary.main"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      margin="0 10px"
    >
      <Button
        variant="outlined"
        size="small"
        color="primary"
        style={{ padding: "5px 20px" }}
        onClick={onClickHandler}
        startIcon={<QrCodeScannerIcon />}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Box>{name}</Box>
        </Box>
      </Button>
    </Box>
  );
};
