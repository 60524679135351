import createAuthRefreshInterceptor from "axios-auth-refresh";
import { useEffect } from "react";

import { config } from "../config";

import { useLogout, useSetLoggedIn, useRefreshToken } from "./AuthContext";

export const useSessionRefresh = (axios) => {
  const setLoggedIn = useSetLoggedIn();
  const refreshToken = useRefreshToken();
  const logout = useLogout();

  useEffect(() => {
    const interceptor = createAuthRefreshInterceptor(axios, refreshAuthLogic);

    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, []);

  const refreshAuthLogic = (failedRequest) => {
    const { response } = failedRequest;
    if (response.config.url !== `${config.endpoints.LOGIN}`) {
      return axios
        .post(`${config.endpoints.REFRESH_SESSION}`, {
          refresh: refreshToken,
        })
        .then(({ data: { access } }) => {
          setLoggedIn({ access, refresh: refreshToken });
          // eslint-disable-next-line no-param-reassign
          response.config.headers.Authorization = `Bearer ${access}`;
          return Promise.resolve();
        })
        .catch(() => logout());
    }
    return Promise.reject(response.data.errors[0].message);
  };
};
