import { format } from "date-fns";
import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "@mui/material";

import { useParkingHistory } from "../../../libs/parking-history";
import { AsyncDataWrapper } from "../../../libs/api";
import { usePagination } from "../../../libs/utils/pagination";
import { config } from "../../../libs/config";

import { ParkingHistoryListItem } from "./ParkingHistoryListItem";

export const ListForParkingHistory = ({ subTitle }) => {
  const result = useParkingHistory();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const _DATA = usePagination(result, PER_PAGE);

  const handleChange = (_, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <AsyncDataWrapper result={result}>
      {({ data: list }) => (
        <Box
          borderRadius={12}
          bgcolor="white"
          style={{
            boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.25)",
          }}
          padding="25px"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="30px"
          >
            <Box
              fontFamily="Ubuntu"
              fontSize="20px"
              fontWeight="400"
              marginRight="5px"
              paddingRight="32px"
            >
              {subTitle}
            </Box>
            <Box display="flex">
              <Pagination
                count={Math.ceil(list.length / PER_PAGE)}
                size="small"
                page={page}
                variant="text"
                shape="rounded"
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Box color="secondary.dark">
            <Box borderBottom={1} borderColor="primary" paddingRight="10px">
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <Box display="flex" fontSize="14px">
                    {t("parking_history.spot_number")}
                    {/* <Icon>arrow_drop_down</Icon> */}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box display="flex" fontSize="14px">
                    {t("parking_history.vehicle")}
                    {/* <Icon>arrow_drop_down</Icon> */}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box display="flex" fontSize="14px">
                    {t("parking_history.start")}
                    {/* <Icon>arrow_drop_down</Icon> */}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box display="flex" fontSize="14px">
                    {t("parking_history.end")}
                    {/* <Icon>arrow_drop_down</Icon> */}
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" fontSize="14px">
                    {t("parking_history.amount")}
                    {/* <Icon>arrow_drop_down</Icon> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ overflow: "auto", height: "70vh" }}>
              {_DATA
                .currentData()
                .concat()
                .reverse()
                .map((parkingItem) => {
                  return (
                    <ParkingHistoryListItem
                      key={parkingItem.id}
                      parkingSpot={
                        parkingItem.parking_spot?.parking_spot_number ||
                        t("parking_history.ticket")
                      }
                      parkingName={parkingItem.parking?.name}
                      licencePlate={parkingItem.license_plate}
                      parkingStart={
                        parkingItem.start
                          ? format(
                              Date.parse(parkingItem.start),
                              config.DATE_TIME_FORMAT
                            )
                          : ""
                      }
                      parkingEnd={
                        parkingItem.end
                          ? format(
                              Date.parse(parkingItem.end),
                              config.DATE_TIME_FORMAT
                            )
                          : ""
                      }
                      amountPaid={parkingItem.total_price}
                      currency={parkingItem.currency}
                    />
                  );
                })}
            </Box>
          </Box>
        </Box>
      )}
    </AsyncDataWrapper>
  );
};
