import i18n from "../../i18n";

export const getWeekDays = () => [
  { value: "MONDAY", label: i18n.t("weekdays_list.monday") },
  { value: "TUESDAY", label: i18n.t("weekdays_list.tuesday") },
  { value: "WEDNESDAY", label: i18n.t("weekdays_list.wednesday") },
  { value: "THURSDAY", label: i18n.t("weekdays_list.thursday") },
  { value: "FRIDAY", label: i18n.t("weekdays_list.friday") },
  { value: "SATURDAY", label: i18n.t("weekdays_list.saturday") },
  { value: "SUNDAY", label: i18n.t("weekdays_list.sunday") },
];
