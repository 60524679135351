import { Box, FormControl, MenuItem, Select } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React from "react";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { AsyncDataWrapper } from "../../libs/api";
import { useUserDetails } from "../../libs/user";
import { useLogout } from "../../libs/auth";
import { PATHS } from "../../libs/routes/paths";

import { NavigationLinkUserProfile } from "./NavigationLinkUserProfile";
import { TitlePageTopBarUserCredentials } from "./TitlePageTopBarUserCredentials";

export const TitlePageTopBar = ({ label }) => {
  const { i18n } = useTranslation();
  const logout = useLogout();
  const result = useUserDetails();
  const [language, setLanguage] = React.useState(
    i18n.use(LanguageDetector).language
  );

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      marginBottom="30px"
      bgcolor="white"
      padding="10px 20px"
      borderRadius={8}
      style={{
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box fontFamily="Ubuntu" fontSize="24px" fontWeight="600">
        {label}
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box marginRight="10px">
            <TranslateIcon />
          </Box>
          <Box marginRight="20px">
            <FormControl size="small">
              <Select
                variant="outlined"
                value={language}
                inputProps={{ "aria-label": "Without label" }}
                onChange={handleChange}
              >
                <MenuItem value="pl-PL">PL</MenuItem>
                <MenuItem value="en">EN</MenuItem>
                <MenuItem value="de">DE</MenuItem>
                <MenuItem value="fr">FR</MenuItem>
                <MenuItem value="ua">UA</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <IconButton aria-label="logout" onClick={logout}>
          <ExitToAppIcon />
        </IconButton>
        <Box
          borderLeft={2}
          borderColor="#DFE0EB"
          height="32px"
          marginLeft="20px"
        />

        <AsyncDataWrapper result={result}>
          {({ data }) => (
            // TODO: consider adding a Link wrapper here, so clicking on the wrapper could navigate to '/users-profile'.
            // Now the Link is only on the photo - when user waits for the photo to load from the server, he cannot click it to go to '/users-profile'
            <>
              <TitlePageTopBarUserCredentials
                userName={data.first_name}
                userSurname={data.last_name}
              />
              <NavigationLinkUserProfile
                to={`${PATHS.base.main}${PATHS.base.userProfile}`}
                userPhoto={data.profile?.avatar}
              />
            </>
          )}
        </AsyncDataWrapper>
      </Box>
    </Box>
  );
};
