import React from "react";
import MaterialReactTable from "material-react-table";
import {
  createTheme,
  ListItemIcon,
  MenuItem,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { Share, Visibility } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as locales from "@mui/material/locale";

import { PATHS } from "../../../libs/routes/paths";
import { useToggleable } from "../../../libs/utils";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../libs/utils/consts";

import { ShareFormDialog } from "./dialogs/ShareFormDialog";

export const ParkingSpotsListTable = ({ data }) => {
  const theme = useTheme();

  const { t, i18n } = useTranslation();
  const [dialogContent, setDialogContent] = React.useState({});
  const [open, { handleOpen, handleClose }] = useToggleable();

  const columns = React.useMemo(() => [
    {
      accessorKey: "parking_spot_number",
      header: t("parking_spots.tables.parking_spot_number"),
    },
    {
      accessorKey: "parking_name",
      header: t("parking_spots.tables.parking_name"),
    },
    {
      accessorFn: (row) => (row.created_at ? new Date(row.created_at) : null),
      muiTableHeadCellFilterTextFieldProps: {
        type: "datetime",
      },
      sortingFn: "datetime",
      Cell: ({ cell }) =>
        cell.getValue() &&
        moment(cell.getValue(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      header: t("parkings.tables.created_date"),
    },
  ]);
  const history = useHistory();

  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        key={i18n.language}
        localization={t("tables", { returnObjects: true })}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableRowActions
        positionActionsColumn="last"
        enableSorting
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={`${row.id}-share`}
            onClick={() => {
              setDialogContent({
                id: row.original.id,
                name: row.original.parking_spot_number,
              });
              handleOpen();
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Share />
            </ListItemIcon>
            {t("parking_spots.tables.share")}
          </MenuItem>,
          <MenuItem
            key={`${row.id}-view`}
            onClick={() => {
              const url = generatePath(PATHS.parkingSpots.detail, {
                parkingSpotId: row.original.id,
              });
              history.push(`${PATHS.base.main}${url}`);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Visibility />
            </ListItemIcon>
            {t("parking_spots.tables.details")}
          </MenuItem>,
        ]}
      />
      {open && (
        <ShareFormDialog
          key={dialogContent.id}
          open={open}
          handleClose={handleClose}
          content={dialogContent}
        />
      )}
    </ThemeProvider>
  );
};
