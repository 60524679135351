import { useQuery } from "react-query";

import { useApi } from "../../libs/api";
import { config } from "../../libs/config";

export const useSubscriptionPricings = () => {
  const { get } = useApi();

  return useQuery(
    "subscription-pricings",
    get(config.endpoints.PRICINGS_SUBSCRIPTION)
  );
};
