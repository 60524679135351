import React from "react";
import { Box, Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ApartmentIcon from "@mui/icons-material/Apartment";

import { TitlePageTopBar } from "../../components/commons/TitlePageTopBar";
import { PATHS } from "../../libs/routes/paths";

import { DashboardTile } from "./components/DashboardTile";

// import backgroundImage from "../svg/gotowe_do_kodowania.png";

export const OwnersPanel = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Box fontFamily="Ubuntu" fontSize="16px" color="black">
      {/* <nav style={{ position: "sticky" }}> */}
      <Box
      // style={{
      //   position: "fixed",
      //   top: 0,
      //   width: "100%",
      //   margin: "20px 5px",
      // }}
      >
        <TitlePageTopBar label={t("user_details.section_title")} />
      </Box>
      <Box marginTop="20px" marginBottom="40px" fontSize="24px">
        {t("user_panel_description")}
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        <DashboardTile
          icon={<ApartmentIcon />}
          sectionName={t("buttons.navbar_carparks")}
          sectionDescription={t("dashboard.car_park.desc")}
          function1={t("dashboard.car_park.function1")}
          function2={t("dashboard.car_park.function2")}
          function3={t("dashboard.car_park.function3")}
          function4={t("dashboard.car_park.function4")}
          functionsName={t("dashboard.functions_name")}
          buttonClick={() => {
            history.push(`${PATHS.base.main}${PATHS.parkings.list}`);
          }}
          marginValue="30px 30px 30px 0"
        />
        <DashboardTile
          icon={<Icon>style</Icon>}
          sectionName={t("buttons.navbar_pricing")}
          sectionDescription={t("dashboard.price_list.desc")}
          function1={t("dashboard.price_list.function1")}
          function2={t("dashboard.price_list.function2")}
          function3={t("dashboard.price_list.function3")}
          function4={t("dashboard.price_list.function4")}
          functionsName={t("dashboard.functions_name")}
          buttonClick={() => {
            history.push(`${PATHS.base.main}${PATHS.pricings.list}`);
          }}
          marginValue="30px 30px 30px 0"
        />
        <DashboardTile
          icon={<Icon>payments</Icon>}
          sectionName={t("buttons.navbar_wallet")}
          sectionDescription={t("dashboard.wallet.desc")}
          function1={t("dashboard.wallet.function1")}
          function2={t("dashboard.wallet.function2")}
          function3={t("dashboard.wallet.function3")}
          function4={t("dashboard.wallet.function4")}
          functionsName={t("dashboard.functions_name")}
          buttonClick={() => {
            history.push(`${PATHS.base.main}${PATHS.wallets.detail}`);
          }}
          marginValue="30px 30px 30px 0"
        />
        <DashboardTile
          icon={<Icon>history</Icon>}
          sectionName={t("buttons.navbar_history")}
          sectionDescription={t("dashboard.history.desc")}
          function1={t("dashboard.history.function1")}
          function2={t("dashboard.history.function2")}
          function3={t("dashboard.history.function3")}
          function4={t("dashboard.history.function4")}
          functionsName={t("dashboard.functions_name")}
          buttonClick={() => {
            history.push(`${PATHS.base.main}${PATHS.base.parkingHistory}`);
          }}
          marginValue="30px 0px 30px 0"
        />
      </Box>
    </Box>
  );
};
