import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

export const VirtualWalletBalance = ({ amount, currency }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      item
      spacing={0}
      rowSpacing={1}
      xs={12}
      alignItems="center"
      sx={{ marginBottom: "1rem" }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
          }}
        >
          {t("wallet.balance")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{
            color: "primary.main",
          }}
        >
          {amount} {currency}
        </Typography>
      </Grid>
    </Grid>
  );
};
