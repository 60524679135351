import React from "react";
import { useTranslation } from "react-i18next";

import { GenericContentBox } from "../../components/commons/GenericContentBox";
import { useChargers } from "../../libs/chargers";

import { ChargersListTable } from "./components/tables/ChargersListTable";

export const ChargersList = () => {
  const { t } = useTranslation();
  const chargers = useChargers();
  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("chargers.breadcrumbs.main"),
        path: "",
      },
    ],
    []
  );
  return (
    <GenericContentBox
      title={t("chargers.main_title")}
      breadcrumbs={breadcrumbs}
    >
      <ChargersListTable data={chargers?.data?.data ?? []} />
    </GenericContentBox>
  );
};
