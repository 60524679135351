import React from "react";
import { generatePath, Link, Route, Switch, useParams } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";

import { PATHS } from "../../../libs/routes/paths";
import { useCharger } from "../../../libs/chargers";
import { GenericContentBox } from "../../../components/commons/GenericContentBox";

import { chargerTabsPaths } from "./tabs/tabRoutes";

export const ChargerDetail = () => {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const { chargerId } = useParams();
  const { data } = useCharger(chargerId);
  const detailUrl = generatePath(PATHS.chargers.detail, {
    chargerId,
  });
  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("chargers.breadcrumbs.main"),
        path: `${PATHS.base.main}${PATHS.chargers.list}`,
      },
      {
        title: `${data?.data?.name}`,
        path: "",
      },
    ],
    [data]
  );
  const getPaths = (paths) => {
    return paths.map((route, i) => {
      const Component = route.component;
      return (
        <Route
          key={i}
          exact={route?.exact ?? false}
          path={`${PATHS.base.main}${PATHS.chargers.detail}${route.path}`}
        >
          <Component setTab={setValue} tabValue={route.tabValue} />
        </Route>
      );
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <GenericContentBox
      title={`${t("chargers.main_title")} (${data?.data?.name})`}
      breadcrumbs={breadcrumbs}
    >
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "3rem" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="chargers tabs"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            component={Link}
            to={`${PATHS.base.main}${detailUrl}${PATHS.chargers.tabs.generalInfo}`}
            label={t("chargers.tabs.general.title")}
            sx={{ marginRight: "3rem" }}
          />
          <Tab
            component={Link}
            to={`${PATHS.base.main}${detailUrl}${PATHS.chargers.tabs.chargerRules.main}`}
            label={t("chargers.tabs.rules.title")}
            sx={{ marginRight: "3rem" }}
          />
          <Tab
            component={Link}
            to={`${PATHS.base.main}${detailUrl}${PATHS.chargers.tabs.activities}`}
            label={t("chargers.tabs.activities.title")}
          />
        </Tabs>
      </Box>

      <Switch>{getPaths(chargerTabsPaths)}</Switch>
    </GenericContentBox>
  );
};
