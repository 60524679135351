import { useQuery } from "react-query";

import { useApi } from "../api";
import { config } from "../config";

export const useUserDetails = () => {
  const { get } = useApi();

  return useQuery("user", get(config.endpoints.USER));
};

export const usePartnerDetails = () => {
  const { get } = useApi();

  return useQuery("partner", get(config.endpoints.PARTNER));
};
