import { Box, Grid } from "@material-ui/core";
import React from "react";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { useTranslation } from "react-i18next";

import { TitlePageTopBar } from "../../components/commons/TitlePageTopBar";

export const Contact = () => {
  const { t } = useTranslation();
  return (
    <Box
      fontFamily="Ubuntu"
      fontSize="24px"
      fontWeight="600"
      className="top-title-bar"
    >
      <TitlePageTopBar label={t("contact.title")} />
      <Box
        fontSize="16px"
        fontWeight="600"
        borderRadius={12}
        bgcolor="white"
        style={{
          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.25)",
        }}
        padding="25px 25px 25px 25px"
      >
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12}>
            <Box marginBottom="20px" fontSize="1.5rem" color="#1D71B8">
              {t("contact.country_ch")}
            </Box>
            <Box
              padding="10px 20px"
              borderRadius={8}
              marginBottom="20px"
              bgcolor="rgba(196, 196, 196, 0.18)"
            >
              <Box marginBottom="5px">ShareP AG(SA)</Box>
              <Box marginBottom="20px" fontSize="0.75rem" fontWeight="400">
                <Box>Apfelbaumstrasse 45</Box>
                <Box>8050 Zürich, CH</Box>
              </Box>

              <Box />
              <a
                href="tel:+41445916011"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box marginRight="10px">
                    <ContactPhoneIcon />
                  </Box>
                  <Box>+41 44 591 60 11</Box>
                </Box>
              </a>
              <a
                href="mailto:support@sharep.io"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box marginRight="10px">
                    <ContactMailIcon />
                  </Box>
                  <Box>support@sharep.io</Box>
                </Box>
              </a>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <Box marginBottom="20px" fontSize="1.5rem" color="#1D71B8">
              {t("contact.country_pl")}
            </Box>
            <Box
              padding="10px 20px"
              borderRadius={8}
              marginBottom="20px"
              bgcolor="rgba(196, 196, 196, 0.18)"
            >
              <Box marginBottom="5px">ShareP Poland P.S.A.</Box>
              <Box marginBottom="20px" fontSize="0.75rem" fontWeight="400">
                <Box>Maślicka 169</Box>
                <Box>52-104 Wrocław, PL</Box>
              </Box>

              <Box />
              <a
                href="tel:+48227774141"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box marginRight="10px">
                    <ContactPhoneIcon />
                  </Box>
                  <Box>+48 22 777 4141</Box>
                </Box>
              </a>
              <a
                href="mailto:support@sharep.io"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box marginRight="10px">
                    <ContactMailIcon />
                  </Box>
                  <Box>support@sharep.io</Box>
                </Box>
              </a>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
