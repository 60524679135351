import { useState, useCallback } from "react";

export function useToggleable(initialState = false) {
  const [open, setOpen] = useState(initialState);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return [open, { handleOpen, handleClose }];
}
