import React, { useContext } from "react";

export const Stage = {
  Type: "type",
  Desc: "description",
  SubDuration: "sub_pricing",
  BasicDuration: "basic_pricing",
  BasicDurationsList: "basic_pricing_list",
};

export const Type = {
  Subscription: "subscription",
  Basic: "basic",
};

const initialState = {
  type: "",
  stage: Stage.Type,
  name: "",
  description: "",
  durations: null,
  editedDurationId: null,
};

const AddPricingContext = React.createContext([initialState, () => ({})]);

export const AddPricingContextProvider = ({ children }) => {
  const state = React.useReducer(reducer, initialState);

  return (
    <AddPricingContext.Provider value={state}>
      {children}
    </AddPricingContext.Provider>
  );
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;

    case "setType":
      return {
        ...initialState,
        ...(action.payload === state.type ? state : {}),
        type: action.payload,
        stage: Stage.Desc,
      };

    case "setDescription":
      return {
        ...state,
        ...action.payload,
        stage:
          state.type === Type.Subscription
            ? Stage.SubDuration
            : Stage.BasicDuration,
      };

    case "setBasicDuration":
      return {
        ...state,
        durations: [...(state.durations || []), action.payload],
        stage: Stage.BasicDurationsList,
      };

    case "updateBasicDuration":
      return {
        ...state,
        durations: (state.durations || []).map((d) =>
          d.id === action.payload.id ? action.payload : d
        ),
        stage: Stage.BasicDurationsList,
      };

    case "addBasicDuration":
      return {
        ...state,
        stage: Stage.BasicDuration,
        editedDurationId: null,
      };

    case "editBasicDuration":
      return {
        ...state,
        stage: Stage.BasicDuration,
        editedDurationId: action.payload,
      };

    case "deleteBasicDuration":
      return {
        ...state,
        durations: state.durations.filter((d) => d.id !== action.payload),
        editedDurationId: null,
      };

    default:
      return state;
  }
};

export const usePricingDispatcher = () => {
  const [, dispatch] = useContext(AddPricingContext);
  return React.useMemo(() => dispatch, []);
};

export const usePricingState = (selector) => {
  const context = React.useContext(AddPricingContext);
  const state = context[0];

  return selector
    ? React.useMemo(() => selector(state), [selector, state])
    : context;
};
