import React from "react";
import { useTranslation } from "react-i18next";
import {
  createTheme,
  Grid,
  IconButton,
  ThemeProvider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { Delete, Edit } from "@material-ui/icons";
import * as locales from "@mui/material/locale";
import MaterialReactTable from "material-react-table";

import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";
import { PATHS } from "../../../../libs/routes/paths";
import { useToggleable } from "../../../../libs/utils";
import { ConfirmDeleteRulesDialog } from "../dialogs/ConfirmDeleteRulesDialog";

export const ChargerRulesTable = ({ data }) => {
  const { chargerId } = useParams();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const [dialogContent, setDialogContent] = React.useState({});
  const [open, { handleOpen, handleClose }] = useToggleable();
  const columns = React.useMemo(
    () => [
      {
        accessorFn: (row) => row.name,
        header: t("chargers.tables.name"),
      },
      {
        accessorFn: (row) =>
          row.groups.length > 0
            ? row.groups.map((item) => item.name)?.join(", ")
            : t("chargers.tables.all"),
        header: t("chargers.tables.groups"),
      },
      {
        accessorFn: (row) => row.price_list,
        header: t("chargers.tables.priceList"),
      },
      {
        id: "actions",
        header: t("tables.actions"),
        columnDefType: "display",
        enableColumnOrdering: false,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <>
            <Tooltip title={t("chargers.tooltips.edit")}>
              <IconButton
                key={`${row.id}-edit`}
                onClick={() => {
                  const chargerUrl = generatePath(PATHS.chargers.detail, {
                    chargerId,
                  });
                  const editUrl = generatePath(
                    PATHS.chargers.tabs.chargerRules.edit,
                    {
                      ruleId: row.original.id,
                    }
                  );
                  history.push(
                    `${PATHS.base.main}${chargerUrl}${PATHS.chargers.tabs.chargerRules.main}${editUrl}`
                  );
                }}
                sx={{ m: 0 }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("chargers.tooltips.delete")}>
              <IconButton
                key={`${row.id}-delete`}
                onClick={() => {
                  setDialogContent({
                    id: row.original.id,
                    chargerId,
                  });
                  handleOpen();
                }}
                sx={{ m: 0 }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    ],
    []
  );

  const displayConnectors = (connectors) => {
    if (connectors.length > 0) {
      return connectors.map((item, i) => {
        return (
          <Grid key={`connector-${i}`} item xs={12}>
            <Typography variant="body1">
              {t("chargers.tables.connector")} {item.port}
            </Typography>
          </Grid>
        );
      });
    }
    return (
      <Typography variant="body1">{t("chargers.tables.empty")}</Typography>
    );
  };

  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        key={i18n.language}
        localization={t("tables", { returnObjects: true })}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableSorting
        enableRowActions={false}
        renderDetailPanel={({ row }) => (
          <Grid container>{displayConnectors(row.original.connectors)}</Grid>
        )}
      />
      {open && (
        <ConfirmDeleteRulesDialog
          key={dialogContent.id}
          open={open}
          handleClose={handleClose}
          content={dialogContent}
        />
      )}
    </ThemeProvider>
  );
};
