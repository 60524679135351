import { PATHS } from "../../../../libs/routes/paths";

import { ParkingSubscriptionCalendar } from "./calendar/ParkingSubscriptionCalendar";
import { ParkingSubscriptionAdd } from "./calendar/ParkingSubscriptionAdd";
import { ParkingSubscriptionEdit } from "./calendar/ParkingSubscriptionEdit";

export const PARKINGS_RULES = {
  calendar: {
    path: PATHS.parkings.tabs.parkingSubscriptions.calendar,
    component: ParkingSubscriptionCalendar,
    exact: true,
  },
  create: {
    path: PATHS.parkings.tabs.parkingSubscriptions.create,
    component: ParkingSubscriptionAdd,
  },
  edit: {
    path: PATHS.parkings.tabs.parkingSubscriptions.edit,
    component: ParkingSubscriptionEdit,
  },
};

export const parkingSubscriptionsPaths = [...Object.values(PARKINGS_RULES)];
