import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

import "yup-phone";
import { GenericContentBox } from "../../components/commons/GenericContentBox";
import { useGetGroup } from "../../libs/groups";
import { PATHS } from "../../libs/routes/paths";

import { UserGroupTable } from "./components/UserGroupTable";
import { groupPermissions } from "./consts";

export const GroupDetail = () => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const [groupName, setGroupName] = React.useState("");
  const [permissions, setPermissions] = React.useState([]);
  const { data, isSuccess } = useGetGroup(groupId);
  const [users, setUsers] = React.useState([]);
  const editUrl = generatePath(PATHS.groups.edit, {
    groupId,
  });
  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("groups.breadcrumbs.main"),
        path: `${PATHS.base.main}${PATHS.groups.list}`,
      },
      {
        title: `${data?.data?.display_name}`,
        path: "",
      },
    ],
    [data]
  );
  React.useEffect(() => {
    if (isSuccess) {
      setUsers(data?.data?.users ?? []);
      setPermissions(data?.data?.permissions ?? []);
      setGroupName(data?.data?.name);
    }
  }, [isSuccess]);

  return (
    <GenericContentBox title={t("groups.main_title")} breadcrumbs={breadcrumbs}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1.5rem",
        }}
      >
        <Box sx={{ display: "inline-flex" }}>
          <Typography variant="h5" color="primary" mr="1rem">
            {groupName}
          </Typography>
        </Box>
        {permissions.includes(groupPermissions.editGroup.permission) && (
          <Button
            variant="contained"
            component={Link}
            to={`${PATHS.base.main}${editUrl}`}
            color="primary"
          >
            {t("groups.buttons.edit")}
          </Button>
        )}
      </Box>

      <UserGroupTable
        users={users}
        enableRowActions={false}
        enableRowSelection={false}
      />
    </GenericContentBox>
  );
};
