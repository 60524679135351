import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, Route, Switch, useParams } from "react-router-dom";

import { GenericContentBox } from "../../components/commons/GenericContentBox";
import { PATHS } from "../../libs/routes/paths";
import { useParking } from "../../libs/parkings";

import { parkingTabsPaths } from "./components/tabs/tabRoutes";

export const ParkingDetail = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const { parkingId } = useParams();
  const detailUrl = generatePath(PATHS.parkings.detail, {
    parkingId,
  });
  const { data } = useParking(parkingId);
  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("parkings.breadcrumbs.main"),
        path: `${PATHS.base.main}${PATHS.parkings.list}`,
      },
      {
        title: `${data?.data?.name}`,
        path: "",
      },
    ],
    [data]
  );
  const getPaths = (paths) => {
    return paths.map((route, i) => {
      const Component = route.component;
      return (
        <Route
          key={i}
          exact={route?.exact ?? false}
          path={`${PATHS.base.main}${PATHS.parkings.detail}${route.path}`}
        >
          <Component setTab={setValue} tabValue={route.tabValue} />
        </Route>
      );
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <GenericContentBox
      title={`${t("parkings.main_title")} (${data?.data?.name})`}
      breadcrumbs={breadcrumbs}
    >
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "3rem" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="parking tabs"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            component={Link}
            to={`${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.generalInfo}`}
            label={t("parkings.tabs.general.title")}
            sx={{ marginRight: "3rem" }}
          />
          <Tab
            component={Link}
            to={`${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingSpots.main}`}
            label={t("parkings.tabs.parking_spots.title")}
            sx={{ marginRight: "3rem" }}
          />
          <Tab
            component={Link}
            to={`${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingRules.main}`}
            label={t("parkings.tabs.parking_rules.title")}
            sx={{ marginRight: "3rem" }}
          />
          <Tab
            component={Link}
            to={`${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.activities}`}
            label={t("parkings.tabs.activities.title")}
            sx={{ marginRight: "3rem" }}
          />
          <Tab
            component={Link}
            to={`${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingSubscriptions.main}`}
            label={t("parkings.tabs.calendar.title")}
          />
        </Tabs>
      </Box>

      <Switch>{getPaths(parkingTabsPaths)}</Switch>
    </GenericContentBox>
  );
};
