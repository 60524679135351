import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const PermissionCheckboxes = ({
  permissionList,
  radioHorizontal,
  checkedItems,
  setCheckeditems,
}) => {
  const { control, watch } = useFormContext();
  const { t } = useTranslation();
  const userType = watch("userType");

  const handleCheckBoxChange = (item) => {
    setCheckeditems((prev) =>
      prev.includes(item)
        ? prev.filter((prevItem) => prevItem !== item)
        : [...prev, item]
    );
  };
  return (
    <>
      <Grid item xs={radioHorizontal ? 12 : 4} lg={radioHorizontal ? 12 : 2}>
        <Controller
          name="userType"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} row={radioHorizontal}>
              <FormControlLabel
                label={t("groups.checkboxes.admin")}
                control={<Radio {...field} value="admin" />}
              />
              <FormControlLabel
                label={t("groups.checkboxes.user")}
                control={<Radio {...field} value="user" />}
              />
            </RadioGroup>
          )}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={radioHorizontal ? 12 : 10}
        key={userType}
      >
        {permissionList.map((item) => {
          return (
            <Grid key={item.value} item sm={12} md={5}>
              <FormControlLabel
                key={item.value}
                disabled={userType === "user" || item.default}
                control={
                  <Controller
                    name="permissions"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          {...field}
                          checked={checkedItems.includes(item.value)}
                          onChange={() => handleCheckBoxChange(item.value)}
                          disabled={userType === "user" || item.default}
                        />
                      );
                    }}
                  />
                }
                label={t(`groups.checkboxes.${item.value}`)}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
