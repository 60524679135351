import { PATHS } from "../../../../libs/routes/paths";

import { ParkingSpotActivities } from "./activities/ParkingSpotActivities";
import { GeneralInfo } from "./general_info/GeneralInfo";
import { ParkingSpotRules } from "./rules/ParkingSpotRules";
import { ParkingSpotAccesses } from "./accesses/ParkingSpotAccesses";

export const PARKING_SPOT_TABS = {
  generalInfo: {
    path: PATHS.parkingSpots.tabs.generalInfo,
    component: GeneralInfo,
    tabValue: 0,
    exact: true,
  },
  parkingRules: {
    path: PATHS.parkingSpots.tabs.parkingSpotRules.main,
    component: ParkingSpotRules,
    tabValue: 1,
  },
  activities: {
    path: PATHS.parkingSpots.tabs.activities,
    component: ParkingSpotActivities,
    tabValue: 2,
  },
  accesses: {
    path: PATHS.parkingSpots.tabs.parkingSpotAccesses.main,
    component: ParkingSpotAccesses,
    tabValue: 0,
  },
};

export const parkingSpotTabsPaths = [...Object.values(PARKING_SPOT_TABS)];
