import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import Select from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import i18n from "../../../../i18n";
import { getWeekDays } from "../../consts";


const validationSchema = () =>
  yup
    .object()
    .required()
    .shape({
      weekday: yup
        .string()
        .required(i18n.t("parkings.fields.weekday.required")),
      start: yup.string().required(i18n.t("parkings.fields.start.required")),
      end: yup
        .string()
        .required(i18n.t("parkings.fields.end.required"))
        .test(
          "end-less-then-start",
          i18n.t("parkings.fields.end.less_then_start"),
          (value, context) => {
            const { start } = context.parent;
            return moment(value, "HH:mm").isAfter(moment(start, "HH:mm"));
          }
        ),
    });
export const AvailabilitiesEditDialog = ({
  open,
  handleClose,
  content,
  updateItem,
}) => {
  const { t } = useTranslation();
  const localeValidationSchema = React.useMemo(
    () => validationSchema(),
    [i18n.language]
  );
  const availabilitiesMethods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    resolver: yupResolver(localeValidationSchema),
  });

  React.useEffect(() => {
    if (content) {
      availabilitiesMethods.reset(content);
    }
  }, [content]);

  const onSubmit = availabilitiesMethods.handleSubmit((payload) => {
    const data = {};
    data.weekday = payload.weekday;
    data.start = payload.start;
    data.end = payload.end;
    updateItem(payload.id, data);
    handleClose();
  });

  return (
    <Grid container spacing={3} maxWidth="10rem">
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          {t("parkings.dialogs.edit_availability_title")}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            item
            xs={12}
            columnGap="1rem"
            rowGap="1rem"
            mb="1.5rem"
            pt="1rem"
          >
            <Controller
              name="weekday"
              defaultValue=""
              control={availabilitiesMethods.control}
              render={({
                field: { ref, value, onChange, ...fieldProps },
                fieldState: { error },
              }) => (
                <Box sx={{ maxWidth: "10rem", width: "100%" }}>
                  <Select
                    {...fieldProps}
                    inputRef={ref}
                    options={getWeekDays()}
                    value={
                        getWeekDays().find((item) => item.value === value) || null
                    }
                    onChange={(val) => onChange(val.value)}
                    placeholder={t("parkings.fields.weekday.placeholder")}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {error?.message && (
                    <FormHelperText error>{error?.message}</FormHelperText>
                  )}
                </Box>
              )}
            />
            <Box sx={{ display: "inline-flex" }}>
              <Box sx={{ marginRight: "1rem" }}>
                <Controller
                  name="start"
                  control={availabilitiesMethods.control}
                  defaultValue="00:00"
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...fieldProps}
                        inputRef={ref}
                        size="small"
                        label={t("parkings.fields.start.label")}
                        type="time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        sx={{
                          maxWidth: "11rem",
                          width: "100%",
                        }}
                      />
                      {error?.message && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="end"
                  control={availabilitiesMethods.control}
                  defaultValue="23:59"
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        {...fieldProps}
                        inputRef={ref}
                        label={t("parkings.fields.end.label")}
                        type="time"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        sx={{ maxWidth: "11rem", width: "100%" }}
                      />
                      {error?.message && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("parkings.buttons.cancel")}</Button>
          <Button
            onClick={() => availabilitiesMethods.handleSubmit(onSubmit)()}
          >
            {t("parkings.buttons.change")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
