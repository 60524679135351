import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useRemoveParkingRules } from "../../../../libs/parkings";

export const ConfirmDeleteRulesDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();
  const del = useRemoveParkingRules(content.id, content.parkingId);

  const handleDelete = () => {
    del();
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("parkings.dialogs.delete_rule_title")}</DialogTitle>
        <DialogContent>
          <Typography>{t("parkings.dialogs.delete_rule_text")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("parkings.buttons.cancel")}</Button>
          <Button onClick={handleDelete}>{t("parkings.buttons.delete")}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
