import { Box, TextField } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";

export const PricingTypeContent = ({ parkingDuration, control, label, name }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      paddingLeft="20px"
    >
      <Box marginBottom="20px" fontSize="18px">
        {parkingDuration}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width="80px">
          <Controller
            name={name}
            control={control}
            render={({
              field: { ref, ...fieldProps },
              fieldState: { invalid, error },
            }) => (
              <TextField
                {...fieldProps}
                variant="outlined"
                label={label}
                size="small"
                type="number"
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
