import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useApi } from '../../libs/api'
import { config } from '../../libs/config'

//TODO: change the name of this file to something more meaningfull - it should be more obvious that you keep here hooks, not some static data

export const useParking = (parkingId) => {
  const { get } = useApi();
  return useQuery(
    ["parking", parkingId],
    get(`${config.endpoints.PARKINGS}${parkingId}/`),
    { staleTime: 0 }
  );
};

export const useParkingEntitlements = (parkingId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-history-entitlements", parkingId],
    get(`${config.endpoints.PARKING_ENTITLEMENTS}${parkingId}/entitlements/`)
  );
};

export const usePartnerParkingEntitlements = (parkingId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-partner-entitlements", parkingId],
    get(`${config.endpoints.PARKING_ENTITLEMENTS}${parkingId}/entitlements/`),
    {
      enabled: !!parkingId,
    }
  );
};

export const useParkingHistory = (parkingId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-history-reservations", parkingId],
    get(`${config.endpoints.PARKINGS}${parkingId}/reservations/`),
    {
      enabled: !!parkingId,
    }
  );
};

export const usePartnerParkingHistory = (parkingId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-history-reservations", parkingId],
    get(`${config.endpoints.PARTNER_RESERVATIONS}${parkingId}/reservations/`),
    {
      enabled: !!parkingId,
    }
  );
};

export const useEntryDevices = (parkingId) => {
  const { get } = useApi();

  return useQuery(
    "entry-devices",
    get(`${config.endpoints.ENTRY_DEVICES}${parkingId}/`)
  );
};

export const useAddEntryDevice = (spotId) => {
  // console.log(payload);
  const queryClient = useQueryClient();
  const { post } = useApi();
  const { mutate } = useMutation(
    post(`${config.endpoints.SPOTS_ENTRY_DEVICES}${spotId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries("parking");
      },
    }
  );
  return mutate;
};

export const useAddParkingSpot = (payload) => {
  const queryClient = useQueryClient();
  const { post } = useApi();
  const { mutate } = useMutation(
    post(`parkings/${payload?.parkingId}/spots/`, payload),
    {
      onSuccess: () => {
        queryClient.refetchQueries("parking");
      },
    }
  );
  return mutate;
};

export const useRemoveParkingSpot = (payload) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(`${config.endpoints.PARKING_SPOTS}${payload}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries("parking");
      },
    }
  );
  return mutate;
};

export const useEditParking = (parkingId) => {
  const { patch } = useApi();
  const { mutate } = useMutation(
    patch(`${config.endpoints.PARKINGS}${parkingId}/`)
  );
  return mutate;
};

export const useUpdateMainPhoto = (parkingId) => {
  const { put } = useApi();
  const { mutate } = useMutation(
    put(`${config.endpoints.PARKINGS}${parkingId}/main-photo/`)
  );
  return mutate;
};

export const useAddPdf = (parkingId) => {
  const queryClient = useQueryClient();
  const { post } = useApi();
  const { mutate } = useMutation(post(`parkings/${parkingId}/pdfs/`), {
    onSuccess: () => {
      queryClient.refetchQueries("parking");
    },
  });
  return mutate;
};

export const useRemovePdf = (pdfId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(del(`parkings/pdfs/${pdfId}/`), {
    onSuccess: () => {
      queryClient.refetchQueries("parking");
    },
  });

  return mutate;
};

export const useAddToGallery = (parkingId) => {
  const { post } = useApi();
  const { mutate } = useMutation(
    post(`${config.endpoints.PARKINGS}${parkingId}/gallery/`)
  );
  return mutate;
};

export const useRemoveFromGallery = (parkingId) => {
  const [entryId, setEntryId] = useState("");
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(`${config.endpoints.PARKINGS}${parkingId}/gallery/${entryId}/`)
  );
  const remove = (entryId) => {
    setEntryId(entryId);
    mutate();
  };
  return remove;
};

export const useUpdateParking = (parkingId) => {
  const result = useParking(parkingId);
  const parkingItem = result.data?.data;

  const queryClient = useQueryClient();
  const updateParking = useEditParking(parkingId);
  const updateMainPhoto = useUpdateMainPhoto(parkingId);
  const addToGallery = useAddToGallery(parkingId);
  const removeFromGallery = useRemoveFromGallery(parkingId);

  return {
    result,
    updateParking: (payload) => {
      // deleting obsolete gallery items
      parkingItem?.gallery.forEach(({ id }) => {
        if (!payload.gallery.find((g) => g.id === id)) {
          removeFromGallery(id);
        }
      });

      // adding new gallery items
      payload.gallery.forEach(({ file }) => {
        if (file) {
          const formData = new FormData();
          formData.append("image", file);

          addToGallery(formData);
        }
      });

      // updating main photo
      if (payload.mainImage.file) {
        const formData = new FormData();
        formData.append("main_image", payload.mainImage.file);

        updateMainPhoto(formData);
      }

      // updating form data
      updateParking({
        ...(parkingItem || {}),
        description: payload.description,
        price_list: payload.priceList,
        spots: payload.spots,
      });

      // invalidating list data
      queryClient.invalidateQueries("parkings");
    },
  };
};
