import { useQuery } from "react-query";

import { useApi } from "../api";
import { config } from "../config";

export const useTransactionsHistory = () => {
  const { get } = useApi();

  return useQuery("transactions", get(config.endpoints.TRANSACTIONS));
};
