import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

import { MainBusinessLogo } from "../../components/commons/MainBusinessLogo";
import { NavigationLink } from "../../components/commons/NavigationLink";
import { usePartnerDetails } from "../../libs/user";

export const SideBarMenuPartner = ({ menuList }) => {
  // const mobileActive = useMediaQuery("(max-width: 414px)");
  //TODO: - avoid plain text - use Object.freeze instead
  const tabletActive = useMediaQuery("(max-width: 1200px)");
  const { t } = useTranslation();
  const result = usePartnerDetails();
  const { url } = useRouteMatch();

  const userGroup = result.data?.data?.groups ?? [];

  const getMenuList = () => {
    return menuList
      .filter((item) => userGroup.includes(item.permission))
      .map((link, i) => {
        return (
          <NavigationLink
            key={i}
            to={`${url}${link.to}`}
            label={t(`${link.label}`)}
            icon={<link.icon />}
          />
        );
      });
  };
  return (
    <Box bgcolor="white" height="100vh" padding="20px 0 20px 20px">
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#1D71B8"
        borderRadius={12}
        height="100%"
        padding="5px"
      >
        <Box marginBottom="50px">
          <Box
            padding={
              (tabletActive && "15px 5px 5px 15px") || "30px 15px 15px 31px"
            }
            marginBottom="20px"
          >
            <MainBusinessLogo />
          </Box>
          {getMenuList()}
        </Box>
      </Box>
    </Box>
  );
};
