import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useApi } from "../api";
import { config } from "../config";
import { PATHS } from "../routes/paths";

export const useParkings = () => {
  const { get } = useApi();

  return useQuery("parkings", get(config.endpoints.USER_PARKINGS));
};

export const useParkingsSpots = (id) => {
  const { get } = useApi();

  return useQuery(["parkings", id], get(`${config.endpoints.PARKINGS}${id}/`), {
    enabled: !!id,
  });
};

export const useAddParkingSpot = (parkingId) => {
  const { post } = useApi();
  const { mutateAsync } = useMutation(post(`parkings/${parkingId}/spots/`));
  return mutateAsync;
};

export const useRemoveParkingSpot = (spotId, parkingId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(`${config.endpoints.PARKING_SPOTS}${spotId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking", parkingId, "parking-spots"]);
      },
    }
  );
  return mutate;
};

export const useGetParkingSpots = (parkingId, admin = true) => {
  const { get } = useApi();

  return useQuery(
    ["parking", parkingId, "parking-spots"],
    get(`deprecated/admin-panel/parkings/${parkingId}/spots/`, { admin })
  );
};

export const useEntryDevices = (parkingId) => {
  const { get } = useApi();

  return useQuery(
    "entry-devices",
    get(`${config.endpoints.ENTRY_DEVICES}${parkingId}/`)
  );
};

export const useAddEntryDevice = (spotId, parkingId) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { post } = useApi();
  const { mutate } = useMutation(
    post(`${config.endpoints.SPOTS_ENTRY_DEVICES}${spotId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries("parking-spots");
        const detailUrl = generatePath(PATHS.parkings.detail, {
          parkingId,
        });
        history.push(
          `${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingSpots.main}`
        );
      },
    }
  );
  return mutate;
};

export const useParkingHistory = (parkingId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-history-reservations", parkingId],
    get(`${config.endpoints.PARKINGS}${parkingId}/reservations/`),
    {
      enabled: !!parkingId,
    }
  );
};

export const useParkingEntitlements = (parkingId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-history-entitlements", parkingId],
    get(`${config.endpoints.PARKING_ENTITLEMENTS}${parkingId}/entitlements/`)
  );
};

export const useParking = (parkingId) => {
  const { get } = useApi();
  return useQuery(
    ["parking", parkingId],
    get(`${config.endpoints.PARKINGS}${parkingId}/`),
    { staleTime: 0 }
  );
};

export const useGetParkingRules = (parkingId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-rules", parkingId],
    get(`deprecated/admin-panel/parkings/${parkingId}/rules/`)
  );
};

export const useAddParkingRules = (parkingId) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { post } = useApi();
  const { mutate } = useMutation(
    post("deprecated/admin-panel/parkings/rules/"),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking-rules", parkingId]);
        const detailUrl = generatePath(PATHS.parkings.detail, {
          parkingId,
        });
        history.push(
          `${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingRules.main}`
        );
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useGetParkingRule = (parkingId, ruleId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-rules", parkingId, ruleId],
    get(`deprecated/admin-panel/parkings/rules/${ruleId}/`)
  );
};

export const useEditParkingRules = (parkingId, ruleId) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { patch } = useApi();
  const { mutate } = useMutation(
    patch(`deprecated/admin-panel/parkings/rules/${ruleId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking-rules", parkingId]);
        const detailUrl = generatePath(PATHS.parkings.detail, {
          parkingId,
        });
        history.push(
          `${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingRules.main}`
        );
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useUpdateParkingDescription = (parkingId) => {
  const queryClient = useQueryClient();
  const { patch } = useApi();
  const { mutate } = useMutation(
    patch(`deprecated/admin-panel/maintenance/parkings/${parkingId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking", parkingId]);
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useRemoveParkingRules = (rulesId, parkingId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(`deprecated/admin-panel/parkings/rules/${rulesId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking-rules", parkingId]);
      },
    }
  );
  return mutate;
};

export const useRemoveParkingGalleryItem = (parkingId, imageId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(`parkings/${parkingId}/gallery/${imageId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking", parkingId]);
      },
    }
  );
  return mutate;
};

export const useAddParkingGalleryItem = (parkingId) => {
  const queryClient = useQueryClient();
  const { post } = useApi();
  const { mutate } = useMutation(post(`parkings/${parkingId}/gallery/`), {
    onSuccess: () => {
      queryClient.refetchQueries(["parking", parkingId]);
    },
    onError: (error) => {
      toast.error(error.response?.data?.errors?.[0]?.message);
    },
  });
  return mutate;
};
export const useRemoveParkingMainImage = (parkingId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(del(`parkings/${parkingId}/main-photo/`), {
    onSuccess: () => {
      queryClient.refetchQueries(["parking", parkingId]);
    },
  });
  return mutate;
};

export const useAddParkingMainImage = (parkingId) => {
  const queryClient = useQueryClient();
  const { put } = useApi();
  const { mutate } = useMutation(put(`parkings/${parkingId}/main-photo/`), {
    onSuccess: () => {
      queryClient.refetchQueries(["parking", parkingId]);
    },
    onError: (error) => {
      toast.error(error.response?.data?.errors?.[0]?.message);
    },
  });
  return mutate;
};

export const useAddParkingSpotSubscription = (parkingId) => {
  const history = useHistory();

  const { post } = useApi();
  const { mutate } = useMutation(
    post(
      `deprecated/admin-panel/maintenance/parkings/${parkingId}/user-subscriptions/`
    ),
    {
      onSuccess: () => {
        const detailUrl = generatePath(PATHS.parkings.detail, {
          parkingId,
        });
        history.push(
          `${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingSubscriptions.main}`
        );
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useRemoveParkingSubscription = (subscriptionId) => {
  const { delete: del } = useApi();
  const { mutate } = useMutation(del(`subscriptions/${subscriptionId}/`));
  return mutate;
};

export const useRemoveParkingReservation = (reservationId) => {
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(`deprecated/admin-panel/maintenance/reservations/${reservationId}/`)
  );
  return mutate;
};

export const useGetParkingSubscription = (subscriptionId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-subscription", subscriptionId],
    get(`subscriptions/${subscriptionId}/`)
  );
};

export const useEditParkingSpotSubscription = (subscriptionId, parkingId) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { patch } = useApi();
  const { mutate } = useMutation(patch(`subscriptions/${subscriptionId}/`), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["parking-subscription", subscriptionId],
      });
      const detailUrl = generatePath(PATHS.parkings.detail, {
        parkingId,
      });
      history.push(
        `${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingSubscriptions.main}`
      );
    },
    onError: (error) => {
      toast.error(error.response?.data?.errors?.[0]?.message);
    },
  });
  return mutate;
};
