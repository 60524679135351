import React from "react";
import { Route, Switch } from "react-router-dom";

import { PATHS } from "../../../../../libs/routes/paths";
import { chargerRulesPaths } from "../chargerRulesPaths";

export const ChargerRules = ({ setTab, tabValue }) => {
  const getPaths = (paths) => {
    return paths.map((route, i) => {
      return (
        <Route
          key={i}
          exact={route?.exact ?? false}
          path={`${PATHS.base.main}${PATHS.chargers.detail}${PATHS.chargers.tabs.chargerRules.main}${route.path}`}
          component={route.component}
        />
      );
    });
  };
  React.useEffect(() => {
    setTab(tabValue);
  }, []);

  return (
    <>
      <Switch>{getPaths(chargerRulesPaths)}</Switch>
    </>
  );
};
