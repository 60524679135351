import { PATHS } from "../../../../libs/routes/paths";

import { ChargerRulesList } from "./charger_rules/ChargerRulesList";
import { ChargerRulesAdd } from "./charger_rules/ChargerRulesAdd";
import { ChargerRulesEdit } from "./charger_rules/ChargerRulesEdit";

export const CHARGER_RULES = {
  list: {
    path: PATHS.chargers.tabs.chargerRules.list,
    component: ChargerRulesList,
    exact: true,
  },
  create: {
    path: PATHS.chargers.tabs.chargerRules.create,
    component: ChargerRulesAdd,
  },
  edit: {
    path: PATHS.chargers.tabs.chargerRules.edit,
    component: ChargerRulesEdit,
  },
};

export const chargerRulesPaths = [...Object.values(CHARGER_RULES)];
