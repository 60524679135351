import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useRemoveParkingSpotRules } from "../../../../libs/parking-spots";

export const ConfirmDeleteRulesDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();
  const del = useRemoveParkingSpotRules(content.id, content.parkingSpotId);

  const handleDelete = () => {
    del();
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {t("parking_spots.dialogs.delete_rule_title")}
        </DialogTitle>
        <DialogContent>
          <Typography>{t("parking_spots.dialogs.delete_rule_text")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t("parking_spots.buttons.cancel")}
          </Button>
          <Button onClick={handleDelete}>
            {t("parking_spots.buttons.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
