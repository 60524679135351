import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { useDeleteGroup } from "../../../libs/groups";

export const ConfirmDeleteGroupDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();
  const { userCount } = content;
  const del = useDeleteGroup(content.id);

  const handleDelete = () => {
    del();
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("groups.dialogs.delete_group_title")}</DialogTitle>
        <DialogContent>
          {content.userCount > 0 && (
            <Typography sx={{ marginBottom: "0.625rem" }}>
              <Trans
                i18nKey="groups.dialogs.delete_group_with_users_text"
                userCount={content.userCount}
              >
                W grupie znajduje się {{ userCount }} użytkowników.
              </Trans>
            </Typography>
          )}
          <Typography>{t("groups.dialogs.delete_group_text")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("groups.buttons.cancel")}</Button>
          <Button onClick={handleDelete}>{t("groups.buttons.delete")}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
