import { PATHS } from "../../../../libs/routes/paths";

import { ParkingSpotRulesAdd } from "./rules/ParkingSpotRulesAdd";
import { ParkingSpotRulesEdit } from "./rules/ParkingSpotRulesEdit";
import { ParkingSpotRulesList } from "./rules/ParkingSpotRulesList";

export const PARKING_SPOT_RULES = {
  list: {
    path: PATHS.parkingSpots.tabs.parkingSpotRules.list,
    component: ParkingSpotRulesList,
    exact: true,
  },
  create: {
    path: PATHS.parkingSpots.tabs.parkingSpotRules.create,
    component: ParkingSpotRulesAdd,
  },
  edit: {
    path: PATHS.parkings.tabs.parkingRules.edit,
    component: ParkingSpotRulesEdit,
  },
};

export const rulesPaths = [...Object.values(PARKING_SPOT_RULES)];
