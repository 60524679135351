import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";

import { regularDashboardPaths } from "../../libs/routes/routes";
import { PATHS } from "../../libs/routes/paths";

export const MainRegularPage = () => {
  //TODO: - avoid plain text - use Object.freeze instead
  const mobileActive = useMediaQuery("(max-width: 414px)");
  const { url } = useRouteMatch();
  const getPaths = (paths) => {
    return paths.map((route, i) => {
      return (
        <Route
          key={i}
          exact={route?.exact ?? false}
          path={`${url}${route.path}`}
          component={route.component}
        />
      );
    });
  };
  return (
    <Box
      height="100vh"
      padding={(mobileActive && "10px 10px 0 10px") || "20px 30px 0 30px"}
    >
      <Switch>
        {getPaths(regularDashboardPaths)}

        <Route key="not-found" path="*">
          <Redirect to={{ pathname: `${PATHS.base.welcomePage}` }} />
        </Route>
      </Switch>
    </Box>
  );
};
