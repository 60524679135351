import { Add } from "@material-ui/icons";
import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

import { useGetParkingSpotRules } from "../../../../../libs/parking-spots";
import { PATHS } from "../../../../../libs/routes/paths";
import { ParkingSpotRulesTable } from "../../tables/ParkingSpotRulesTable";

export const ParkingSpotRulesList = () => {
  const { parkingSpotId } = useParams();
  const { t } = useTranslation();
  const { data, isSuccess } = useGetParkingSpotRules(parkingSpotId);
  const detailUrl = generatePath(PATHS.parkingSpots.detail, { parkingSpotId });
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          component={Link}
          to={`${PATHS.base.main}${detailUrl}${PATHS.parkingSpots.tabs.parkingSpotRules.main}${PATHS.parkingSpots.tabs.parkingSpotRules.create}`}
          startIcon={<Add />}
          variant="text"
          color="primary"
          sx={{
            fontSize: "1rem",
          }}
        >
          {t("parking_spots.buttons.add_rule")}
        </Button>
      </Box>
      {isSuccess && <ParkingSpotRulesTable data={data?.data ?? []} />}
    </>
  );
};
