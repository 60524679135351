import { useState } from "react";

export const usePagination = (data, itemsPerPage) => {
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.data ? data.data.data.length : 1  / itemsPerPage); 

    const currentData = () => {
      const begin = (currentPage - 1) * itemsPerPage;
      const end = begin + itemsPerPage;

      return data.data.data.concat().reverse().slice(begin, end);
    };

    const next = () => {
      setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    };

    const prev = () => {
      setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    };

    const jump = (page) => {
      const pageNumber = Math.max(1, page);
      setCurrentPage(() => Math.min(pageNumber, maxPage));
    };

    return { next, prev, jump, currentData, currentPage, maxPage };
};
