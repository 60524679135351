import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";

import { useBusinessLogin } from "../../hooks";

const validationSchema = yup
  .object()
  .required()
  .shape({
    username: yup
      .string()
      .required("Email is required")
      .email("Must be a valid email"),
    password: yup.string().required("Password is required"),
  });

export const LoginWindowBusiness = () => {
  const login = useBusinessLogin();

  const { handleSubmit, control } = useForm({
    // defaultValues,
    criteriaMode: "onSubmit",
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const onSubmit = handleSubmit((payload) => {
    login(payload);
  });

  const { t } = useTranslation();

  return (
    <Card
      sx={{
        maxWidth: 310,
        borderRight: "1px solid rgba(0, 0, 0, 0.25)",
        boxShadow: "none",
      }}
    >
      <form noValidate onSubmit={onSubmit}>
        <CardContent>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "1.25rem",
                fontWeight: "600",
                fontFamily: "Ubuntu",
              }}
              variant="h5"
            >
              {t("login_box_business_title")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "1.25rem",
                textAlign: "center",
                fontWeight: "200",
              }}
            >
              {t("login_box_business_description")}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ marginBottom: "1.375rem" }}
            >
              <Controller
                name="username"
                control={control}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <TextField
                    {...fieldProps}
                    margin="normal"
                    required
                    inputRef={ref}
                    label={t("login_box_email")}
                    placeholder="eg.: email@address.com"
                    variant="outlined"
                    fullWidth
                    size="normal"
                    error={!!error}
                    helperText={error?.message || ""}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <FormControl
                    error={!!error}
                    variant="outlined"
                    size="normal"
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      {t("login_box_password")}
                    </InputLabel>
                    <OutlinedInput
                      {...fieldProps}
                      inputRef={ref}
                      error={!!error}
                      label={t("login_box_password")}
                      id="outlined-adornment-password"
                      aria-describedby="password-error-text"
                      type={values.showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {error?.message && (
                      <FormHelperText id="password-error-text">
                        {error?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>

            <ButtonGroup orientation="vertical" fullWidth>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
              >
                {t("buttons.login")}
              </Button>
            </ButtonGroup>
          </Box>
        </CardContent>
      </form>
    </Card>
  );
};
