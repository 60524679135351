import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Box,
  Button,
  Grid,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Delete } from "@material-ui/icons";
import { FilePresent } from "@mui/icons-material";

import {
  useAddParkingSpotPdf,
  useRemoveParkingSpotPdf,
} from "../../../../../libs/parking-spots";

export const PdfListItem = ({ pdf, parkingSpotId }) => {
  const removePdf = useRemoveParkingSpotPdf(pdf.id, parkingSpotId);
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={() => removePdf()}>
          <Delete />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <FilePresent />
      </ListItemAvatar>
      <ListItemText primary={pdf.file_name} />
    </ListItem>
  );
};

export const ParkingSpotRegulations = ({ files }) => {
  const { parkingSpotId } = useParams();
  const addPdf = useAddParkingSpotPdf(parkingSpotId);
  const { t } = useTranslation();

  const formData = new FormData();
  const onChangeHandler = (event) => {
    const pdfFile = event.target.files[0];
    formData.append("file_name", pdfFile.name);
    formData.append("file", pdfFile);

    addPdf(formData);
  };
  return (
    <Grid item xs={12}>
      <Box marginBottom="0.625rem">
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: 700,
          }}
        >
          {t("parking_spots.tabs.general.regulations.title")}
        </Typography>
        {files.length === 0 ? (
          <Box sx={{ display: "inline-flex", alignItems: "baseline" }}>
            <Typography variant="body1">
              {t("parking_spots.tabs.general.regulations.empty")}
            </Typography>
            <Button
              variant="text"
              component="label"
              color="primary"
              size="small"
            >
              <input
                accept="pdf/*"
                multiple
                type="file"
                onChange={onChangeHandler}
                hidden
              />
              {t("parking_spots.buttons.add_regulations")}
            </Button>
          </Box>
        ) : (
          <List>
            {files.map((item) => (
              <PdfListItem
                key={item.id}
                pdf={item}
                parkingSpotId={parkingSpotId}
              />
            ))}
          </List>
        )}
      </Box>
    </Grid>
  );
};
