import {
  Apartment,
  ContactSupport,
  EvStation,
  Group,
  History,
  Policy,
  Style,
  Tune,
} from "@material-ui/icons";
import { QrCode, Quiz } from "@mui/icons-material";

import { PATHS } from "./paths";

export const regularDashboardMenuList = [
  {
    to: PATHS.parkings.list,
    label: "buttons.navbar_carparks",
    icon: Apartment,
  },
  {
    to: PATHS.chargers.list,
    label: "buttons.chargers",
    icon: EvStation,
  },
  {
    to: PATHS.pricings.list,
    label: "buttons.navbar_pricing",
    icon: Style,
  },
  {
    to: PATHS.groups.list,
    label: "buttons.navbar_groups",
    icon: Group,
  },
  {
    to: PATHS.base.parkingHistory,
    label: "buttons.navbar_history",
    icon: History,
  },
  {
    to: PATHS.base.contact,
    label: "buttons.navbar_contact",
    icon: ContactSupport,
  },
  {
    to: PATHS.base.settings,
    label: "buttons.navbar_settings",
    icon: Tune,
  },
  {
    to: PATHS.base.faq,
    label: "buttons.navbar_faq",
    icon: Quiz,
  },
];

export const businessDashboardMenuList = [
  {
    to: PATHS.base.maintainer,
    label: "business_client.nav_maintainer",
    icon: Policy,
    permission: "PARKING_MAINTAINER",
  },
  {
    to: PATHS.base.discount,
    label: "business_client.nav_promo",
    icon: QrCode,
    permission: "QR_PROMO_PROVIDER",
  },
];
