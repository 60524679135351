import { Add, Edit } from "@material-ui/icons";
import { Button, IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useUpdateParkingSpotDescription } from "../../../../../libs/parking-spots";

const DESCRIPTION_MAX_LENGTH = 480;

export const ParkingSpotDescription = ({ description }) => {
  const { t } = useTranslation();

  const [descriptionCount, setDescriptionCount] = React.useState(
    description?.length ?? 0
  );

  const [isEdit, setIsEdit] = React.useState(false);
  const { parkingSpotId } = useParams();

  const updateParkingDescription =
    useUpdateParkingSpotDescription(parkingSpotId);
  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
  });

  React.useEffect(() => {
    if (description?.length > 0) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [description]);

  const handleEdit = () => {
    setIsEdit(true);
    methods.setValue("description", description);
  };

  const onSubmit = (payload) => {
    updateParkingDescription(payload);
    setIsEdit(false);
  };
  return (
    <>
      <Box width="100%" marginBottom="20px">
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: 700,
          }}
        >
          {t("parking_spots.tabs.general.description.title")}
          {!isEdit && (
            <IconButton color="primary" onClick={handleEdit}>
              <Edit />
            </IconButton>
          )}
        </Typography>
        {!isEdit ? (
          <Typography variant="body1">{description}</Typography>
        ) : (
          <Controller
            name="description"
            rules={{ required: t("parking_spots.fields.description.required") }}
            key={description}
            control={methods.control}
            render={({
              field: { ref, onChange, ...fieldProps },
              fieldState: { error },
            }) => (
              <TextField
                {...fieldProps}
                inputRef={ref}
                multiline
                variant="outlined"
                placeholder={t("parking_spots.fields.description.placeholder")}
                fullWidth
                onChange={(event) => {
                  setDescriptionCount(event.target.value.length);
                  onChange(event);
                }}
                inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
                minRows={6}
                maxRows={6}
                helperText={error?.message}
              />
            )}
          />
        )}
      </Box>
      {isEdit && (
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography sx={{ marginRight: "0.5rem" }}>
            {descriptionCount}/{DESCRIPTION_MAX_LENGTH}{" "}
            {t("parking_spots.tabs.general.description.chars")}
          </Typography>
          <Button
            onClick={() => methods.handleSubmit(onSubmit)()}
            variant="contained"
            startIcon={<Add />}
            color="primary"
          >
            {t("parking_spots.buttons.save")}
          </Button>
        </Box>
      )}
    </>
  );
};
