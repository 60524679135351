import React from "react";
import { useTranslation } from "react-i18next";
import MaterialReactTable from "material-react-table";
import { ListItemIcon, Box, Button, MenuItem } from "@mui/material";
import { Add, Delete, Edit, Visibility } from "@material-ui/icons";
import { generatePath, Link, useHistory } from "react-router-dom";

import { useGroups } from "../../libs/groups";
import { PATHS } from "../../libs/routes/paths";
import { GenericContentBox } from "../../components/commons/GenericContentBox";
import { useToggleable } from "../../libs/utils";

import { groupPermissions } from "./consts";
import { ConfirmDeleteGroupDialog } from "./components/ConfirmDeleteGroupDialog";

export const GroupsList = () => {
  const { t } = useTranslation();
  const groups = useGroups();
  const [dialogContent, setDialogContent] = React.useState({});

  const [open, { handleOpen, handleClose }] = useToggleable();

  const history = useHistory();
  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("groups.breadcrumbs.main"),
        path: "",
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "display_name",
        header: t("groups.table.name"),
      },
      {
        accessorKey: "user_count",
        header: t("groups.table.users_count"),
        enableColumnFilter: false,
        size: 50,
      },
    ],
    []
  );

  return (
    <>
      <GenericContentBox
        title={t("groups.main_title")}
        breadcrumbs={breadcrumbs}
      >
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            component={Link}
            to={`${PATHS.base.main}${PATHS.groups.create}`}
            startIcon={<Add />}
            variant="text"
            color="primary"
            sx={{
              fontSize: "1rem",
            }}
          >
            {t("groups.buttons.add_new_group")}
          </Button>
        </Box>
        {groups.isFetched && (
          <MaterialReactTable
            columns={columns}
            data={groups.data.data}
            enableColumnActions={false}
            enableColumnDragging={false}
            enableHiding={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableRowActions
            positionActionsColumn="last"
            enableSorting
            renderRowActionMenuItems={({ closeMenu, row }) => [
              row.original.permissions.includes(
                groupPermissions.viewGroup.permission
              ) && (
                <MenuItem
                  key={`${row.id}-view`}
                  onClick={() => {
                    const url = generatePath(PATHS.groups.detail, {
                      groupId: row.original.id,
                    });
                    history.push(`${PATHS.base.main}${url}`);
                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <Visibility />
                  </ListItemIcon>
                  {t("groups.table.details")}
                </MenuItem>
              ),
              row.original.permissions.includes(
                groupPermissions.editGroup.permission
              ) && (
                <MenuItem
                  key={`${row.id}-edit`}
                  onClick={() => {
                    const url = generatePath(PATHS.groups.edit, {
                      groupId: row.original.id,
                    });
                    history.push(`${PATHS.base.main}${url}`);
                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  {t("groups.table.edit")}
                </MenuItem>
              ),
              row.original.permissions.includes(
                groupPermissions.deleteGroup.permission
              ) &&
                !row.original.in_use && (
                  <MenuItem
                    key={`${row.id}-delete`}
                    onClick={() => {
                      setDialogContent({
                        userCount: row.original.user_count,
                        id: row.original.id,
                      });
                      handleOpen();
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    {t("groups.table.delete_user")}
                  </MenuItem>
                ),
            ]}
          />
        )}
      </GenericContentBox>
      {open && (
        <ConfirmDeleteGroupDialog
          key={dialogContent.id}
          open={open}
          handleClose={handleClose}
          content={dialogContent}
        />
      )}
    </>
  );
};
