import { Box, Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

import { usePricingDispatcher } from "../AddPricingContext";
import { useAddPricings } from "../../../../libs/price-lists";

const validationSchema = yup
  .object()
  .required()
  .shape({
    name: yup.string().required("Nazwa jest wymagana"),
    description: yup.string(),
  });

export const PricingDecriptionStep = () => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const dispatch = usePricingDispatcher();
  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      name: "",
      currency: "PLN",
    },
    criteriaMode: "all",
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const addPricing = useAddPricings();
  const onNextStep = handleSubmit((payload) => {
    addPricing(payload)
      .then((response) => {
        dispatch({
          type: "setDescription",
          payload: { id: response.data.id, ...payload },
        });
      })
      .catch((errorMessage) =>
        setError(errorMessage.response.data.errors[0].message)
      );
  });

  return (
    <Box fontFamily="Ubuntu">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginTop="40px"
        marginBottom="30px"
        bgcolor="rgba(196, 196, 196, 0.18)"
        padding="5px 15px"
        borderRadius={8}
      >
        <Box
          borderRight={1}
          fontSize="20px"
          fontWeight="800"
          marginRight="10px"
          paddingRight="15px"
        >
          {t("pricing_setup.name")}
        </Box>
        <Box fontFamily="Ubuntu" fontSize="16px" fontWeight="200">
          {t("pricing_setup.name_desc")}
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        marginBottom="60px"
        width="100%"
      >
        <Box marginBottom="40px">
          <Controller
            name="name"
            control={control}
            render={({
              field: { ref, ...fieldProps },
              fieldState: { invalid, error },
            }) => (
              <TextField
                {...fieldProps}
                fullWidth
                variant="outlined"
                required
                label={t("pricing_setup.name_label")}
                size="small"
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Box>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>

      <Box width="100%" display="flex" justifyContent="flex-end">
        <Button
          id="btnModal"
          variant="text"
          color="primary"
          size="medium"
          //TODO: add function to onClick
          onClick={() => {}}
          marginRight="20px"
        >
          {t("buttons.return")}
        </Button>
        <Button
          disabled={!formState.isValid}
          id="btnModal"
          variant="contained"
          color="primary"
          size="large"
          onClick={onNextStep}
        >
          {t("buttons.next")}
        </Button>
      </Box>
    </Box>
  );
};
