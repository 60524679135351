import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";

import { DelayedLoader } from "../components";

export const AsyncDataWrapper = ({
  loadingView = <DelayedLoader />,
  errorView = CenteredError,
  noDataView,
  children,
  result,
}) => {
  if (result.isLoading && !result.data) {
    return loadingView;
  }

  if (result.error) {
    return errorView(result.error);
  }

  if (result.data === undefined && noDataView) {
    return noDataView;
  }

  return (
    <>{result.data !== undefined && children({ data: result.data.data })}</>
  );
};

const CenteredError = () => {
  const { t } = useTranslation();
  return (
    <Box color="error.main" display="flex" justifyContent="center" p={1}>
      {t("error.async_wrapper")}
    </Box>
  );
};
