import { Add } from "@material-ui/icons";
import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

import { useGetParkingRules } from "../../../../../libs/parkings";
import { PATHS } from "../../../../../libs/routes/paths";
import { ParkingRulesTable } from "../../tables/ParkingRulesTable";

export const ParkingRulesList = () => {
  const { parkingId } = useParams();
  const { t } = useTranslation();

  const { data, isSuccess } = useGetParkingRules(parkingId);
  const detailUrl = generatePath(PATHS.parkings.detail, { parkingId });
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          component={Link}
          to={`${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingRules.main}${PATHS.parkings.tabs.parkingRules.create}`}
          startIcon={<Add />}
          variant="text"
          color="primary"
          sx={{
            fontSize: "1rem",
          }}
        >
          {t("parkings.buttons.add_rule")}
        </Button>
      </Box>
      {isSuccess && <ParkingRulesTable data={data?.data ?? []} />}
    </>
  );
};
