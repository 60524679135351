import { Box, TextField } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";

export const PricingDurationContent = ({
  parkingDuration,
  control,
  labelAmount,
  labelCurrency,
}) => {
  const currencies = ["PLN", "CHF", "EUR"];
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      paddingLeft="15px"
    >
      <Box marginBottom="20px" fontSize="18px">
        {parkingDuration}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width="80px" marginRight="20px">
          <Controller
            name="timespan"
            control={control}
            render={({
              field: { ref, ...fieldProps },
              fieldState: { invalid, error },
            }) => (
              <TextField
                {...fieldProps}
                variant="outlined"
                label={labelAmount}
                size="small"
                type="number"
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Box>
        <Box width="80px">
          <Controller
            name="currency"
            control={control}
            render={({
              field: { ref, ...fieldProps },
              fieldState: { invalid, error },
            }) => (
              <TextField
                {...fieldProps}
                select
                label={labelCurrency}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                size="small"
                error={invalid}
                helperText={error?.message}
              >
                {currencies.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
