export const PATHS = {
  base: {
    main: "/dashboard",
    login: "/login",
    passwordReset: "/password-reset",
    welcomePage: "",
    faq: "/FAQ",
    settings: "/settings",
    contact: "/contact",
    parkingHistory: "/parking-history",
    userProfile: "/profile",
    maintainer: "/maintainer",
    discount: "/discount",
  },
  parkings: {
    list: "/parkings",
    create: "",
    detail: "/parkings/:parkingId/view",
    edit: "/parkings/:parkingId/edit",
    tabs: {
      generalInfo: "",
      activities: "/activities",
      parkingSpots: {
        main: "/spots",
        list: "",
        create: "/create",
        edit: "/:spotId/edit",
      },
      parkingRules: {
        main: "/rules",
        list: "",
        create: "/create",
        edit: "/:ruleId/edit",
      },
      parkingSubscriptions: {
        main: "/calendar",
        calendar: "",
        create: "/create",
        edit: "/:subscriptionId/edit",
      },
    },
  },
  parkingSpots: {
    list: "/parking-spots",
    create: "",
    detail: "/parking-spots/:parkingSpotId/view",
    edit: "",
    tabs: {
      generalInfo: "",
      activities: "/activities",
      parkingSpotRules: {
        main: "/rules",
        list: "",
        create: "/create",
        edit: "/:ruleId/edit",
      },
      parkingSpotAccesses: {
        main: "/accesses",
        create: "/create",
      },
    },
  },
  pricings: {
    list: "/pricings",
    create: "",
    detail: "",
    edit: "",
  },
  wallets: {
    list: "",
    create: "",
    detail: "/wallets",
    edit: "",
  },
  groups: {
    list: "/groups",
    create: "/groups/create",
    detail: "/groups/:groupId/view",
    edit: "/groups/:groupId/edit",
  },
  chargers: {
    list: "/chargers",
    detail: "/chargers/:chargerId/view",
    tabs: {
      generalInfo: "",
      chargerRules: {
        main: "/rules",
        list: "",
        create: "/create",
        edit: "/:ruleId/edit",
      },
      activities: "/activities",
    },
  },
};
