import React from "react";
import {
  createTheme,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import * as locales from "@mui/material/locale";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";
import { config } from "../../../../libs/config";

export const ChargerActivitiesTable = ({ data }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const columns = React.useMemo(() => [
    {
      accessorKey: "charge_point_connector_port",
      header: t("chargers.tables.name"),
      Cell: ({ cell }) =>
        `${t("chargers.tables.connector")} ${cell.getValue()}`,
    },
    {
      accessorKey: "license_plate",
      header: t("chargers.tables.license_plate"),
    },

    {
      accessorFn: (row) => new Date(row.start),
      muiTableHeadCellFilterTextFieldProps: {
        type: "datetime",
      },
      sortingFn: "datetime",
      Cell: ({ cell }) =>
        format(Date.parse(cell.getValue()), config.DATE_TIME_FORMAT),
      header: t("chargers.tables.start"),
    },
    {
      accessorFn: (row) => (row.end ? new Date(row.end) : null),
      muiTableHeadCellFilterTextFieldProps: {
        type: "datetime",
      },
      sortingFn: "datetime",
      Cell: ({ cell }) =>
        cell.getValue()
          ? format(Date.parse(cell.getValue()), config.DATE_TIME_FORMAT)
          : "-",
      header: t("chargers.tables.end"),
    },
    {
      accessorKey: "status",
      header: t("chargers.tables.status"),
      Cell: ({ row }) => {
        const status = row.original?.status?.toUpperCase() ?? "FINISHED";
        return (
          <Typography
            variant="body2"
            color={status === "ONGOING" ? "#00CE2D" : "red"}
          >
            {status}
          </Typography>
        );
      },
    },
    {
      accessorKey: "charging_consumed_watts",
      header: t("chargers.tables.energyUsed"),
      Cell: ({ cell }) => `${(cell.getValue() / 1000).toFixed(2)} kW`,
    },
  ]);
  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        columns={columns}
        data={data}
        localization={t("tables", { returnObjects: true })}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableRowActions={false}
        positionActionsColumn="last"
        enableSorting={false}
        enableColumnFilters={false}
        enableGlobalFilter={false}
      />
    </ThemeProvider>
  );
};
