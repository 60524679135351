import { PATHS } from "../../../../libs/routes/paths";

import { ParkingRulesAdd } from "./parking_rules/ParkingRulesAdd";
import { ParkingRulesEdit } from "./parking_rules/ParkingRulesEdit";
import { ParkingRulesList } from "./parking_rules/ParkingRulesList";

export const PARKINGS_RULES = {
  list: {
    path: PATHS.parkings.tabs.parkingRules.list,
    component: ParkingRulesList,
    exact: true,
  },
  create: {
    path: PATHS.parkings.tabs.parkingRules.create,
    component: ParkingRulesAdd,
  },
  edit: {
    path: PATHS.parkings.tabs.parkingRules.edit,
    component: ParkingRulesEdit,
  },
};

export const parkingRulesPaths = [...Object.values(PARKINGS_RULES)];
