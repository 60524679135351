import React from "react";
import MaterialReactTable from "material-react-table";
import { Delete, Edit } from "@material-ui/icons";
import {
  createTheme,
  Grid,
  ListItemIcon,
  MenuItem,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as locales from "@mui/material/locale";

import { ConfirmDeleteRulesDialog } from "../dialogs/ConfirmDeleteRulesDialog";
import { useToggleable } from "../../../../libs/utils";
import { PATHS } from "../../../../libs/routes/paths";
import { getWeekDays } from "../../../parkings/consts";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";

export const ParkingSpotRulesTable = ({ data }) => {
  const { parkingSpotId } = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const [dialogContent, setDialogContent] = React.useState({});

  const [open, { handleOpen, handleClose }] = useToggleable();

  const columns = React.useMemo(() => [
    {
      accessorFn: (row) => row.name,
      header: t("parking_spots.tables.name"),
    },
    {
      accessorFn: (row) =>
        row.groups.length > 0
          ? row.groups.map((item) => item.display_name)?.join(", ")
          : t("parking_spots.tables.all"),
      header: t("parking_spots.tables.groups"),
    },
    {
      accessorFn: (row) => row.price_list.name,
      header: t("parking_spots.tables.price_list"),
    },
  ]);

  const displayTime = (value) => {
    return value ? moment(value, "HH:mm").format("HH:mm") : "";
  };

  const displayAvailabilities = (availabilities) => {
    if (availabilities.length > 0) {
      return availabilities.map((item, i) => {
        return (
          <Grid key={`availability-${i}`} item xs={12}>
            <Typography variant="body1">
              {getWeekDays().find((day) => day.value === item.weekday).label}:{" "}
              {displayTime(item.start)} - {displayTime(item.end)}
            </Typography>
          </Grid>
        );
      });
    }
    return (
      <Typography variant="body1">{t("parking_spots.tables.none")}</Typography>
    );
  };

  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        key={i18n.language}
        localization={t("tables", { returnObjects: true })}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableSorting
        enableRowActions
        positionActionsColumn="last"
        renderDetailPanel={({ row }) => (
          <Grid container>
            {displayAvailabilities(row.original.availabilities)}
          </Grid>
        )}
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={`${row.id}-edit`}
            onClick={() => {
              const parkingSpotUrl = generatePath(PATHS.parkingSpots.detail, {
                parkingSpotId,
              });
              const editUrl = generatePath(
                PATHS.parkingSpots.tabs.parkingSpotRules.edit,
                {
                  ruleId: row.original.id,
                }
              );
              history.push(
                `${PATHS.base.main}${parkingSpotUrl}${PATHS.parkingSpots.tabs.parkingSpotRules.main}${editUrl}`
              );
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            {t("parking_spots.tables.edit")}
          </MenuItem>,
          <MenuItem
            key={`${row.id}-delete`}
            onClick={() => {
              setDialogContent({
                id: row.original.id,
                parkingSpotId,
              });
              handleOpen();
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            {t("parking_spots.tables.delete")}
          </MenuItem>,
        ]}
      />
      {open && (
        <ConfirmDeleteRulesDialog
          key={dialogContent.id}
          open={open}
          handleClose={handleClose}
          content={dialogContent}
        />
      )}
    </ThemeProvider>
  );
};
