import { useCallback, useEffect, useMemo, useState } from "react";

export const useSessionStorage = (key, initialValue = () => "") => {
  return useStorage(window.sessionStorage, key, initialValue);
};

const useStorage = (storage, key, initialValue = () => "") => {
  const [instance, setInstance] = useState();
  const item = useMemo(
    () => getSessionStorageItem(storage, key),
    [storage, key, instance]
  );
  const setValue = useCallback(
    (value) => {
      try {
        storage.setItem(key, JSON.stringify(value));
        setInstance(Math.random());
      } catch (error) {
        // console.log(error);
      }
    },
    [storage, key]
  );

  useEffect(() => {
    const initial = initialValue();
    if (!item && initial) {
      setValue(initial);
    }
  }, []);

  const resetValue = () => {
    storage.removeItem(key);
    setInstance(Math.random());
  };
  return [item, setValue, resetValue];
};

const getSessionStorageItem = (storage, key) => {
  try {
    return JSON.parse(storage.getItem(key));
  } catch (err) {
    return "";
  }
};
