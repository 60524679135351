import { Box, Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export const DashboardTileFAQ = ({
  icon,
  sectionName,
  sectionDescription,
  buttonClick,
  marginValue,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      width="25%"
      borderRadius={18}
      margin={marginValue}
      textAlign="center"
      bgcolor="white"
      style={{
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.25)",
      }}
      padding="25px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Box
          marginBottom="20px"
          display="flex"
          alignItems="center"
          flexDirection="row"
        >
          <Box
            marginRight="10px"
            marginBottom="0"
            fontSize="34px"
            color="primary.main"
          >
            {icon}
          </Box>
          <Box fontSize="20px" fontWeight="800" color="primary.main">
            {sectionName}
          </Box>
        </Box>
        <Box
          textAlign="left"
          fontSize="16px"
          fontWeight="600"
          marginBottom="10px"
          color="#6A6A6A"
          lineHeight="1.5rem"
          paddingBottom="10px"
          borderBottom={1}
          borderColor="primary"
        >
          {sectionDescription}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="flex-start"
        marginTop="40px"
        width="50%"
      >
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          fullWidth
          onClick={buttonClick}
        >
          {t("faq.take_me_there")}
        </Button>
      </Box>
    </Box>
  );
};
