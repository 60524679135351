import { Box, TextField } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";

export const SubTypeContent = ({ parkingDuration, control, label }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      paddingLeft="15px"
    >
      <Box fontSize="18px">{parkingDuration}</Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box width="80px">
            <Controller
              name="timespan"
              control={control}
              render={({
                field: { ref, ...fieldProps },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  {...fieldProps}
                  variant="outlined"
                  label={label}
                  size="small"
                  type="number"
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
