import { Box, FormControl, MenuItem, Select } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React from "react";
import { useTranslation } from "react-i18next";
import TranslateIcon from "@mui/icons-material/Translate";
import LanguageDetector from "i18next-browser-languagedetector";

import { useLogout } from "../../libs/auth";

import { TitlePageTopBarPartnerCredentials } from "./TitlePageTopBarPartnerCredentials";

export const TitlePageTopBarBusiness = ({ label, result, returnButton }) => {
  const { i18n } = useTranslation();
  const logout = useLogout();
  const [language, setLanguage] = React.useState(
    i18n.use(LanguageDetector).language
  );

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      marginBottom="30px"
      bgcolor="white"
      padding="10px 20px"
      borderRadius={8}
      style={{
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        {returnButton}

        <Box fontFamily="Ubuntu" fontSize="24px" fontWeight="600">
          {label}
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box marginRight="10px">
            <TranslateIcon />
          </Box>
          <Box marginRight="20px" color="primary">
            <FormControl size="small">
              <Select
                variant="outlined"
                value={language}
                // label="Language"
                onChange={handleChange}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="pl-PL">PL</MenuItem>
                <MenuItem value="en">EN</MenuItem>
                <MenuItem value="de">DE</MenuItem>
                <MenuItem value="fr">FR</MenuItem>
                <MenuItem value="ua">UA</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <IconButton aria-label="logout" onClick={logout}>
          <ExitToAppIcon />
        </IconButton>
        <Box display="flex" alignItems="center">
          <TitlePageTopBarPartnerCredentials
            partnerCompany={result?.company_name}
            partnerParkingName={result?.maintained_parkings.name}
            partnerLogo={result?.profile?.avatar}
          />
        </Box>
      </Box>
    </Box>
  );
};
