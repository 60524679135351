import { Box, Grid } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";

import { config } from "../../libs/config";

export const ParkingReservationsItem = ({
  licensePlate,
  parkingSpot,
  start,
  end,
  totalPrice,
  currency,
}) => {
  return (
    <Box
      bgcolor="rgba(196, 196, 196, 0.18)"
      padding="20px 0px 20px 15px"
      marginBottom="5px"
      borderRadius={8}
    >
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={2}>
          <Box fontSize="16px" color="#252733" fontWeight="800">
            <Box>{licensePlate}</Box>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box fontSize="16px" fontWeight="400" color="black">
            {parkingSpot}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box fontSize="16px" fontWeight="400" color="black">
            {format(Date.parse(start), config.DATE_TIME_FORMAT)}
          </Box>
        </Grid>
        <Grid item xs={3}>
          {end == null ? (
            "---"
          ) : (
            <Box fontSize="16px" fontWeight="400" color="black">
              {format(Date.parse(end), config.DATE_TIME_FORMAT)}
            </Box>
          )}
        </Grid>
        <Grid item xs={2}>
          {!!totalPrice && (
            <Box fontSize="16px" color="black">
              {totalPrice}&nbsp;
              {currency}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
