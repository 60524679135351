import React from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  Typography,
  Hidden,
  Divider,
  IconButton,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Edit } from "@material-ui/icons";

import { useCharger, useUpdateChargerName } from "../../../../libs/chargers";
import GenericTextField from "../../../../components/forms/GenericTextField";
import { ConnectorsListTable } from "../tables/ConnectorsListTable";

const GeneralInfoItem = ({ label, text, mb = true }) => {
  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: 700,
          color: "#343434",
          ...(mb ? { marginBottom: "0.5rem" } : {}),
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          fontSize: "1rem",
          color: "rgba(0, 0, 0, 0.6)",
        }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export const GeneralInfo = ({ setTab, tabValue }) => {
  const { chargerId } = useParams();
  const { data, isSuccess } = useCharger(chargerId);
  const changeName = useUpdateChargerName(chargerId);
  const [editChargerName, setEditChargerName] = React.useState(false);
  const [chargerName, setChargerName] = React.useState("");
  const { t } = useTranslation();
  const methods = useForm({
    criteriaMode: "onSubmit",
    mode: "onSubmit",
  });

  React.useEffect(() => {
    setTab(tabValue);
  }, []);

  const onSubmit = methods.handleSubmit((payload) => {
    changeName(payload);
    setChargerName(payload.name);
    setEditChargerName(false);
  });

  React.useEffect(() => {
    if (isSuccess) {
      setChargerName(data?.data?.name);
    }
  }, [isSuccess]);

  return (
    <Grid container spacing={0} columnSpacing={3} rowSpacing={2}>
      <Grid
        item
        container
        md={12}
        lg={4}
        xl={3}
        sx={{ alignItems: "baseline" }}
        rowGap={4}
        columnSpacing={3}
      >
        <Grid item xs={12}>
          {editChargerName ? (
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "flex-end",
                  }}
                >
                  <GenericTextField
                    name="name"
                    label={`1. ${t("chargers.fields.name.label")}`}
                    placeholder={t("chargers.fields.name.placeholder")}
                    id="charger-name"
                    defaultValue={chargerName}
                  />
                  <Box>
                    <Button variant="contained" type="submit" color="primary">
                      {t("chargers.buttons.save")}
                    </Button>
                  </Box>
                </Box>
              </form>
            </FormProvider>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  color: "#343434",
                  marginBottom: "0.375rem",
                }}
              >
                1. {t("chargers.fields.name.label")}
              </Typography>
              <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {chargerName}
                </Typography>
                <IconButton
                  color="default"
                  onClick={() => setEditChargerName(true)}
                >
                  <Edit />
                </IconButton>
              </Box>
            </>
          )}
        </Grid>
        <GeneralInfoItem
          label={`2. ${t("chargers.tabs.general.parking_name.title")}`}
          text={data?.data?.parking_name}
        />

        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
              color: "#343434",
              marginBottom: "0.5rem",
            }}
          >
            3. {t("chargers.tabs.general.parking_spots.title")}
          </Typography>

          {data?.data?.parking_spots.map((item, index) => (
            <Typography
              key={index}
              sx={{
                fontSize: "1rem",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              {item.parking_spot_number}
              {data?.data?.parking_spots.length < index && ","}
            </Typography>
          ))}
        </Grid>
        <GeneralInfoItem
          label={`4. ${t("chargers.tabs.general.manufacturer.title")}`}
          text={data?.data?.manufacturer}
        />
        <GeneralInfoItem
          label={`5. ${t("chargers.tabs.general.serial_number.title")}`}
          text={data?.data?.serial_number}
        />
        <GeneralInfoItem
          label={`6. ${t("chargers.tabs.general.status.title")}`}
          text={data?.data?.status}
        />
      </Grid>
      <Divider orientation="vertical" flexItem />

      <Hidden lgUp>
        <Grid item xs={12}>
          <Divider orientation="horizontal" />
        </Grid>
      </Hidden>
      <Grid container item md={12} lg={7} xl={8}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
              color: "#343434",
              marginBottom: "0.5rem",
            }}
          >
            7. {t("chargers.tabs.general.connectors.title")}
          </Typography>
          <ConnectorsListTable data={data?.data?.connectors ?? []} />
        </Grid>
      </Grid>
    </Grid>
  );
};
