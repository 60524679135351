import i18n from "../../i18n";

export const groupPermissions = {
  viewGroup: {
    permission: "view_userparkinggroup",
  },
  editGroup: {
    permission: "change_userparkinggroup",
  },
  deleteGroup: {
    permission: "delete_userparkinggroup",
  },
  addUserToGroup: {
    permission: "add_user_userparkinggroup",
  },
  setPermissionsToUser: {
    permission: "permission_assign_userparkinggroup",
  },
  deleteUserFromGroup: {
    permission: "remove_user_userparkinggroup",
  },
};

export const groupPermissionsCheckboxesList = [
  {
    label: i18n.t("groups.checkboxes.view_userparkinggroup"),
    value: groupPermissions.viewGroup.permission,
    default: true,
  },
  {
    label: i18n.t("groups.checkboxes.change_userparkinggroup"),
    value: groupPermissions.editGroup.permission,
    default: false,
  },
  {
    label: i18n.t("groups.checkboxes.delete_userparkinggroup"),
    value: groupPermissions.deleteGroup.permission,
    default: false,
    action: "can_delete_group",
  },
  {
    label: i18n.t("groups.checkboxes.add_user_userparkinggroup"),
    value: groupPermissions.addUserToGroup.permission,
    default: false,
    action: "can_add_user_to_group",
  },
  {
    label: i18n.t("groups.checkboxes.permission_assign_userparkinggroup"),
    value: groupPermissions.setPermissionsToUser.permission,
    default: false,
  },
  {
    label: i18n.t("groups.checkboxes.remove_user_userparkinggroup"),
    value: groupPermissions.deleteUserFromGroup.permission,
    default: false,
  },
];
