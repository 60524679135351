import { Box, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import "../ButtonsMain.css";

export const NavigationLink = ({ to, icon, label }) => {
  const tabletActive = useMediaQuery("(max-width: 1200px)");

  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Box
        color="#D6ECFF"
        fontSize="16px"
        fontWeight="400"
        display="flex"
        alignItems="center"
        padding={(tabletActive && "14px 4px 14px 4px") || "14px 24px 14px 31px"}
        className="side-bar-menu-btn"
        borderRadius={8}
      >
        <span height="24px" style={{ marginRight: "10px" }}>
          {icon}
        </span>
        {label}
      </Box>
    </Link>
  );
};
