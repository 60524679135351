import { PATHS } from "../../../../libs/routes/paths";

import { Activities } from "./activities/Activities";
import { GeneralInfo } from "./general_info/GeneralInfo";
import { ParkingRules } from "./parking_rules/ParkingRules";
import { ParkingSpots } from "./parking_spots/ParkingSpots";
import { ParkingSubscriptions } from "./calendar/ParkingSubscriptions";

export const PARKING_TABS = {
  generalInfo: {
    path: PATHS.parkings.tabs.generalInfo,
    component: GeneralInfo,
    tabValue: 0,
    exact: true,
  },
  parkingSpots: {
    path: PATHS.parkings.tabs.parkingSpots.main,
    component: ParkingSpots,
    tabValue: 1,
  },
  parkingRules: {
    path: PATHS.parkings.tabs.parkingRules.main,
    component: ParkingRules,
    tabValue: 2,
  },
  activities: {
    path: PATHS.parkings.tabs.activities,
    component: Activities,
    tabValue: 3,
  },
  calendar: {
    path: PATHS.parkings.tabs.parkingSubscriptions.main,
    component: ParkingSubscriptions,
    tabValue: 4,
  },
};

export const parkingTabsPaths = [...Object.values(PARKING_TABS)];
