import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SharepLogo } from "../../svg/logo_home.svg";
import { PATHS } from "../../libs/routes/paths";

export const MainPageLogo = () => {
  const { t } = useTranslation();
  return (
    <Link
      to={`${PATHS.base.main}${PATHS.base.welcomePage}`}
      style={{ textDecoration: "none" }}
    >
      <Box>
        <SharepLogo />

        <Box
          fontFamily="Ubuntu"
          fontSize="19px"
          fontWeight="800"
          color="primary.light"
        >
          {t("user_panel")}
        </Box>
      </Box>
    </Link>
  );
};
