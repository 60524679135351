import React from "react";
import MaterialReactTable from "material-react-table";
import { Delete } from "@material-ui/icons";
import {
  createTheme,
  ListItemIcon,
  MenuItem,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";

import { useToggleable } from "../../../../libs/utils";
import { ConfirmDeleteAccessesDialog } from "../dialogs/ConfirmDeleteAccessesDialog";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";

export const ParkingSpotAccessesTable = ({ data }) => {
  const { t, i18n } = useTranslation();
  const { parkingSpotId } = useParams();
  const theme = useTheme();

  const [dialogContent, setDialogContent] = React.useState({});

  const [open, { handleOpen, handleClose }] = useToggleable();

  const columns = React.useMemo(() => [
    {
      accessorFn: (row) =>
        row.is_group
          ? `${t("parking_spots.fields.type.groupLabel")}`
          : `${t("parking_spots.fields.type.userLabel")}`,
      header: t("parking_spots.tables.type"),
    },
    {
      accessorKey: "group_name",
      header: t("parking_spots.tables.groupName"),
    },
    {
      accessorFn: (row) =>
        !row.is_group ? `${row.first_name} ${row.last_name}` : "",
      header: t("parking_spots.tables.full_name"),
    },
    {
      accessorKey: "phone_number",
      header: t("parking_spots.tables.phone_number"),
    },
    {
      accessorKey: "number_of_users",
      header: t("parking_spots.tables.numberOfUsers"),
    },
  ]);

  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        key={i18n.language}
        columns={columns}
        data={data}
        localization={t("tables", { returnObjects: true })}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableSorting
        enableRowActions
        positionActionsColumn="last"
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={`${row.id}-delete`}
            onClick={() => {
              setDialogContent({
                id: row.original.id,
                name: row.original.is_group
                  ? `${row.original.group_name}`
                  : `${row.original.first_name} ${row.original.last_name}`,
                phoneNumber: row.original.phone_number,
                is_group: row.original.is_group,
                parkingSpotId,
              });
              handleOpen();
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            {t("parkings.tables.delete")}
          </MenuItem>,
        ]}
      />
      {open && (
        <ConfirmDeleteAccessesDialog
          key={dialogContent.id}
          open={open}
          handleClose={handleClose}
          content={dialogContent}
        />
      )}
    </ThemeProvider>
  );
};
