import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";

export const NavigationLinkUserProfile = ({ to, userPhoto }) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Box>
        <Box
          width={48}
          height={48}
          borderRadius="50%"
          overflow="hidden"
          marginLeft="20px"
        >
          <img
            width="100%"
            height="100%"
            src={userPhoto}
            alt="avatar"
            style={{
              // width: "100%",
              // maxHeight: "200px",
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
    </Link>
  );
};
