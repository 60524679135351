import { useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Box,
  Button,
  Card,
  Container,
  CardContent,
} from "@mui/material";
import { Link } from "react-router-dom";

import { ReactComponent as SharepLogo } from "../../../svg/logo_home.svg";
import businessClient from "../../../svg/business.svg";
import { loginType } from "../consts";

import { ResetPasswordRequest } from "./components/ResetPasswordRequest";
import { ResetPasswordVerify } from "./components/ResetPasswordVerify";
import { ResetPassword } from "./components/ResetPassword";

const passwordResetType = Object.freeze({
  request: "REQUEST",
  verify: "VERIFY",
  reset: "RESET",
});

const AuthCardImage = ({ resetStep }) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ boxShadow: "none" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0.625rem 2.25rem",
          maxWidth: 310,
        }}
      >
        <Typography
          sx={{
            marginBottom: "1.25rem",
            textAlign: "center",
            fontSize: "1.25rem",
            color: "primary.main",
            fontFamily: "Ubuntu",
            maxWidth: 200,
            fontWeight: "600",
          }}
        >
          {t("business_client.login_title")}
        </Typography>

        <Box
          component="img"
          sx={{
            height: 200,
            mb: `${
              resetStep !== passwordResetType.reset ? "1.25rem" : "8.75rem"
            }`,
          }}
          src={businessClient}
        />
        <Link
          to={{ pathname: "/login", state: { type: loginType.business } }}
          style={{ textDecoration: "none" }}
        >
          <Button variant="outlined" fullWidth color="primary">
            {t("buttons.click_here")}
          </Button>
        </Link>
      </CardContent>
    </Card>
  );
};

export const PasswordResetPage = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [step, setStep] = useState(passwordResetType.request);

  return (
    <>
      <Box
        sx={{
          bgcolor: "primary.main",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "1.875rem 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "center",
            alignItems: "center",
            marginBottom: `${isMobile ? "0.625rem" : "0"}`,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginRight: "1.25rem",
              fontFamily: "Ubuntu",
              color: "white",
              fontWeight: "700",
            }}
          >
            {t("login_greeting_title")}
          </Typography>
          <SharepLogo />
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Ubuntu",
            color: "white",
          }}
        >
          {t("login_greeting_subtitle")}
        </Typography>
      </Box>
      <Container
        component="main"
        maxWidth={false}
        sx={{
          maxWidth: "fit-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: `${(isMobile && "10vh") || "25vh"}`,
            boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.25)",
            borderRadius: 2,
          }}
        >
          {step === passwordResetType.request && (
            <ResetPasswordRequest
              setStep={setStep}
              setPhoneNumber={setPhoneNumber}
            />
          )}
          {step === passwordResetType.verify && (
            <ResetPasswordVerify
              setStep={setStep}
              setResetToken={setResetToken}
              phoneNumber={phoneNumber}
            />
          )}
          {step === passwordResetType.reset && (
            <ResetPassword
              setStep={setStep}
              phoneNumber={phoneNumber}
              resetToken={resetToken}
            />
          )}
          {!isMobile && <AuthCardImage resetStep={step} />}
        </Box>
      </Container>
    </>
  );
};
