import { useMutation, useQuery, useQueryClient } from "react-query";

import { useApi } from "../../../libs/api";
import { config } from "../../../libs/config";

export const useVehicles = () => {
  const { get } = useApi();

  return useQuery("vehicles", get(config.endpoints.VEHICLES));
};

export const useAddVehicle = () => {
  const queryClient = useQueryClient();
  const { post } = useApi();
  const { mutate } = useMutation(post(config.endpoints.VEHICLES), {
    onSuccess: () => {
      queryClient.refetchQueries("vehicles");
    },
  });
  return mutate;
};

export const useGetVehicles = () => {
  const { get } = useApi();

  return useQuery(
    "vehicles_manu",
    get(config.endpoints.VEHICLES_MANUFACTURERS)
  );
};

export const useGetModels = (manufacturer) => {
  const { get } = useApi();
  return useQuery(
    ["vehicles_models", manufacturer],
    get(`${config.endpoints.VEHICLES_MODELS}${manufacturer}/`),
    {
      enabled: !!manufacturer,
    }
  );
};

export const useRemoveVehicle = (vehicleId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(`${config.endpoints.VEHICLES}${vehicleId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries("vehicles");
      },
    }
  );
  return mutate;
};
