import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { TitlePageTopBar } from "../../components/commons/TitlePageTopBar";

export const Settings = () => {
  const { t } = useTranslation();
  return (
    <Box
      fontFamily="Ubuntu"
      fontSize="24px"
      fontWeight="600"
      className="top-title-bar"
    >
      <TitlePageTopBar label={t("settings.title")} />
      {/* TODO: empty box */}
      <Box
        borderRadius={12}
        bgcolor="white"
        style={{
          boxShadow:
            "0px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 0px 5px rgba(0, 0, 0, 0.25)",
        }}
        padding="25px 25px 25px 25px"
      />
    </Box>
  );
};
