import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Box,
  Button,
  Card,
  Container,
  CardContent,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { ReactComponent as SharepLogo } from "../../../svg/logo_home.svg";
import individualClient from "../../../svg/individual.svg";
import businessClient from "../../../svg/business.svg";
import { loginType } from "../consts";

import { LoginWindowBusiness } from "./components/LoginWindowBusiness";
import { LoginWindow } from "./components/LoginWindow";

const LoginCardImage = ({ type, setActive }) => {
  const { t } = useTranslation();

  const handleChange = () => {
    setActive(
      type === loginType.regular ? loginType.business : loginType.regular
    );
  };
  return (
    <Card sx={{ boxShadow: "none" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0.625rem 2.25rem",
          maxWidth: 310,
        }}
      >
        <Typography
          sx={{
            marginBottom: "3.25rem",
            textAlign: "center",
            fontSize: "1.25rem",
            color: "primary.main",
            fontFamily: "Ubuntu",
            maxWidth: 200,
            fontWeight: "600",
          }}
        >
          {type === loginType.regular
            ? t("business_client.login_title")
            : t("individual_client.login_title")}
        </Typography>

        <Box
          component="img"
          sx={{ height: 200, mb: "1.25rem" }}
          src={type === loginType.regular ? businessClient : individualClient}
        />

        <Button
          variant="outlined"
          fullWidth
          color="primary"
          onClick={handleChange}
        >
          {t("buttons.click_here")}
        </Button>
      </CardContent>
    </Card>
  );
};

export const LoginPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [active, setActive] = useState(loginType.regular);

  useEffect(() => {
    if (isMobile) {
      setActive(loginType.business);
    } else {
      setActive(loginType.regular);
    }
  }, [isMobile]);

  useEffect(() => {
    const type = location?.state?.type ?? loginType.regular;
    if (type && type !== active) {
      setActive(type);
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: "primary.main",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "1.875rem 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "center",
            alignItems: "center",
            marginBottom: `${isMobile ? "0.625rem" : "0"}`,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginRight: "1.25rem",
              fontFamily: "Ubuntu",
              color: "white",
              fontWeight: "700",
            }}
          >
            {t("login_greeting_title")}
          </Typography>
          <SharepLogo />
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Ubuntu",
            color: "white",
          }}
        >
          {t("login_greeting_subtitle")}
        </Typography>
      </Box>
      <Container
        component="main"
        maxWidth={false}
        sx={{
          maxWidth: "fit-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: `${(isMobile && "10vh") || "25vh"}`,
            boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.25)",
            borderRadius: 2,
          }}
        >
          {active === loginType.regular && <LoginWindow />}
          {active === loginType.business && <LoginWindowBusiness />}
          {!isMobile && <LoginCardImage type={active} setActive={setActive} />}
        </Box>
      </Container>
    </>
  );
};
