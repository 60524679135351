import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { DateTimePicker } from "@mui/x-date-pickers";
import { format, isAfter } from "date-fns";

import i18n from "../../../../i18n";
import { useCreateParkingSpotReservation } from "../../../../libs/parking-spots";

const validationSchema = () =>
  yup
    .object()
    .required()
    .shape({
      phone_number: yup
        .string()
        .required(i18n.t("parking_spots.fields.phone_number.required"))
        .phone("", false, i18n.t("parking_spots.fields.phone_number.invalid")),
      start: yup
        .string()
        .required(i18n.t("parking_spots.fields.datetime_range.start.required")),
      end: yup
        .string()
        .required(i18n.t("parking_spots.fields.datetime_range.end.required"))
        .test(
          "end-less-then-start",
          i18n.t("parking_spots.fields.datetime_range.end.less_then_start"),
          (value, context) => {
            const { start } = context.parent;
            return isAfter(new Date(value), new Date(start));
          }
        ),
    });
export const ShareFormDialog = ({ open, handleClose, content }) => {
  const localeValidationSchema = React.useMemo(
    () => validationSchema(),
    [i18n.language]
  );
  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    resolver: yupResolver(localeValidationSchema),
  });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const addReservation = useCreateParkingSpotReservation(
    content.id,
    handleClose
  );

  const { name } = content;

  const onSubmit = methods.handleSubmit(async (payload) => {
    setIsLoading(true);
    const data = {};
    data.start = format(new Date(payload.start), "yyyy-MM-dd'T'HH:mm");
    data.end = format(new Date(payload.end), "yyyy-MM-dd'T'HH:mm");
    data.phone_number = payload.phone_number;
    await addReservation(data);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  });
  return (
    <Grid container spacing={3} maxWidth="10rem">
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Trans i18nKey="parking_spots.dialogs.share_spot_title" name={name}>
            Udostępnij miejsce {{ name }}
          </Trans>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            item
            xs={12}
            columnGap="1rem"
            rowGap="2rem"
            mb="1.5rem"
            pt="1rem"
          >
            <Controller
              name="phone_number"
              control={methods.control}
              defaultValue=""
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <Box sx={{ width: "12rem" }}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      color: "#343434",
                      marginBottom: "1.5rem",
                    }}
                  >
                    1. {t("parking_spots.fields.phone_number.label")}
                  </Typography>
                  <Input
                    {...fieldProps}
                    inputRef={ref}
                    fullWidth
                    placeholder={t(
                      "parking_spots.fields.phone_number.placeholder"
                    )}
                    error={!!error}
                  />
                  {error?.message && (
                    <FormHelperText error>{error?.message}</FormHelperText>
                  )}
                </Box>
              )}
            />
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  color: "#343434",
                }}
              >
                2. {t("parking_spots.fields.datetime_range.label")}
              </Typography>
            </Grid>
            <Box sx={{ display: "inline-flex" }}>
              <Box sx={{ marginRight: "1rem" }}>
                <Controller
                  name="start"
                  control={methods.control}
                  defaultValue={moment().format("YYYY-MM-DDTHH:mm")}
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <>
                      <DateTimePicker
                        {...fieldProps}
                        inputRef={ref}
                        ampm={false}
                        label={t(
                          "parking_spots.fields.datetime_range.start.label"
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        renderInput={(props) => (
                          <TextField {...props} size="small" />
                        )}
                      />
                      {error?.message && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="end"
                  control={methods.control}
                  defaultValue={moment()
                    .add(1, "hour")
                    .format("YYYY-MM-DDTHH:mm")}
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error },
                  }) => (
                    <>
                      <DateTimePicker
                        {...fieldProps}
                        inputRef={ref}
                        ampm={false}
                        label={t(
                          "parking_spots.fields.datetime_range.end.label"
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        renderInput={(props) => (
                          <TextField {...props} size="small" />
                        )}
                      />
                      {error?.message && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t("parking_spots.buttons.cancel")}
          </Button>
          <LoadingButton
            onClick={() => methods.handleSubmit(onSubmit)()}
            loading={isLoading}
            loadingPosition="start"
          >
            {t("parking_spots.buttons.share")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
