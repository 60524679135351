import {
  Box,
  Button,
  createTheme,
  IconButton,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Add, Delete } from "@material-ui/icons";
import MaterialReactTable from "material-react-table";
import * as locales from "@mui/material/locale";

import { useToggleable } from "../../../libs/utils";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../libs/utils/consts";

import { useLoyaltyCards } from "./data";
import { AddLoyaltyCardDialog } from "./AddLoyaltyCardDialog";
import { ConfirmDeleteLCDialog } from "./ConfirmDeleteLCDialog";

export const YourCards = () => {
  const { isFetched, data } = useLoyaltyCards();
  const { t, i18n } = useTranslation();
  const [dialogContent, setDialogContent] = React.useState({});
  const theme = useTheme();

  const [open, { handleOpen, handleClose }] = useToggleable();
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "number",
        header: t("loyalty_cards.cards_number"),
        enableColumnFilter: false,
      },
      {
        accessorKey: "partner",
        header: t("loyalty_cards.cards_partner"),
        enableColumnFilter: false,
      },
      {
        accessorKey: "vehicle.license_plate",
        header: t("loyalty_cards.cards_vehicle"),
        enableColumnFilter: false,
      },
      {
        id: "actions",
        header: t("tables.actions"),
        columnDefType: "display",
        enableColumnOrdering: false,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <>
            <IconButton
              key={`${row.id}-delete`}
              onClick={() => {
                setDialogContent({
                  id: row.original.id,
                  number: row.original.number,
                  licensePlate: row.original.vehicle.license_plate,
                });
                handleOpen();
              }}
              sx={{ m: 0 }}
            >
              <Delete />
            </IconButton>
          </>
        ),
      },
    ],
    []
  );
  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <AddLoyaltyCardDialog>
          {(handleOpen) => (
            <Button
              startIcon={<Add />}
              onClick={handleOpen}
              variant="text"
              color="primary"
              sx={{
                fontSize: "1rem",
              }}
            >
              {t("loyalty_cards.cards_add_new")}
            </Button>
          )}
        </AddLoyaltyCardDialog>
      </Box>
      {isFetched && (
        <MaterialReactTable
          columns={columns}
          localization={t("tables", { returnObjects: true })}
          data={data.data}
          enableColumnActions={false}
          enableColumnDragging={false}
          enableHiding={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableRowActions={false}
          enableSorting={false}
          enableColumnFilters={false}
          enableGlobalFilter={false}
        />
      )}
      {open && (
        <ConfirmDeleteLCDialog
          key={dialogContent.id}
          open={open}
          handleClose={handleClose}
          content={dialogContent}
        />
      )}
    </ThemeProvider>
  );
};
