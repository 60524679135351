import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { useAddPricingPeriod } from "../../../../libs/price-lists";
import { usePricingState } from "../AddPricingContext";

import { PricingTypeContent } from "./PricingTypeContent";

const currencies = ["PLN", "CHF", "EUR"];

const initialValues = {
  minute_based_billing: false,
  price_per: 60,
  price: "",
  pricing_band: 1,
};

const useEditedDuration = () => {
  return usePricingState(
    ({ durations, editedDurationId }) =>
      (durations || []).find(({ id }) => id === editedDurationId) ||
      initialValues
  );
};

const validationSchema = yup
  .object()
  .required()
  .shape({
    minute_based_billing: yup.boolean(),
    price: yup
      .number()
      .min(0, "Cena nie może być ujemna")
      .typeError("Cena musi mieć wartość numeryczną")
      .required("Cena jest wymagana"),
    duration: yup.number().optional(),
  });

export const BasicDurationStep = ({ onClose }) => {
  const pricingListId = usePricingState(({ id }) => id);

  const addPeriod = useAddPricingPeriod(pricingListId);
  const defaultValues = useEditedDuration();
  const { handleSubmit, control, formState } = useForm({
    defaultValues,
    criteriaMode: "all",
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onNextStep = handleSubmit((payload) => {
    const periods = [];
    const data = {}
    const { secondPeriodPrice, secondPeriodTime, price, currency } = payload;
    periods.push({ ...defaultValues });
    periods[0].price = price;
    if (secondPeriodTime) {
      periods.push({ ...defaultValues });
      periods[0].duration = secondPeriodTime;
      periods[0].time_unit = 60;
      periods[1].price = secondPeriodPrice;
    }
    data.periods = periods;
    data.currency = currency;
    addPeriod(data);
    onClose();
  });

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { t } = useTranslation();

  return (
    <form noValidate onSubmit={onNextStep}>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginTop="40px"
          marginBottom="30px"
          bgcolor="rgba(196, 196, 196, 0.18)"
          padding="8px 15px"
          borderRadius={8}
        >
          <Box
            borderRight={1}
            fontFamily="Ubuntu"
            fontSize="20px"
            fontWeight="800"
            marginRight="10px"
            paddingRight="15px"
          >
            {t("pricing_setup.tarif_1")}
          </Box>
          <Box fontFamily="Ubuntu" fontSize="16px" fontWeight="200">
            {t("pricing_setup.tarif_1_desc")}
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box marginBottom="20px" fontSize="18px" paddingLeft="15px">
              {t("pricing_setup.tarif_1_price")}
            </Box>
            <Box display="flex" flexDirection="row">
              <Box marginRight="20px" width="80px">
                <Controller
                  name="price"
                  control={control}
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { invalid, error },
                  }) => (
                    <TextField
                      {...fieldProps}
                      variant="outlined"
                      label={t("pricing_setup.price")}
                      size="small"
                      type="number"
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="currency"
                  control={control}
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { invalid, error },
                  }) => (
                    <TextField
                      {...fieldProps}
                      select
                      label={t("pricing_setup.currency")}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                      size="small"
                      error={invalid}
                      helperText={error?.message}
                    >
                      {currencies.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box padding="30px 15px 20px 15px" fontSize="13px">
            {t("pricing_setup.tarif_2_pre")}
          </Box>
        </Box>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          style={{
            borderRadius: "12px",
            boxShadow: "none",
            marginBottom: "20px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{
              borderRadius: "12px",
              backgroundColor: "rgba(196, 196, 196, 0.18)",
              marginBottom: "20px",
            }}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box
                borderRight={1}
                fontFamily="Ubuntu"
                fontSize="20px"
                fontWeight="800"
                marginRight="10px"
                paddingRight="15px"
              >
                {t("pricing_setup.tarif_2")}
              </Box>
              <Box fontFamily="Ubuntu" fontSize="16px" fontWeight="200">
                {t("pricing_setup.tarif_2_desc")}
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" width="100%">
              <PricingTypeContent
                parkingDuration={t("pricing_setup.tarif_2_hours")}
                control={control}
                label="Godziny"
                name="secondPeriodTime"
              />
              <PricingTypeContent
                parkingDuration={t("pricing_setup.tarif_2_price")}
                control={control}
                label={t("pricing_setup.price")}
                name="secondPeriodPrice"
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button
            disabled={!formState.isValid}
            id="btnModal"
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            {t("buttons.save_2")}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
