import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SharepLogo } from "../../svg/logo_home.svg";

export const MainBusinessLogo = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <SharepLogo />

      <Box
        fontFamily="Ubuntu"
        fontSize="19px"
        fontWeight="800"
        color="primary.light"
      >
        {t("user_panel")}
      </Box>
    </Box>
  );
};
