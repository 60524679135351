import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { useRemoveVehicle } from "./data";

export const ConfirmDeleteVehicleDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();
  const { licensePlate } = content;
  const removeVehicle = useRemoveVehicle(content.id);

  const handleDelete = () => {
    removeVehicle();
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Usuwanie pojazdu</DialogTitle>
        <DialogContent>
          <Typography>
            <Trans
              i18nKey="vehicles.dialog_delete_vehicle_text"
              licensePlate={content.licensePlate}
            >
              Czy na pewno chcesz usunąć pojazd o numerze rejestracyjnym{" "}
              {{ licensePlate }}?
            </Trans>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("groups.buttons.cancel")}</Button>
          <Button onClick={handleDelete}>{t("groups.buttons.delete")}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
