import { Box, IconButton, DialogContent, Dialog } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

import { useToggleable } from "../../../libs/utils";

import { AddVehicleForm } from "./AddVehicleForm";

export const AddVehicleDialog = ({ children }) => {
  const [open, { handleOpen, handleClose }] = useToggleable();
  return (
    <>
      {children(handleOpen)}
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <AddVehicleDialogContent handleClose={handleClose} />
      </Dialog>
    </>
  );
};

const AddVehicleDialogContent = React.memo(({ handleClose }) => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Box width="760px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            fontFamily="Ubuntu"
            fontSize="20px"
            fontWeight="500"
            color="primary.main"
          >
            {t("vehicles.vehicles_title_form")}
          </Box>
          <Box>
            <IconButton
              aria-label="delete"
              size="medium"
              color="primary"
              onClick={handleClose}
            >
              <Clear />
            </IconButton>
          </Box>
        </Box>
        <AddVehicleForm handleClose={handleClose} />
      </Box>
    </DialogContent>
  );
});
