export const config = {
  API_URL: process.env.REACT_APP_BASIC_URL,
  endpoints: {
    REFRESH_SESSION: "auth/refresh/",
    LOGIN: "auth/login/",
    LOGIN_BUSINESS: "auth/partners/login/",
    PASSWORD_RESET_REQUEST: "auth/password-reset-request/",
    PASSWORD_RESET_VERIFY: "auth/password-reset-verify/",
    PASSWORD_RESET: "auth/password-reset/",
    ENTRY_DEVICES: "gates/parkings_devices/",
    SPOTS_ENTRY_DEVICES: "gates/parking_spots_devices/",
    GROUPS_BASE: "deprecated/admin-panel/groups/",

    PARTNER_RESERVATIONS: "deprecated/admin-panel/maintenance/parkings/",
    PARKING_ENTITLEMENTS: "deprecated/admin-panel/maintenance/parkings/",

    PARKINGS: "deprecated/admin-panel/maintenance/parkings/",
    PARKING_SPOTS: "parking_spots/",
    PROMOTION_PRICE: "partners/promotion_price_lists/",
    USER_PARKINGS: "deprecated/admin-panel/user-parkings/",
    TRANSACTIONS: "deprecated/admin-panel/user-wallet-transactions/",
    USER: "auth/user-details/",
    PARTNER: "auth/partner-details/",
    AVATAR: "auth/user-details/avatar/",
    VEHICLES: "auth/user-details/vehicles/",
    PRICINGS: "auth/user-details/price_lists/",
    EV_PRICING: "auth/user-details/ev-price-lists/",
    USERS_SPOTS: "deprecated/admin-panel/user-parking-spots/",
    PRICING_ITEM: "price_lists/",
    PRICINGS_SUBSCRIPTION: "auth/user-details/parking-subscriptions/",
    RESERVATIONS: "reservations/",
    LOYALTY_CARDS: "loyalty_cards/",
    VEHICLES_MANUFACTURERS: "vehicles/manufacturers/",
    VEHICLES_MODELS: "vehicles/models/",
    CHARGERS_BASE: "deprecated/admin-panel/chargers/",
    PARKING_SPOTS_BASE: "deprecated/admin-panel/maintenance/parking_spots/",
  },
  DATE_FORMAT: "dd/MM/yyyy",
  DATE_TIME_FORMAT: "dd/MM/yyyy HH:mm",
  GOOGLE_MAPS: "Https://www.google.com/maps/place/",
};
