import { Box, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useRemovePricings } from "../../../../../libs/price-lists";

export const PricingListItem = ({
  id,
  name,
  currency,
  firstPeriod,
  secondPeriod,
  thirdPeriod,
}) => {
  const removePricing = useRemovePricings(id);

  const { t } = useTranslation();
  return (
    <Box fontFamily="Ubuntu">
      <Box
        bgcolor="rgba(196, 196, 196, 0.18)"
        padding="5px 15px 5px 15px"
        marginBottom="5px"
        borderRadius={8}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item lg={5} md={5} sm={3}>
            <Box
              fontSize="16px"
              fontWeight="800"
              paddingRight="20px"
              borderRight={1}
            >
              {name}
            </Box>
          </Grid>

          <Grid item lg={2} md={2} sm={4}>
            <Box
              display="flex"
              flexDirection="row"
              fontSize="16px"
              fontWeight="800"
              color="primary.main"
            >
              <Box color="black" fontWeight="500">
                {t("pricings.first_period")}:
              </Box>
              &nbsp;{firstPeriod === null ? "--" : firstPeriod}&nbsp;
              {firstPeriod === null ? "--" : currency}
            </Box>
          </Grid>
          <Grid item lg={2} md={2} sm={4}>
            <Box
              display="flex"
              flexDirection="row"
              fontSize="16px"
              fontWeight="800"
              color="primary.main"
            >
              <Box color="black" fontWeight="500">
                {t("pricings.second_period")}:
              </Box>
              &nbsp;{secondPeriod == null ? "--" : secondPeriod}
              &nbsp;
              {secondPeriod == null ? "--" : currency}
            </Box>
          </Grid>
          <Grid item lg={2} md={2} sm={4}>
            <Box
              display="flex"
              flexDirection="row"
              fontSize="16px"
              fontWeight="800"
              color="primary.main"
            >
              <Box color="black" fontWeight="500">
                {t("pricings.third_period")}:
              </Box>
              &nbsp;{thirdPeriod == null ? "--" : thirdPeriod}&nbsp;
              {thirdPeriod == null ? "--" : currency}
            </Box>
          </Grid>

          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            container
            direction="row"
            justifyContent="flex-end"
          >
            <IconButton aria-label="delete" fontSize="small" color="secondary">
              <Delete
                onClick={() => {
                  removePricing();
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
