import { PATHS } from "../../../../libs/routes/paths";

import { ParkingSpotAccessesAdd } from "./accesses/ParkingSpotAccessesAdd";

export const PARKING_SPOT_ACCESSES = {
  create: {
    path: PATHS.parkingSpots.tabs.parkingSpotAccesses.create,
    component: ParkingSpotAccessesAdd,
  },
};

export const accessesPaths = [...Object.values(PARKING_SPOT_ACCESSES)];
