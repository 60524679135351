import frLocale from "date-fns/locale/fr";
import plLocale from "date-fns/locale/pl";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import ukLocale from "date-fns/locale/uk";

export const LANGUAGE_LOCALIZATION_PARSER = {
  "pl-PL": "plPL",
  en: "enUS",
  de: "deDE",
  fr: "frFR",
  ua: "ukUA",
};

export const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
  ua: ukLocale,
  "pl-PL": plLocale,
};

export const calendarLocale = {
  "pl-PL": "pl",
  en: "en-GB",
  de: "de",
  fr: "fr",
  ua: "ua",
};
