import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import { useParking } from "../../../../../libs/parkings";

import { ParkingAddress } from "./ParkingAddress";
import { ParkingDescription } from "./ParkingDescription";
import { ParkingMainImage } from "./ParkingMainImage";
import { ParkingPhotos } from "./ParkingPhotos";
import { ParkingRegulations } from "./ParkingRegulations";

export const GeneralInfo = ({ setTab, tabValue }) => {
  const { parkingId } = useParams();
  const { data } = useParking(parkingId);
  const canEdit = data?.data?.permissions.can_edit ?? false;
  React.useEffect(() => {
    setTab(tabValue);
  }, []);
  return (
    <Grid container columnGap="2rem">
      <Grid item xs={12} md={7}>
        {data?.data && (
          <ParkingAddress
            address={data?.data?.address}
            latitude={data?.data?.latitude}
            longitude={data?.data?.longitude}
            control={null}
          />
        )}
      </Grid>
      {canEdit && (
        <Grid item xs={12} md={4}>
          {data?.data && (
            <ParkingDescription description={data?.data?.description} />
          )}
        </Grid>
      )}
      {canEdit && (
        <Grid item container md={6} xs={12}>
          {data?.data && (
            <ParkingRegulations files={data?.data?.pdf_files ?? []} />
          )}
        </Grid>
      )}
      {canEdit && (
        <Grid marginBottom="1.875rem" item container xs={12}>
          {data?.data && (
            <ParkingMainImage
              photo={data?.data?.main_image ? [data?.data?.main_image] : []}
              maxPhotos={1}
            />
          )}
        </Grid>
      )}
      {canEdit && (
        <Grid item container xs={12}>
          {data?.data && (
            <ParkingPhotos photos={data?.data?.gallery ?? []} maxPhotos={4} />
          )}
        </Grid>
      )}
    </Grid>
  );
};
