import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useHistory } from "react-router";

import { useApi } from "../../libs/api";
import { useSetLoggedIn, useSetUserType } from "../../libs/auth";
import { config } from "../../libs/config";
import { PATHS } from "../../libs/routes/paths";

import { loginType, passwordResetType } from "./consts";

export const useRegularLogin = () => {
  const { post } = useApi();
  const setLoggedIn = useSetLoggedIn();
  const setUserType = useSetUserType();
  const history = useHistory();
  const { mutate } = useMutation(post(config.endpoints.LOGIN), {
    onSuccess: ({ data: { access, refresh } }) => {
      if (access && refresh) {
        setLoggedIn({ access, refresh });
        setUserType(loginType.regular);
        history.push(`${PATHS.base.main}${PATHS.base.welcomePage}`);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });
  return mutate;
};

export const useBusinessLogin = () => {
  const { post } = useApi();
  const setLoggedIn = useSetLoggedIn();
  const setUserType = useSetUserType();
  const history = useHistory();

  const { mutate } = useMutation(post(config.endpoints.LOGIN_BUSINESS), {
    onSuccess: ({ data: { access, refresh } }) => {
      if (access && refresh) {
        setLoggedIn({ access, refresh });
        setUserType(loginType.business);
        history.push(`${PATHS.base.main}${PATHS.base.welcomePage}`);
      }
    },
    onError: () => {
      toast.error("Niepoprawne dane logowania");
    },
  });

  return mutate;
};

export const usePasswordResetRequest = (setStep) => {
  const { post } = useApi();
  const { mutate } = useMutation(
    post(config.endpoints.PASSWORD_RESET_REQUEST),
    {
      onSuccess: () => {
        setStep(passwordResetType.verify);
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const usePasswordResetVerify = (setStep, setResetToken) => {
  const { post } = useApi();
  const { mutate } = useMutation(post(config.endpoints.PASSWORD_RESET_VERIFY), {
    onSuccess: ({ data: { token } }) => {
      setResetToken(token);
      setStep(passwordResetType.reset);
    },
    onError: (error) => {
      toast.error(error.response?.data?.errors?.[0]?.message);
    },
  });
  return mutate;
};

export const usePasswordReset = () => {
  const { post } = useApi();
  const history = useHistory();
  const { mutate } = useMutation(post(config.endpoints.PASSWORD_RESET), {
    onSuccess: () => {
      history.push(PATHS.base.login);
    },
    onError: (error) => {
      toast.error(error.response?.data?.errors?.[0]?.message);
    },
  });
  return mutate;
};
