import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { useRemoveCard } from "./data";

export const ConfirmDeleteLCDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();
  const { number, licensePlate } = content;
  const removeCard = useRemoveCard(content.id);

  const handleDelete = () => {
    removeCard();
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Usuwanie karty lojalnościowej</DialogTitle>
        <DialogContent>
          <Typography>
            <Trans
              i18nKey="loyality_cards.dialog_delete_card_text"
              number={content.number}
              licensePlate={content.licensePlate}
            >
              Czy na pewno chcesz usunąć kartę lojalnościową {{ number }} dla
              samochodu {{ licensePlate }}?
            </Trans>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("groups.buttons.cancel")}</Button>
          <Button onClick={handleDelete}>{t("groups.buttons.delete")}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
