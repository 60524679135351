import "./ParkingSubscriptionCalendar.css";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import React from "react";
import {
  Eventcalendar,
  locale,
  CalendarNav,
  SegmentedGroup,
  SegmentedItem,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
} from "@mobiscroll/react";
import { generatePath, Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import { IconButton, Typography, Box } from "@mui/material";
import i18n from "i18next";

import { useGetParkingSpots } from "../../../../../libs/parkings";
import { useApi } from "../../../../../libs/api";
import { PATHS } from "../../../../../libs/routes/paths";
import { calendarLocale } from "../../../../../libs/utils/consts";
import usePrevious from "../../../../../libs/utils/usePrevious";
import { useToggleable } from "../../../../../libs/utils";
import { ConfirmDeleteCalendarEventDialog } from "../../dialogs/ConfirmDeleteCalendarEventDialog";

import { ParkingSubscriptionDetailModal } from "./ParkingSubscriptionDetailModal";

export const ParkingSubscriptionCalendar = () => {
  const [events, setEvents] = React.useState([]);
  const [resources, setResources] = React.useState([]);
  const [view, setView] = React.useState("2");
  const [deleteDialogContent, setDeleteDialogContent] = React.useState({});
  const [calView, setCalView] = React.useState({
    timeline: {
      type: "day",
      startTime: "08:00",
      endTime: "16:00",
    },
  });
  const [selectedDate, setSelectedDate] = React.useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const previousSelectedDate = usePrevious(selectedDate);
  const { get } = useApi();
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  const [deleteOpen, { handleOpen, handleClose }] = useToggleable();
  const [shouldRefetch, setShouldRefetch] = React.useState(false);
  const [eventData, setEventData] = React.useState({});

  const { parkingId } = useParams();
  const detailUrl = generatePath(PATHS.parkings.detail, { parkingId });
  const { data: spotsData, isSuccess: spotsIsSuccess } = useGetParkingSpots(
    parkingId,
    false
  );

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "1":
        calView = {
          timeline: {
            type: "day",
            startTime: "00:00",
            endTime: "08:00",
          },
        };
        break;
      case "2":
        calView = {
          timeline: {
            type: "day",
            startTime: "08:00",
            endTime: "16:00",
          },
        };
        break;
      case "3":
      default:
        calView = {
          timeline: {
            type: "day",
            startTime: "16:00",
            endTime: "00:00",
          },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };

  const renderCustomResource = (resource) => {
    return (
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {resource.canEdit && (
          <IconButton
            aria-label="add"
            color="primary"
            component={Link}
            to={{
              pathname: `${PATHS.base.main}${detailUrl}${PATHS.parkings.tabs.parkingSubscriptions.main}${PATHS.parkings.tabs.parkingSubscriptions.create}`,
              state: { parkingSpotId: resource.id },
            }}
          >
            <AddCircleOutlineOutlined />
          </IconButton>
        )}
        <Typography variant="body1">{resource.name}</Typography>
      </Box>
    );
  };

  const renderMyHeader = () => {
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="md-work-week-picker">
            <SegmentedGroup
              theme="material"
              themeVariant="light"
              value={view}
              onChange={changeView}
            >
              <SegmentedItem value="1" theme="material" themeVariant="light">
                00:00 - 08:00
              </SegmentedItem>
              <SegmentedItem value="2" theme="material" themeVariant="light">
                08:00 - 16:00
              </SegmentedItem>
              <SegmentedItem value="3" theme="material" themeVariant="light">
                16:00 - 00:00
              </SegmentedItem>
            </SegmentedGroup>
          </div>
          <CalendarPrev className="md-work-week-prev" />
          <CalendarToday className="md-work-week-today" />
          <CalendarNext className="md-work-week-next" />
        </div>
        <CalendarNav className="md-work-week-nav" />
      </>
    );
  };

  const onEventClick = React.useCallback((args) => {
    setAnchor(args.domEvent.target);
    setEventData(args.event);
    setIsOpen(true);
  }, []);

  const closePopup = React.useCallback(() => {
    setAnchor(null);
    setIsOpen(false);
    setEventData({});
  }, []);

  const customEvent = React.useCallback((event) => {
    return (
      <div>
        {event.title}
        <br />
        {event.original.phone_number} {event.original.license_plate}
      </div>
    );
  }, []);

  React.useEffect(() => {
    const getEvents = async () => {
      await get(
        `deprecated/admin-panel/maintenance/parkings/${parkingId}/calendar/`,
        {
          date: selectedDate,
        }
      )().then(({ data }) => {
        setEvents(
          data.map((item) => {
            return {
              start: item.event_start,
              color:
                item.entitlement_reason === "PARKING_SUBSCRIPTION"
                  ? "#1D71B8"
                  : "#D6ECFF",
              end: item.event_end,
              title: `${item?.user?.first_name} ${item?.user?.last_name}`,
              resource: item.parking_spot_id,
              id: item.id,
              license_plate: item.license_plate,
              phone_number: item?.user?.phone_number ?? "-",
              from: item.start,
              to: item.end,
              can_edit: item.entitlement_reason === "PARKING_SUBSCRIPTION",
              can_delete: ["PARKING_SUBSCRIPTION", "RESERVATION"].includes(
                item.entitlement_reason
              ),
              resource_type: item.entitlement_reason,
            };
          })
        );
      });
    };
    if (previousSelectedDate !== selectedDate || shouldRefetch) {
      setTimeout(() => {
        getEvents();
      }, 2000);
      if (shouldRefetch) {
        setShouldRefetch(false);
      }
    }
  }, [selectedDate, shouldRefetch]);
  React.useEffect(() => {
    if (spotsIsSuccess) {
      const spots = spotsData?.data;

      setResources(
        spots.map((item) => {
          return {
            id: item.id,
            name: item.parking_spot_number,
            color: "1D71B8",
            canEdit: item.permissions.can_edit,
          };
        })
      );
    }
  }, [spotsIsSuccess]);

  return (
    <>
      <Eventcalendar
        theme="material"
        themeVariant="light"
        locale={locale[calendarLocale[[i18n.language]]]}
        data={events}
        view={calView}
        resources={resources}
        renderResource={renderCustomResource}
        renderHeader={renderMyHeader}
        onSelectedDateChange={(event) => {
          setSelectedDate(format(event.date, "yyyy-MM-dd"));
        }}
        onEventClick={onEventClick}
        cssClass="md-switching-timeline-view-cont"
        renderScheduleEventContent={customEvent}
      />
      {isOpen && (
        <ParkingSubscriptionDetailModal
          isOpen={isOpen}
          anchor={anchor}
          closePopup={closePopup}
          eventData={eventData}
          setDeleteDialogContent={setDeleteDialogContent}
          handleDeleteOpen={handleOpen}
        />
      )}
      {deleteOpen && (
        <ConfirmDeleteCalendarEventDialog
          key={deleteDialogContent.id}
          open={deleteOpen}
          handleClose={handleClose}
          content={deleteDialogContent}
          setShouldRefetch={setShouldRefetch}
        />
      )}
    </>
  );
};
