import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import { useGetParkingSpot } from "../../../../../libs/parking-spots";
import { ParkingSpotAccessesList } from "../accesses/ParkingSpotAccessesList";

import { ParkingSpotDescription } from "./ParkingSpotDescription";
import { ParkingSpotMainImage } from "./ParkingSpotMainImage";
import { ParkingSpotRegulations } from "./ParkingSpotRegulations";

export const GeneralInfo = ({ setTab, tabValue }) => {
  const { parkingSpotId } = useParams();
  const { data } = useGetParkingSpot(parkingSpotId);

  React.useEffect(() => {
    setTab(tabValue);
  }, []);

  return (
    <Grid container columnGap="2rem">
      <Grid container item md={5} xs={12} columnGap="2rem" rowGap="2rem">
        <Grid item xs={12}>
          {data?.data && (
            <ParkingSpotDescription description={data?.data?.description} />
          )}
        </Grid>
        <Grid item container xs={12}>
          {data?.data && (
            <ParkingSpotRegulations files={data?.data?.pdf_files ?? []} />
          )}
        </Grid>
        <Grid item container xs={12}>
          {data?.data && (
            <ParkingSpotMainImage
              photo={data?.data?.main_image ? [data?.data?.main_image] : []}
              maxPhotos={1}
            />
          )}
        </Grid>
      </Grid>
      <Grid item container md={6} xs={12}>
        <Grid item xs={12}>
          <ParkingSpotAccessesList />
        </Grid>
      </Grid>
    </Grid>
  );
};
