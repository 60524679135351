import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  useParkingEntitlements,
  useParkingHistory,
} from "../../../../../libs/parkings";
import { ParkingActivitiesTable } from "../../tables/ParkingActivitiesTable";
import { ParkingHistoryTable } from "../../tables/ParkingHistoryTable";

export const Activities = ({ setTab, tabValue }) => {
  const { parkingId } = useParams();
  const { t } = useTranslation();
  const { data, isSuccess } = useParkingEntitlements(parkingId);
  const { data: historyData, isSuccess: historyIsSuccess } =
    useParkingHistory(parkingId);

  React.useEffect(() => {
    setTab(tabValue);
  }, []);
  return (
    <>
      <Box sx={{ marginBottom: "2rem" }}>
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: 700,
          }}
        >
          {t("parkings.tabs.activities.activity_title")}
        </Typography>
        <Typography variant="body1">
          {t("parkings.tabs.activities.activity_description")}
        </Typography>
        {isSuccess && <ParkingActivitiesTable data={data?.data} />}
      </Box>
      <Box>
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: 700,
          }}
        >
          {t("parkings.tabs.activities.history_title")}
        </Typography>
        <Typography variant="body1">
          {t("parkings.tabs.activities.history_title")}
        </Typography>
        {historyIsSuccess && <ParkingHistoryTable data={historyData?.data} />}
      </Box>
    </>
  );
};
