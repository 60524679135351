import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import i18n from "../../i18n";
import { useApi } from "../api";
import { config } from "../config";
import { PATHS } from "../routes/paths";

export const useUserSpots = () => {
  const { get } = useApi();

  return useQuery("parking-spots", get(config.endpoints.USERS_SPOTS));
};

export const useGetParkingSpot = (parkingSpotId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-spot", parkingSpotId],
    get(`deprecated/admin-panel/maintenance/parking_spots/${parkingSpotId}/`)
  );
};

export const useUpdateParkingSpotDescription = (parkingSpotId) => {
  const queryClient = useQueryClient();
  const { patch } = useApi();
  const { mutate } = useMutation(patch(`parking_spots/${parkingSpotId}/`), {
    onSuccess: () => {
      queryClient.refetchQueries(["parking-spot", parkingSpotId]);
    },
    onError: (error) => {
      toast.error(error.response?.data?.errors?.[0]?.message);
    },
  });
  return mutate;
};

export const useAddParkingSpotPdf = (parkingSpotId) => {
  const queryClient = useQueryClient();
  const { post } = useApi();
  const { mutate } = useMutation(post(`parking_spots/${parkingSpotId}/pdfs/`), {
    onSuccess: () => {
      queryClient.refetchQueries(["parking-spot", parkingSpotId]);
    },
  });
  return mutate;
};

export const useRemoveParkingSpotPdf = (pdfId, parkingSpotId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(del(`parkings/pdfs/${pdfId}/`), {
    onSuccess: () => {
      queryClient.refetchQueries(["parking-spot", parkingSpotId]);
    },
  });

  return mutate;
};

export const useParkingSpotEntitlements = (parkingSpotId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-spot-history-entitlements", parkingSpotId],
    get(
      `deprecated/admin-panel/maintenance/parking_spots/${parkingSpotId}/entitlements/`
    )
  );
};

export const useParkingSpotHistory = (parkingSpotId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-spot-history-reservations", parkingSpotId],
    get(
      `deprecated/admin-panel/maintenance/parking_spots/${parkingSpotId}/reservations/`
    ),
    {
      enabled: !!parkingSpotId,
    }
  );
};

export const useGetParkingSpotRules = (parkingSpotId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-spot-rules", parkingSpotId],
    get(`deprecated/admin-panel/parking_spots/${parkingSpotId}/rules/`),
    {
      enabled: !!parkingSpotId,
    }
  );
};

export const useRemoveParkingSpotRules = (rulesId, parkingSpotId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(
      `deprecated/admin-panel/parking_spots/${parkingSpotId}/rules/${rulesId}/`
    ),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking-spot-rules", parkingSpotId]);
      },
    }
  );
  return mutate;
};

export const useAddParkingSpotRules = (parkingSpotId) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { post } = useApi();
  const { mutate } = useMutation(
    post(`deprecated/admin-panel/parking_spots/${parkingSpotId}/rules/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking-spot-rules", parkingSpotId]);
        const detailUrl = generatePath(PATHS.parkingSpots.detail, {
          parkingSpotId,
        });
        history.push(
          `${PATHS.base.main}${detailUrl}${PATHS.parkingSpots.tabs.parkingSpotRules.main}`
        );
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useGetParkingSpotRule = (parkingSpotId, ruleId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-rules", parkingSpotId, ruleId],
    get(`deprecated/admin-panel/parkings/rules/${ruleId}/`)
  );
};

export const useEditParkingSpotRules = (parkingSpotId, ruleId) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { patch } = useApi();
  const { mutate } = useMutation(
    patch(`deprecated/admin-panel/parkings/rules/${ruleId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking-spot-rules", parkingSpotId]);
        const detailUrl = generatePath(PATHS.parkingSpots.detail, {
          parkingSpotId,
        });
        history.push(
          `${PATHS.base.main}${detailUrl}${PATHS.parkingSpots.tabs.parkingSpotRules.main}`
        );
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useGetParkingSpotAccesses = (parkingSpotId) => {
  const { get } = useApi();

  return useQuery(
    ["parking-spot-accesses", parkingSpotId],
    get(`parking_spots/${parkingSpotId}/co-users/`),
    {
      enabled: !!parkingSpotId,
    }
  );
};

export const useRemoveParkingSpotAccesses = (parkingSpotId) => {
  const queryClient = useQueryClient();
  const { post } = useApi();
  const { mutate } = useMutation(
    post(`parking_spots/${parkingSpotId}/co-users/remove`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking-spot-accesses", parkingSpotId]);
      },
    }
  );
  return mutate;
};

export const useAddParkingSpotAccesses = (parkingSpotId) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { post } = useApi();
  const { mutate } = useMutation(
    post(`parking_spots/${parkingSpotId}/co-users/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking-spot-accesses", parkingSpotId]);
        const detailUrl = generatePath(PATHS.parkingSpots.detail, {
          parkingSpotId,
        });
        history.push(
          `${PATHS.base.main}${detailUrl}${PATHS.parkingSpots.tabs.generalInfo}`
        );
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useCreateParkingSpotReservation = (parkingSpotId, handleClose) => {
  const { post } = useApi();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    post(`parking_spots/${parkingSpotId}/reservations/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          "parking-spot-history-entitlements",
          parkingSpotId,
        ]);
        toast.success(i18n.t("parking_spots.dialogs.success_share_spot"));
        handleClose();
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useRemoveParkingSpotMainImage = (parkingSpotId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(
      `deprecated/admin-panel/maintenance/parking_spots/${parkingSpotId}/main-image/`
    ),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking-spot", parkingSpotId]);
      },
    }
  );
  return mutate;
};

export const useAddParkingSpotMainImage = (parkingSpotId) => {
  const queryClient = useQueryClient();
  const { put } = useApi();
  const { mutate } = useMutation(
    put(
      `deprecated/admin-panel/maintenance/parking_spots/${parkingSpotId}/main-image/`
    ),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["parking-spot", parkingSpotId]);
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useCheckSpotAvailability = (parkingSpotId) => {
  const { post } = useApi();
  return useMutation(
    post(`subscriptions/spots/${parkingSpotId}/check-availability/`),
    {
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
};
