import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

import { theme } from "./libs/theme";
import { AuthContextProvider } from "./libs/auth";
import { localeMap } from "./libs/utils/consts";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const AppProviders = ({ children }) => {
  const { i18n } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={localeMap[i18n.language]}
          >
            <BrowserRouter>
              {children}
              <ReactQueryDevtools />
            </BrowserRouter>
          </LocalizationProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
