import React from "react";
import { useParams } from "react-router-dom";

import { useGetChargerActivities } from "../../../../libs/chargers";
import { ChargerActivitiesTable } from "../tables/ChargerActivitiesTable";

export const ChargerActivities = ({ setTab, tabValue }) => {
  const { chargerId } = useParams();
  const { data } = useGetChargerActivities(chargerId);

  React.useEffect(() => {
    setTab(tabValue);
  }, []);

  return <ChargerActivitiesTable data={data?.data ?? []} />;
};
