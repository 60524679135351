export const passwordResetType = Object.freeze({
  request: "REQUEST",
  verify: "VERIFY",
  reset: "RESET",
});

export const loginType = Object.freeze({
  regular: "REGULAR",
  business: "BUSINESS",
});
