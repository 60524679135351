import React from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";

import { PATHS } from "../routes/paths";
import { useSessionStorage } from "../utils";

const initialState = { accessToken: "", refreshToken: "" };

const Context = React.createContext([initialState, {}]);

export const AuthContextProvider = ({ children }) => {
  const [accessToken, persistAccessToken, resetAccessToken] =
    useSessionStorage("access-token") || "";
  const [refreshToken, persistRefreshToken, resetRefreshToken] =
    useSessionStorage("refresh-token") || "";
  const [userType, persistUserType, resetUserType] =
    useSessionStorage("user-type") || "";
  const state = React.useMemo(
    () => ({ accessToken, refreshToken, userType }),
    [accessToken, refreshToken, userType]
  );

  const setTokens = React.useCallback(
    ({ access, refresh }) => {
      persistAccessToken(access);
      persistRefreshToken(refresh);
    },
    [persistAccessToken, persistRefreshToken]
  );

  const setUserType = React.useCallback(
    (type) => {
      persistUserType(type);
    },
    [persistUserType]
  );

  const resetTokens = React.useCallback(() => {
    resetAccessToken();
    resetRefreshToken();
  }, [persistAccessToken, persistRefreshToken]);

  const resetType = React.useCallback(() => {
    resetUserType();
  }, [persistUserType]);

  return (
    <Context.Provider
      value={[state, { setTokens, resetTokens, setUserType, resetType }]}
    >
      {children}
    </Context.Provider>
  );
};

//TODO: move hooks outside of this file
export const useAccessToken = () => {
  const [{ accessToken }] = React.useContext(Context);
  return React.useMemo(() => accessToken, [accessToken]);
};
export const useRefreshToken = () => {
  const [{ refreshToken }] = React.useContext(Context);
  return React.useMemo(() => refreshToken, [refreshToken]);
};

export const useUserType = () => {
  const [{ userType }] = React.useContext(Context);
  return React.useMemo(() => userType, [userType]);
};

export const useSetLoggedIn = () => {
  const [, { setTokens }] = React.useContext(Context);
  return setTokens;
};

export const useSetUserType = () => {
  const [, { setUserType }] = React.useContext(Context);
  return setUserType;
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [, { resetTokens }] = React.useContext(Context);
  const [, { resetType }] = React.useContext(Context);
  return React.useCallback(() => {
    resetTokens();
    resetType();
    history.push(PATHS.base.login);
    queryClient.clear();
  }, [resetTokens, resetType, history.push]);
};
