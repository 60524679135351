import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useChargerStartTransaction } from "../../../../libs/chargers";

export const ConfirmStartTransactionDialog = ({
  open,
  handleClose,
  content,
}) => {
  const { t } = useTranslation();
  const startTransaction = useChargerStartTransaction(
    content.id,
    content.chargerId
  );

  const handleConfirm = () => {
    startTransaction();
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("chargers.dialogs.startTransactionTitle")}</DialogTitle>
        <DialogContent>
          <Typography>
            {t("chargers.dialogs.startTransactionContent")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("chargers.buttons.cancel")}</Button>
          <Button onClick={handleConfirm}>
            {t("chargers.buttons.accept")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
