import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "yup-phone";
import {
  Button,
  Box,
  FormHelperText,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { Add } from "@material-ui/icons";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { DateTimePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import Select from "react-select";

import i18n from "../../../../../i18n";
import { ParkingRulesFormTable } from "../../tables/ParkingRulesFormTable";
import { AvailabilitiesArrayField } from "../parking_rules/AvailabilitiesArrayField";
import {
  useEditParkingSpotSubscription,
  useGetParkingSpots,
  useGetParkingSubscription,
} from "../../../../../libs/parkings";
import { useCheckSpotAvailability } from "../../../../../libs/parking-spots";
import { useToggleable } from "../../../../../libs/utils";
import { RestrictionsDialog } from "../../dialogs/RestrictionsDialog";

const validationSchema = () =>
  yup
    .object()
    .required()
    .optional()
    .shape({
      parkingSpot: yup
        .object()
        .nullable()
        .test("empty-select", "Wybierz miejsce parkingowe", (value) => !!value),
    });

export const ParkingSubscriptionEdit = () => {
  const { t } = useTranslation();
  const { parkingId } = useParams();
  const { subscriptionId } = useParams();

  const location = useLocation();
  // const parkingSpotId = location.state?.parkingSpotId ?? null;
  const editSpotSubscription = useEditParkingSpotSubscription(
    subscriptionId,
    parkingId
  );
  const [parkingSpotId, setParkingSpotId] = React.useState(
    location.state?.parkingSpotId ?? null
  );
  const checkAvailability = useCheckSpotAvailability(parkingSpotId);
  const [open, { handleOpen, handleClose }] = useToggleable();
  const [restrictions, setRestrictions] = React.useState([]);
  const [submitPayload, setSubmitPayload] = React.useState({});
  const [spots, setSpots] = React.useState([]);

  const localeValidationSchema = React.useMemo(
    () => validationSchema(),
    [i18n.language]
  );
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      phone_number: "",
    },
    criteriaMode: "all",
    mode: "onSubmit",
    resolver: yupResolver(localeValidationSchema),
  });
  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: "availabilities",
  });

  const { data: spotsData, isSuccess: spotsIsSuccess } =
    useGetParkingSpots(parkingId);

  const { data: subscriptionData, isSuccess: subscriptionIsSuccess } =
    useGetParkingSubscription(subscriptionId);

  const onSubmit = async (payload) => {
    const data = {};
    data.has_time_restrictions = payload.availabilities.length > 0;
    data.time_restrictions = payload.availabilities;
    data.start = format(new Date(payload.start), "yyyy-MM-dd'T'HH:mm");
    data.end = format(new Date(payload.end), "yyyy-MM-dd'T'HH:mm");
    data.license_plate = payload.license_plate;
    data.parking_spot = payload.parkingSpot.id;

    const { data: availableData } = await checkAvailability.mutateAsync({
      ...data,
      excluded_subscription_ids: [`${subscriptionId}`],
    });
    if (availableData.length > 0) {
      setRestrictions(availableData);
      setSubmitPayload(data);
      handleOpen();
    } else {
      editSpotSubscription(data);
    }
  };

  React.useEffect(() => {
    if (spotsIsSuccess) {
      const userSpots = spotsData?.data;

      setSpots(
        userSpots
          .filter((spot) => spot.permissions.can_edit)
          .map((item) => {
            return {
              id: item.id,
              name: item.parking_spot_number,
              color: "#1D71B8",
              canEdit: item.permissions.can_edit,
            };
          })
      );
    }
  }, [spotsIsSuccess]);

  React.useEffect(() => {
    if (parkingSpotId && spots.length > 0) {
      setValue(
        "parkingSpot",
        spots.find((item) => item.id === parkingSpotId)
      );
    }
  }, [spots]);

  React.useEffect(() => {
    if (subscriptionIsSuccess) {
      setValue("license_plate", subscriptionData?.data?.license_plate ?? "");
      if (spots.length > 0) {
        setValue(
          "parkingSpot",
          spots.find((item) => item.id === subscriptionData?.data?.parking_spot)
        );
        setParkingSpotId(subscriptionData?.data?.parking_spot ?? null);
      }
      setValue("start", subscriptionData?.data?.start);
      setValue("end", subscriptionData?.data?.end);

      replace(subscriptionData?.data?.time_restrictions ?? []);
    }
  }, [subscriptionIsSuccess, spots]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        >
          <Button
            startIcon={<Add />}
            variant="contained"
            type="submit"
            color="primary"
          >
            {t("parking_spots.buttons.save")}
          </Button>
        </Box>
        <Grid container sx={{ columnGap: "2rem" }}>
          <Grid item container xs={12} lg={5}>
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: "2rem",
              }}
            >
              <Controller
                name="license_plate"
                control={control}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <Box sx={{ maxWidth: "fit-content" }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        color: "#343434",
                        marginBottom: "1.5rem",
                      }}
                    >
                      1. {t("parkings.fields.license_plate.label")}
                    </Typography>
                    <Input
                      {...fieldProps}
                      inputRef={ref}
                      fullWidth
                      placeholder={t(
                        "parkings.fields.license_plate.placeholder"
                      )}
                      error={!!error}
                    />
                    {error?.message && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "2.5rem" }}>
              <Controller
                name="parkingSpot"
                control={control}
                rules={{ required: t("parkings.fields.parkingSpots.label") }}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <Box sx={{ maxWidth: "fit-content" }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        color: "#343434",
                        marginBottom: "1.5rem",
                      }}
                    >
                      2. {t("parkings.fields.parkingSpots.label")}
                    </Typography>
                    <Select
                      {...fieldProps}
                      inputRef={ref}
                      options={spots}
                      isDisabled
                      getOptionLabel={(option) => option.name}
                      placeholder={t(
                        "parkings.fields.parkingSpots.placeholder"
                      )}
                      getOptionValue={(option) => option.id}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {error?.message && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  color: "#343434",
                  marginBottom: "1.5rem",
                }}
              >
                3. {t("parkings.fields.datetime_range.label")}
              </Typography>
              <Box sx={{ display: "inline-flex", marginBottom: "1.5rem" }}>
                <Box sx={{ marginRight: "1rem" }}>
                  <Controller
                    name="start"
                    control={control}
                    defaultValue={moment().format("YYYY-MM-DDTHH:mm")}
                    render={({
                      field: { ref, ...fieldProps },
                      fieldState: { error },
                    }) => (
                      <>
                        <DateTimePicker
                          {...fieldProps}
                          inputRef={ref}
                          ampm={false}
                          label={t(
                            "parkings.fields.datetime_range.start.label"
                          )}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          renderInput={(props) => (
                            <TextField {...props} size="small" />
                          )}
                        />
                        {error?.message && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="end"
                    control={control}
                    defaultValue={moment()
                      .add(1, "week")
                      .format("YYYY-MM-DDTHH:mm")}
                    render={({
                      field: { ref, ...fieldProps },
                      fieldState: { error },
                    }) => (
                      <>
                        <DateTimePicker
                          {...fieldProps}
                          inputRef={ref}
                          ampm={false}
                          label={t("parkings.fields.datetime_range.end.label")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          renderInput={(props) => (
                            <TextField {...props} size="small" />
                          )}
                        />
                        {error?.message && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12} lg={6}>
            <Grid item xs={12}>
              <AvailabilitiesArrayField append={append} customNumber="4" />
              <ParkingRulesFormTable
                data={fields}
                removeItem={remove}
                updateItem={update}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
      {open && (
        <RestrictionsDialog
          key={restrictions.length}
          open={open}
          handleClose={handleClose}
          content={restrictions}
          payload={submitPayload}
          parkingId={parkingId}
          subscriptionId={subscriptionId}
          isEdit
        />
      )}
    </>
  );
};
