import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    primary: {
      //standard Share.P blue
      main: "#1D71B8",
      //bright blue
      light: "#9FD3FF",
      //dark blue
      dark: "#155993",
      contrastText: "#fff",
      white: "white",
    },
    secondary: {
      //light red
      light: "#ff7961",
      //red
      main: "#f44336",
      //
      dark: "#9FA2B4",
      contrastText: "#000",
    },
    third: {
      main: "white",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "Ubuntu",
      },
      text: {
        textTransform: "none",
      },
      sizeLarge: {
        textTransform: "none",
        padding: "11px 72px",
        fontSize: "16px",
      },
      sizeSmall: {
        textTransform: "none",
      },
      // sizeMedium: {
      //   textTransform: "none",
      //   padding: "11px 60px",
      // },
    },
    MuiDialog: {
      paperWidthSm: { maxWidth: 840 },
      paper: {
        border: "none",
        borderRadius: 37,
        boxShadow:
          "inset 0px 0px 14px rgba(0, 0, 0, 0.5), 0px 4px 26px rgba(0, 0, 0, 0.25) ",
        padding: "20px",
      },
    },
    MuiDialogContent: { root: { padding: "10px 35px 30px" } },
    MuiTextField: { root: { minHeight: 64 } },
  },
});
