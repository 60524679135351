import { Add } from "@material-ui/icons";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import { useParams } from "react-router-dom";

import {
  useAddParkingGalleryItem,
  useRemoveParkingGalleryItem,
} from "../../../../../libs/parkings";

export const ParkingPhotosItem = ({
  item,
  index,
  onImageRemove,
  parkingId,
}) => {
  const removeMainImage = useRemoveParkingGalleryItem(parkingId, item.id);
  const { t } = useTranslation();
  return (
    <Box key={item.id} sx={{ display: "inline-flex", flexDirection: "column" }}>
      <Avatar
        variant="square"
        src={item.image}
        sx={{ width: 128, height: 128 }}
      />
      <Button
        aria-label="delete"
        onClick={() => {
          removeMainImage();
          onImageRemove(index);
        }}
      >
        {t("parkings.buttons.delete")}
      </Button>
    </Box>
  );
};

const imageSize = 2;

export const ParkingPhotos = ({ photos, maxPhotos }) => {
  const [images, setImages] = React.useState([]);
  const { parkingId } = useParams();
  const { t } = useTranslation();
  const addGalleryItem = useAddParkingGalleryItem(parkingId);
  const onChange = (imageList, addUpdateIndex) => {
    if (addUpdateIndex) {
      const formData = new FormData();
      formData.append("image", imageList?.[addUpdateIndex]?.file);
      addGalleryItem(formData);
    }
  };
  React.useEffect(() => {
    setImages(photos);
  }, [photos]);

  return (
    <Grid item container md={6} xs={12} spacing={0}>
      <Grid
        item
        xs={12}
        marginBottom="0.625rem"
        sx={{
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: 700,
            marginRight: "0.5rem",
          }}
        >
          {t("parkings.tabs.general.photos.gallery")}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "primary.main",
            fontWeight: 400,
          }}
        >
          <Trans
            i18nKey="parkings.tabs.general.photos.galleryDescription"
            imageSize={imageSize}
            maxPhotos={maxPhotos}
          >
            (maks. {{ maxPhotos }} zdjęcia ({{ imageSize }}MB))
          </Trans>
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <ImageUploading
          key={images.length}
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxPhotos}
          dataURLKey="image"
        >
          {({ imageList, onImageUpload, onImageRemove }) => (
            <Grid container item xs={12} rowGap={2} columnGap={2}>
              {imageList.map((image, index) => (
                <ParkingPhotosItem
                  key={image.id}
                  item={image}
                  index={index}
                  onImageRemove={onImageRemove}
                  parkingId={parkingId}
                />
              ))}
              {images.length < maxPhotos && (
                <Box>
                  <Avatar
                    variant="square"
                    sx={{
                      width: 128,
                      height: 128,
                      bgcolor: "white",
                      color: "primary.main",
                      border: "1px solid",
                      borderColor: "primary.main",
                    }}
                    onClick={onImageUpload}
                  >
                    <Add />
                  </Avatar>
                </Box>
              )}
            </Grid>
          )}
        </ImageUploading>
      </Grid>
    </Grid>
  );
};
