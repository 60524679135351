import React, { useEffect, useState } from "react";
import axios from "axios";
import { stringify } from "query-string";

import { config } from "../config";
import { useAccessToken, useSessionRefresh } from "../auth";

const axiosInstance = axios.create({
  baseURL: config.API_URL,
});

export const useApi = () => {
  const [instance, setInstance] = useState(0);
  useAuthorizationInterceptor(axiosInstance);
  useSessionRefresh(axiosInstance);

  return React.useMemo(
    () => ({
      reset: () => setInstance((i) => i + 1),
      get: (url, query, paramFormat = "bracket") => {
        return () =>
          axiosInstance.get(
            `${url}${query ? "?" : ""}${stringify(query || {}, {
              arrayFormat: paramFormat,
            })}`
          );
      },
      post: (url) => (body) => {
        return axiosInstance.post(url, body);
      },
      put: (url) => (body) => {
        return axiosInstance.put(url, body);
      },
      delete: (url) => (body) => {
        return axiosInstance.delete(url, body);
      },
      patch: (url) => (body) => {
        return axiosInstance.patch(url, body);
      },
    }),
    [instance]
  );
};

const useAuthorizationInterceptor = (axiosInstance) => {
  const accessToken = useAccessToken();
  const headers = React.useMemo(
    () => ({
      "content-type": "application/json",
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    }),
    [accessToken]
  );
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.request.use((request) => {
      request.headers = headers;
      return request;
    });

    return () => axiosInstance.interceptors.request.eject(interceptor);
  }, [headers]);
};
