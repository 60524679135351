import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { useRemoveParkingSpotAccesses } from "../../../../libs/parking-spots";

export const ConfirmDeleteAccessesDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();
  const del = useRemoveParkingSpotAccesses(content.parkingSpotId);
  const { name } = content;

  const handleDelete = () => {
    del({
      phone_number: content.phoneNumber,
      group_id: content.is_group ? content.id : null,
      is_group: content.is_group,
    });
    handleClose();
  };

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {t("parking_spots.dialogs.delete_access_title")}
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Trans
              i18nKey="parking_spots.dialogs.delete_access_text"
              owner={content.name}
            >
              Czy napewno chcesz usunąć dostęp dla {{ name }}?
            </Trans>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t("parking_spots.buttons.cancel")}
          </Button>
          <Button onClick={handleDelete}>
            {t("parking_spots.buttons.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
