import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Box } from "@mui/system";
import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { useAddVehicle, useGetVehicles, useGetModels } from "./data";

const validationSchema = yup
  .object()
  .required()
  .shape({
    license_plate: yup.string().required("Numer rejestracyjny jest wymagany"),
    manufacturer: yup.string().required("Marka jest wymagana"),
    model: yup.string().required("Model jest wymagany"),
  });

const initialValues = {
  license_plate: "",
  manufacturer: "",
  model: "",
};

export const AddVehicleForm = React.memo(
  ({ handleClose, defaultValues = initialValues }) => {
    const addVehicle = useAddVehicle();
    const { t } = useTranslation();
    const vehicleManufacturers = useGetVehicles();
    const [manufacturer, setManufacturer] = useState("");

    const vehicleModels = useGetModels(manufacturer?.id);
    const [model, setModel] = useState("");

    const { handleSubmit, control, formState } = useForm({
      defaultValues,
      criteriaMode: "all",
      mode: "onChange",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = handleSubmit((payload) => {
      addVehicle(payload);
      handleClose();
    });

    return (
      <form noValidate onSubmit={onSubmit}>
        <Box borderBottom={1} paddingBottom="20px" borderColor="#C4C4C4">
          <Grid container spacing={3} style={{ height: "100%" }}>
            <Grid item xs={6}>
              <Box marginBottom="20px">{t("vehicles.vehicle_form_plates")}</Box>
              <Box display="flex" flexDirection="row">
                <Controller
                  name="license_plate"
                  control={control}
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { invalid, error },
                  }) => (
                    <TextField
                      {...fieldProps}
                      fullWidth
                      variant="outlined"
                      required
                      label={t("vehicles.vehicle_plates_label")}
                      size="small"
                      error={invalid ? "Pole wymagane" : null}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="16px"
                  fontWeight="400"
                  marginBottom="15px"
                >
                  {t("vehicles.vehicle_form_plates_help")}
                </Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="14px"
                  fontWeight="200"
                  lineHeight="20px"
                  textAlign="justify"
                >
                  {t("vehicles.vehicle_form_plates_text")}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          borderBottom={1}
          paddingBottom="20px"
          borderColor="#C4C4C4"
          marginTop="20px"
        >
          <Grid container spacing={3} style={{ height: "100%" }}>
            <Grid item xs={6}>
              <Box marginBottom="20px">{t("vehicles.vehicle_form_brand")}</Box>
              <Box display="flex" flexDirection="row">
                <Controller
                  name="manufacturer"
                  control={control}
                  render={({
                    field: { onChange, ...fieldProps },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      {...fieldProps}
                      value={manufacturer || ""}
                      onChange={(_, newValue) => {
                        setManufacturer(newValue);
                        onChange(newValue ? newValue.manufacturer : "");
                        setModel("");
                      }}
                      fullWidth
                      disablePortal
                      id="manufacturer"
                      options={vehicleManufacturers.data?.data ?? []}
                      getOptionLabel={(option) => option?.manufacturer || ""}
                      getOptionSelected={(option) => option?.manufacturer}
                      sx={{ width: 300 }}
                      error={invalid ? "Pole wymagane" : null}
                      helperText={error?.message}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          label={t("vehicles.vehicle_form_brand_label")}
                          required
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="16px"
                  fontWeight="400"
                  marginBottom="15px"
                >
                  {t("vehicles.vehicle_form_brand_help")}
                </Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="14px"
                  fontWeight="200"
                  lineHeight="20px"
                  textAlign="justify"
                >
                  {t("vehicles.vehicle_form_brand_text")}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          borderBottom={1}
          paddingBottom="20px"
          borderColor="#C4C4C4"
          marginTop="20px"
        >
          <Grid container spacing={3} style={{ height: "100%" }}>
            <Grid item xs={6}>
              <Box marginBottom="20px">
                {t("vehicles.vehicle_form_model_label")}
              </Box>
              <Box display="flex" flexDirection="row">
                <Controller
                  name="model"
                  control={control}
                  render={({
                    field: { onChange, ...fieldProps },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      {...fieldProps}
                      value={model || ""}
                      onChange={(_, newValue) => {
                        setModel(newValue);
                        onChange(newValue ? newValue.model : "");
                      }}
                      fullWidth
                      disablePortal
                      disabled={!manufacturer}
                      id="model"
                      options={vehicleModels.data?.data ?? []}
                      getOptionLabel={(option) => option?.model ?? ""}
                      sx={{ width: 300 }}
                      error={invalid ? "Pole wymagane" : null}
                      helperText={error?.message}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          label={t("vehicles.vehicle_form_model_label")}
                          required
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="16px"
                  fontWeight="400"
                  marginBottom="15px"
                >
                  {t("vehicles.vehicle_form_model_help")}
                </Box>
                <Box
                  fontFamily="Ubuntu"
                  fontSize="14px"
                  fontWeight="200"
                  lineHeight="20px"
                  textAlign="justify"
                >
                  {t("vehicles.vehicle_form_model_text")}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3} width="100%" display="flex" justifyContent="flex-end">
          <Button
            disabled={!formState.isValid}
            id="btnModal"
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            {t("buttons.next")}
          </Button>
        </Box>
      </form>
    );
  }
);
