import React from "react";
import MaterialReactTable from "material-react-table";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

import { config } from "../../../../libs/config";
import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";

export const ParkingSpotActivitiesTable = ({ data }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const columns = React.useMemo(() => [
    {
      accessorKey: "license_plate",
      header: t("parking_spots.tables.license_plate"),
    },
    {
      accessorFn: (row) => row.entitlement_reason,
      header: t("parking_spots.tables.entitlement_reason"),
    },
    {
      accessorFn: (row) => new Date(row.start),
      muiTableHeadCellFilterTextFieldProps: {
        type: "datetime",
      },
      sortingFn: "datetime",
      Cell: ({ cell }) =>
        format(Date.parse(cell.getValue()), config.DATE_TIME_FORMAT),
      header: t("parking_spots.tables.start"),
    },
    {
      accessorFn: (row) => new Date(row.end),
      muiTableHeadCellFilterTextFieldProps: {
        type: "datetime",
      },
      sortingFn: "datetime",
      Cell: ({ cell }) =>
        format(Date.parse(cell.getValue()), config.DATE_TIME_FORMAT),
      header: t("parking_spots.tables.end"),
    },
  ]);

  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        key={i18n.language}
        localization={t("tables", { returnObjects: true })}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableSorting
      />
    </ThemeProvider>
  );
};
