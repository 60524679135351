import { Box, Button, FormHelperText, Input, Typography } from "@mui/material";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAddGroup } from "../../libs/groups";
import { GenericContentBox } from "../../components/commons/GenericContentBox";
import i18n from "../../i18n";
import { PATHS } from "../../libs/routes/paths";

const validationSchema = () =>
  yup
    .object()
    .required()
    .shape({
      displayName: yup.string().required(i18n.t("groups.fields.group_name")),
    });

export const GroupAdd = () => {
  const { t } = useTranslation();
  const localeValidationSchema = React.useMemo(
    () => validationSchema(),
    [i18n.language]
  );
  const { handleSubmit, control } = useForm({
    // defaultValues,
    criteriaMode: "onSubmit",
    mode: "onSubmit",
    resolver: yupResolver(localeValidationSchema),
  });

  const addGroup = useAddGroup();
  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("groups.breadcrumbs.main"),
        path: `${PATHS.base.main}${PATHS.groups.list}`,
      },
      { title: t("groups.breadcrumbs.add"), path: "" },
    ],
    []
  );
  const onSubmit = handleSubmit((payload) => {
    const data = { ...payload, display_name: payload.displayName };
    addGroup(data);
  });

  return (
    <GenericContentBox title={t("groups.main_title")} breadcrumbs={breadcrumbs}>
      <form onSubmit={onSubmit}>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "flex-end",
          }}
        >
          <Controller
            name="displayName"
            control={control}
            defaultValue=""
            render={({
              field: { ref, ...fieldProps },
              fieldState: { error },
            }) => (
              <Box
                sx={{ maxWidth: "15rem", width: "100%", marginRight: "2rem" }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#343434",
                    marginBottom: "1.5rem",
                  }}
                >
                  1. {t("groups.group_name")}
                </Typography>
                <Input
                  {...fieldProps}
                  inputRef={ref}
                  fullWidth
                  id="group-name"
                  placeholder={t("groups.group_name_field")}
                  error={!!error}
                />
                {error?.message && (
                  <FormHelperText error>{error?.message}</FormHelperText>
                )}
              </Box>
            )}
          />
          <Box>
            <Button variant="contained" type="submit" color="primary">
              {t("groups.buttons.save")}
            </Button>
          </Box>
        </Box>
      </form>
    </GenericContentBox>
  );
};
