import { Box, IconButton, DialogContent, Dialog } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

import { useToggleable } from "../../../libs/utils";

import {
  AddPricingContextProvider,
  usePricingState,
  Stage,
  usePricingDispatcher,
} from "./AddPricingContext";
import {
  PricingTypeStep,
  PricingDecriptionStep,
  SubDurationStep,
  BasicDurationStep,
} from "./components";

export const AddPricingDialog = ({ children }) => {
  const [open, { handleOpen, handleClose }] = useToggleable();
  return (
    <>
      {children(handleOpen)}
      <AddPricingContextProvider>
        <Dialog
          open={open}
          onClose={handleClose}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <AddPricingModalContent handleClose={handleClose} />
        </Dialog>
      </AddPricingContextProvider>
    </>
  );
};

const AddPricingModalContent = React.memo(({ handleClose }) => {
  const { t } = useTranslation();
  const stage = usePricingState(({ stage }) => stage);
  const dispatch = usePricingDispatcher();
  const Component = stageMap[stage];

  const onClose = () => {
    dispatch({ type: "reset" });
    handleClose();
  };

  return (
    <DialogContent>
      <Box width="760px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            fontFamily="Ubuntu"
            fontSize="27px"
            fontWeight="800"
            color="primary.main"
          >
            {t("pricing_setup.title")}
          </Box>
          <Box>
            <IconButton aria-label="delete" color="primary" onClick={onClose}>
              <Clear />
            </IconButton>
          </Box>
        </Box>
        <Component onClose={onClose} />
      </Box>
    </DialogContent>
  );
});

const stageMap = {
  [Stage.Type]: PricingTypeStep,
  [Stage.Desc]: PricingDecriptionStep,
  [Stage.BasicDuration]: BasicDurationStep,
  [Stage.SubDuration]: SubDurationStep,
};
