import { useMutation, useQueryClient } from "react-query";

import { useApi } from "../../../libs/api";
import { config } from "../../../libs/config";

export const useUpdateUserAvatar = () => {
  const queryClient = useQueryClient();
  const { put } = useApi();
  const { mutate } = useMutation(put(config.endpoints.AVATAR), {
    onSuccess: () => {
      queryClient.refetchQueries("user");
    },
  });
  return mutate;
};
