import { ParkingHistory } from "../../pages/user-parking-history/ParkingHistory";
import { OwnersPanel } from "../../pages/owners-dashboard/OwnersPanel";
import { ParkingSpotsList } from "../../pages/parking-spots/ParkingSpotsList";
import { YourPricing } from "../../pages/pricing/YourPricing";
import { Contact } from "../../pages/technical-pages/Contact";
import { Faq } from "../../pages/technical-pages/Faq";
import { Settings } from "../../pages/technical-pages/Settings";
import { UserProfile } from "../../pages/user-profile";
import { GroupsList } from "../../pages/groups/GroupsList";
import { GroupAdd } from "../../pages/groups/GroupAdd";
import { GroupEdit } from "../../pages/groups/GroupEdit";
import { WelcomePage } from "../../pages/business/WelcomePage";
import { DiscountPage } from "../../pages/business/DiscountPage";
import { MaintainerPage } from "../../pages/business/MaintainterPage";
import { GroupDetail } from "../../pages/groups/GroupDetail";
import { ParkingsList } from "../../pages/parkings/ParkingsList";
import { ParkingDetail } from "../../pages/parkings/ParkingDetail";
import { ParkingSpotDetail } from "../../pages/parking-spots/ParkingSpotDetail";
import { ChargersList } from "../../pages/chargers/ChargersList";
import { ChargerDetail } from "../../pages/chargers/components/ChargerDetail";

import { PATHS } from "./paths";

// regular pages
export const PARKINGS = {
  list: {
    path: PATHS.parkings.list,
    component: ParkingsList,
    exact: true,
  },
  create: {},
  detail: {
    path: PATHS.parkings.detail,
    component: ParkingDetail,
  },
  edit: {},
};

export const PARKING_SPOTS = {
  list: {
    path: PATHS.parkingSpots.list,
    component: ParkingSpotsList,
    exact: true,
  },
  create: {},
  detail: {
    path: PATHS.parkingSpots.detail,
    component: ParkingSpotDetail,
  },
  edit: {},
};

export const PRICINGS = {
  list: {
    path: PATHS.pricings.list,
    component: YourPricing,
  },
  create: {},
  detail: {},
  edit: {},
};

export const GROUPS = {
  list: {
    path: PATHS.groups.list,
    component: GroupsList,
    exact: true,
  },
  create: {
    path: PATHS.groups.create,
    component: GroupAdd,
  },
  edit: {
    path: PATHS.groups.edit,
    component: GroupEdit,
  },
  detail: {
    path: PATHS.groups.detail,
    component: GroupDetail,
  },
};

export const CHARGERS = {
  list: {
    path: PATHS.chargers.list,
    component: ChargersList,
    exact: true,
  },
  detail: {
    path: PATHS.chargers.detail,
    component: ChargerDetail,
  },
};

export const DEFAULT_PAGES = {
  userProfile: {
    path: PATHS.base.userProfile,
    component: UserProfile,
  },
  welcomePage: {
    path: PATHS.base.welcomePage,
    exact: true,
    component: OwnersPanel,
  },
  faq: {
    path: PATHS.base.faq,
    component: Faq,
  },
  settings: {
    path: PATHS.base.settings,
    component: Settings,
  },
  contact: {
    path: PATHS.base.contact,
    component: Contact,
  },
  parkingHistory: {
    path: PATHS.base.parkingHistory,
    component: ParkingHistory,
  },
};

// businness pages
export const BUSINESS_PAGES = {
  welcomePage: {
    path: PATHS.base.welcomePage,
    component: WelcomePage,
    exact: true,
  },
  discount: {
    path: PATHS.base.discount,
    component: DiscountPage,
  },
  maintainer: {
    path: PATHS.base.maintainer,
    component: MaintainerPage,
  },
};

export const regularDashboardPaths = [
  ...Object.values(PARKINGS),
  ...Object.values(PRICINGS),
  ...Object.values(GROUPS),
  ...Object.values(CHARGERS),
  ...Object.values(PARKING_SPOTS),
  ...Object.values(DEFAULT_PAGES),
];

export const businessDashboardPaths = [...Object.values(BUSINESS_PAGES)];
