import { Box, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useRemovePricings } from "../../libs/price-lists";

export const PricingSubListItem = ({ id, duration, price, name, currency }) => {
  const removePricing = useRemovePricings(id);
  const { t } = useTranslation();
  return (
    <Box fontFamily="Ubuntu">
      <Box
        bgcolor="rgba(196, 196, 196, 0.18)"
        padding="5px 15px 5px 15px"
        marginBottom="5px"
        borderRadius={8}
      >
        <Grid container spacing={0} alignItems="center">
          <Grid item lg={5} md={5} sm={3}>
            <Box fontSize="16px" fontWeight="800" borderRight={1}>
              {name}
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box
                display="flex"
                flexDirection="row"
                fontSize="16px"
                fontWeight="400"
                marginLeft="10px"
              >
                <Box display="flex" marginRight="20px" alignItems="center">
                  <p style={{ fontWeight: "500" }}>
                    {t("pricings.second_sub_period_1")}:&nbsp;&nbsp;{" "}
                  </p>
                  <p style={{ color: "#1D71B8", fontWeight: "600" }}>
                    {duration}
                  </p>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={3}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box
                display="flex"
                flexDirection="row"
                fontSize="16px"
                fontWeight="400"
                marginLeft="10px"
              >
                {!!price && (
                  <Box display="flex" marginRight="20px" alignItems="center">
                    <p style={{ fontWeight: "500" }}>
                      {t("pricings.first_sub_period")}:
                    </p>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <p style={{ color: "#1D71B8", fontWeight: "600" }}>
                      {price} {currency}
                    </p>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1}
            container
            direction="row"
            justifyContent="flex-end"
          >
            <IconButton aria-label="delete" fontSize="small" color="secondary">
              <Delete
                onClick={() => {
                  removePricing();
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
