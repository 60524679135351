import React from "react";
import { Route, Switch } from "react-router-dom";

import { PATHS } from "../../../../../libs/routes/paths";
import { accessesPaths } from "../accessesPaths";

export const ParkingSpotAccesses = ({ setTab, tabValue }) => {
  const getPaths = (paths) => {
    return paths.map((route, i) => {
      return (
        <Route
          key={i}
          exact={route?.exact ?? false}
          path={`${PATHS.base.main}${PATHS.parkingSpots.detail}${PATHS.parkingSpots.tabs.parkingSpotAccesses.main}${route.path}`}
          component={route.component}
        />
      );
    });
  };

  React.useEffect(() => {
    setTab(tabValue);
  }, []);
  return (
    <>
      <Switch>{getPaths(accessesPaths)}</Switch>
    </>
  );
};
