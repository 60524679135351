import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useApi } from "../api";
import { config } from "../config";
import { PATHS } from "../routes/paths";

export const useGroups = () => {
  const { get } = useApi();
  return useQuery("groups", get(config.endpoints.GROUPS_BASE));
};

export const useAddGroup = () => {
  const { post } = useApi();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(post(config.endpoints.GROUPS_BASE), {
    onSuccess: ({ data: { id } }) => {
      const redirectUrl = generatePath(PATHS.groups.edit, {
        groupId: id,
      });
      queryClient.invalidateQueries("groups");
      history.push(`${PATHS.base.main}${redirectUrl}`);
    },
    onError: (error) => {
      toast.error(error.response?.data?.errors?.[0]?.message);
    },
  });
  return mutate;
};

export const useGetGroup = (groupId) => {
  const { get } = useApi();
  return useQuery(
    ["group", groupId],
    get(`${config.endpoints.GROUPS_BASE}${groupId}/`)
  );
};

export const useEditGroup = (groupId) => {
  const { put } = useApi();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    put(`${config.endpoints.GROUPS_BASE}${groupId}/`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("groups");
        history.push(`${PATHS.base.main}${PATHS.groups.list}`);
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useAddUserToGroup = (groupId, setUsers) => {
  const { post } = useApi();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    post(`${config.endpoints.GROUPS_BASE}${groupId}/users/`),
    {
      onSuccess: ({ data: { users } }) => {
        setUsers(users);
        queryClient.invalidateQueries("groups");
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useDeleteUserFromGroup = (groupId, setUsers) => {
  const { post } = useApi();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    post(`${config.endpoints.GROUPS_BASE}${groupId}/users/remove/`),
    {
      onSuccess: ({ data: { users } }) => {
        setUsers(users);
        queryClient.invalidateQueries("groups");
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useEditUserGroupPermission = (groupId, setUsers) => {
  const { put } = useApi();

  const { mutate } = useMutation(
    put(`${config.endpoints.GROUPS_BASE}${groupId}/users/`),
    {
      onSuccess: ({ data: { users } }) => {
        setUsers(users);
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useDeleteGroup = (groupId) => {
  const { delete: del } = useApi();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    del(`${config.endpoints.GROUPS_BASE}${groupId}/`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("groups");
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};
