import React from "react";

import { useUserDetails } from "../../libs/user";

import { VirtualWalletPaymentsHistory } from "./components/VirtualWalletPaymentsHistory";
import { VirtualWalletBalance } from "./components/VirtualWalletBalance";

export const VirtualWallet = () => {
  const { isFetched, data } = useUserDetails();
  return (
    <>
      {isFetched && (
        <>
          <VirtualWalletBalance
            amount={data.data.wallet?.amount}
            currency={data.data.wallet?.currency}
          />

          <VirtualWalletPaymentsHistory />
        </>
      )}
    </>
  );
};
