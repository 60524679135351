import { Box } from "@material-ui/core";
import React from "react";

export const TitlePageTopBarPartnerCredentials = ({
  partnerCompany,
  partnerParkingName,
  partnerLogo,
}) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box marginLeft="20px">
        <Box fontSize="16px" fontWeight="500" color="black" textAlign="right">
          {partnerCompany} {partnerParkingName}
        </Box>
        <Box fontSize="12px" fontWeight="200" color="#929292" textAlign="right">
          partner
        </Box>
      </Box>
      <Box width={48} height={48} overflow="hidden" marginLeft="20px">
        <img
          width="100%"
          height="100%"
          src={partnerLogo}
          alt="avatar"
          style={{
            // width: "100%",
            // maxHeight: "200px",
            objectFit: "cover",
          }}
        />
      </Box>
    </Box>
  );
};
