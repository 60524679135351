import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useApi } from "../api";
import { PATHS } from "../routes/paths";
import { config } from "../config";

export const useChargers = () => {
  const { get } = useApi();
  return useQuery("chargers", get(config.endpoints.CHARGERS_BASE), {
    staleTime: 0,
  });
};

export const useCharger = (chargerId) => {
  const { get } = useApi();
  return useQuery(
    ["chargers", chargerId],
    get(`${config.endpoints.CHARGERS_BASE}${chargerId}/`),
    { staleTime: 0 }
  );
};

export const useChargerRules = (chargerId) => {
  const { get } = useApi();
  return useQuery(
    ["charger-rules", chargerId],
    get(`${config.endpoints.CHARGERS_BASE}${chargerId}/rules/`)
  );
};

export const useRemoveChargerRules = (rulesId, chargerId) => {
  const queryClient = useQueryClient();
  const { delete: del } = useApi();
  const { mutate } = useMutation(
    del(`${config.endpoints.CHARGERS_BASE}${chargerId}/rules/${rulesId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["charger-rules", chargerId]);
      },
    }
  );
  return mutate;
};

export const useGetChargerPriceLists = () => {
  const { get } = useApi();

  return useQuery("charger-price-lists", get(config.endpoints.EV_PRICING));
};

export const useAddChargerRules = (chargerId) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { post } = useApi();

  const { mutate } = useMutation(
    post(`${config.endpoints.CHARGERS_BASE}${chargerId}/rules/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["charger-rules", chargerId]);
        const detailUrl = generatePath(PATHS.chargers.detail, {
          chargerId,
        });
        history.push(
          `${PATHS.base.main}${detailUrl}${PATHS.chargers.tabs.chargerRules.main}`
        );
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useGetChargerRule = (chargerId, ruleId) => {
  const { get } = useApi();

  return useQuery(
    ["charger-rule", ruleId],
    get(`${config.endpoints.CHARGERS_BASE}${chargerId}/rules/${ruleId}/`)
  );
};

export const useUpdateChargerName = (chargerId) => {
  const queryClient = useQueryClient();
  const { patch } = useApi();

  const { mutate } = useMutation(
    patch(`${config.endpoints.CHARGERS_BASE}${chargerId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["chargers", chargerId]);
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useUpdateChargerRules = (chargerId, ruleId) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { put } = useApi();

  const { mutate } = useMutation(
    put(`${config.endpoints.CHARGERS_BASE}${chargerId}/rules/${ruleId}/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["charger-rules", chargerId]);
        const detailUrl = generatePath(PATHS.chargers.detail, {
          chargerId,
        });
        history.push(
          `${PATHS.base.main}${detailUrl}${PATHS.chargers.tabs.chargerRules.main}`
        );
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useGetChargerActivities = (chargerId) => {
  const { get } = useApi();

  return useQuery(
    ["charger-activities", chargerId],
    get(`${config.endpoints.CHARGERS_BASE}${chargerId}/activities/`)
  );
};

export const useChargerStartTransaction = (connectorId, chargerId) => {
  const queryClient = useQueryClient();
  const { post } = useApi();

  const { mutate } = useMutation(
    post(`chargers/${connectorId}/owner-start-charging/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["chargers", chargerId]);
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useChargerStopTransaction = (connectorId, chargerId) => {
  const queryClient = useQueryClient();
  const { post } = useApi();

  const { mutate } = useMutation(
    post(`chargers/${connectorId}/owner-finish-charging/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["chargers", chargerId]);
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};

export const useChargerChangeStatus = (connectorId, chargerId) => {
  const queryClient = useQueryClient();
  const { post } = useApi();

  const { mutate } = useMutation(
    post(`chargers/${connectorId}/change-availability/`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["chargers", chargerId]);
      },
      onError: (error) => {
        toast.error(error.response?.data?.errors?.[0]?.message);
      },
    }
  );
  return mutate;
};
