import React from "react";
import {
  createTheme,
  IconButton,
  ThemeProvider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import * as locales from "@mui/material/locale";
import { useTranslation } from "react-i18next";
import { Visibility } from "@material-ui/icons";

import { LANGUAGE_LOCALIZATION_PARSER } from "../../../../libs/utils/consts";
import { PATHS } from "../../../../libs/routes/paths";

export const ChargersListTable = ({ data }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const columns = React.useMemo(() => [
    {
      accessorKey: "name",
      header: t("chargers.tables.name"),
    },
    {
      accessorKey: "manufacturer",
      header: t("chargers.tables.manufacturer"),
    },
    {
      accessorKey: "status",
      header: t("chargers.tables.status"),
      Cell: ({ row }) => {
        const status = row.original?.status?.toUpperCase() ?? "OFFLINE";
        return (
          <Typography
            variant="body2"
            color={status === "ONLINE" ? "#00CE2D" : "red"}
          >
            {status}
          </Typography>
        );
      },
    },
    {
      accessorKey: "parking_name",
      header: t("chargers.tables.parking_name"),
    },
    {
      accessorFn: (row) => (
        <>
          <Typography variant="body2">{row.address.address_line_1}</Typography>
          <Typography variant="body2">
            {row.address.post_code}, {row.address.city}
          </Typography>
        </>
      ),
      header: t("chargers.tables.address"),
      enableColumnOrdering: false,
    },
    {
      id: "actions",
      header: t("tables.actions"),
      columnDefType: "display",
      enableColumnOrdering: false,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ row }) => (
        <>
          <Tooltip title={t("chargers.tooltips.details")}>
            <IconButton
              key={`${row.id}-view`}
              onClick={() => {
                const url = generatePath(PATHS.chargers.detail, {
                  chargerId: row.original.id,
                });
                history.push(`${PATHS.base.main}${url}`);
              }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ]);
  return (
    <ThemeProvider
      theme={createTheme(
        theme,
        locales[LANGUAGE_LOCALIZATION_PARSER[i18n.language]]
      )}
    >
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableRowActions={false}
        positionActionsColumn="last"
        enableSorting={false}
        enableColumnFilters={false}
        enableGlobalFilter={false}
      />
    </ThemeProvider>
  );
};
