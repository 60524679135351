import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { groupPermissionsCheckboxesList } from "../consts";

import { PermissionCheckboxes } from "./PermissionCheckboxes";

export const PermissionsDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();
  const { phoneNumber } = content;
  const methods = useForm({
    criteriaMode: "onSubmit",
    mode: "onSubmit",
  });
  const defaultPermissions = React.useMemo(
    () => groupPermissionsCheckboxesList.find((item) => item.default),
    [groupPermissionsCheckboxesList]
  );
  const [checkedItems, setCheckedItems] = React.useState([
    defaultPermissions.value,
  ]);
  const onSubmit = methods.handleSubmit((payload) => {
    const data = {};
    data.permissions = payload.userType === "admin" ? [...checkedItems] : [];
    data.phone_number = phoneNumber;
    content.editUserGroupPermissions(data);
    handleClose();
  });

  useEffect(() => {
    if (open) {
      methods.reset({
        userType: content.permissions.length > 0 ? "admin" : "user",
      });
      setCheckedItems([...content.permissions, defaultPermissions.value]);
    }
  }, [content, open]);

  return (
    <Grid container spacing={3}>
      <Dialog open={open} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <DialogTitle>
              <Trans
                i18nKey="groups.dialogs.edit_perm_title"
                userCount={phoneNumber}
              >
                Edycja uprawnień dla {{ phoneNumber }}
              </Trans>
            </DialogTitle>
            <DialogContent>
              <PermissionCheckboxes
                key={checkedItems.length}
                permissionList={groupPermissionsCheckboxesList}
                radioHorizontal
                checkedItems={checkedItems}
                setCheckeditems={setCheckedItems}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>
                {t("groups.buttons.cancel")}
              </Button>
              <Button type="submit">{t("groups.buttons.edit")}</Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </Grid>
  );
};
