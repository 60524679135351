import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

import { MainPageLogo } from "../../components/commons/MainPageLogo";
import { NavigationLink } from "../../components/commons/NavigationLink";

export const SideBarMenu = ({ menuList }) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  return (
    <Box bgcolor="white" height="100vh" padding="20px 0 20px 20px">
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#1D71B8"
        borderRadius={12}
        height="100%"
        padding="5px"
      >
        <Box marginBottom="50px">
          <Box padding="30px 15px 15px 31px" marginBottom="20px">
            <MainPageLogo />
          </Box>
          {menuList.map((link, i) => {
            return (
              <NavigationLink
                key={i}
                to={`${url}${link.to}`}
                label={t(`${link.label}`)}
                icon={<link.icon />}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
