import { Box, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { useTranslation } from "react-i18next";
// import { format } from "date-fns";

// import { config } from "../../libs/config";
import { TitlePageTopBar } from "../../components/commons/TitlePageTopBar";
import { PricingListItem } from "../parking-edit/components/main/pricing-list/PricingListItem";
import { AsyncDataWrapper } from "../../libs/api";
import { usePricings } from "../../libs/price-lists";

import { PricingSubListItem } from "./PricingSubListItem";
import { AddPricingDialog } from "./add-pricing";
import { useSubscriptionPricings } from "./data";

export const YourPricing = () => {
  const pricingsResult = usePricings();
  const subscriptionsResult = useSubscriptionPricings();
  const { t } = useTranslation();

  return (
    <Box
      fontFamily="Ubuntu"
      fontSize="24px"
      fontWeight="600"
      className="top-title-bar"
    >
      <Box>
        <TitlePageTopBar label={t("pricing.main_title")} />
      </Box>
      <Box
        borderRadius={12}
        bgcolor="white"
        style={{
          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.25)",
        }}
        padding="25px 25px 25px 25px"
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Box
              fontFamily="Ubuntu"
              fontSize="20px"
              fontWeight="500"
              height="24px"
              color="primary.main"
            >
              {t("pricing.pricing_title")}
            </Box>
            <Box fontSize="20px" fontWeight="300" paddingBottom="40px">
              {t("pricing.pricing_title_description")}
            </Box>
          </Box>
          <Box marginRight="20px">
            <AddPricingDialog>
              {(handleOpen) => (
                <Button
                  // disabled
                  color="primary"
                  size="small"
                  onClick={handleOpen}
                >
                  + {t("pricing.pricing_add_new")}
                </Button>
              )}
            </AddPricingDialog>
          </Box>
        </Box>
        <Box style={{ overflow: "auto", height: "70vh" }}>
          <AsyncDataWrapper result={pricingsResult}>
            {({ data }) => (
              <>
                <Box
                  fontSize="14px"
                  fontWeight="200"
                  marginBottom="10px"
                  marginLeft="15px"
                >
                  {t("pricing.pricing_type_a")}
                </Box>
                {/* TODO: handle the case when there is no data to show */}
                {data.map((item) => (
                  <PricingListItem
                    name={item.name}
                    firstPeriod={item.price_per_first_period}
                    secondPeriod={item?.price_per_second_period}
                    thirdPeriod={item?.price_per_third_period}
                    currency={item.currency}
                    key={item.id}
                    id={item.id}
                  />
                ))}
              </>
            )}
          </AsyncDataWrapper>

          <AsyncDataWrapper result={subscriptionsResult}>
            {({ data }) => (
              <>
                <Box
                  fontSize="14px"
                  fontWeight="200"
                  marginBottom="10px"
                  marginTop="20px"
                  marginLeft="15px"
                >
                  {t("pricing.pricing_type_b")}
                </Box>
                {!data.length ? (
                  <Box
                    bgcolor="rgba(196, 196, 196, 0.18)"
                    padding="20px 15px 20px 15px"
                    marginBottom="5px"
                    borderRadius="8px"
                    fontSize="16px"
                    fontWeight="200"
                  >
                    {t("pricings.no_subscription")}
                  </Box>
                ) : (
                  //TODO: subscription pricings are not shown here, but together with PricingListItem instead
                  <Grid>
                    {data.map((item) => (
                      <PricingSubListItem
                        price={item.price}
                        currency={item.currency}
                        duration={item.duration}
                        key={item.id}
                        name={item.name}
                      />
                    ))}
                  </Grid>
                )}
              </>
            )}
          </AsyncDataWrapper>
        </Box>
      </Box>
    </Box>
  );
};
