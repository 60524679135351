import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { TitlePageTopBarBusiness } from "../../components/commons/TitlePageTopBarBusiness";
import { AsyncDataWrapper } from "../../libs/api";
import { usePartnerDetails } from "../../libs/user";

// import { AsyncDataWrap per } from "../../libs/api";

export const WelcomePage = () => {
  const { t } = useTranslation();
  const result = usePartnerDetails();

  // const mobileActive = useMediaQuery("(max-width: 414px)");

  return (
    <Box fontFamily="Ubuntu" className="top-title-bar" marginBottom="54px">
      <AsyncDataWrapper result={result}>
        {({ data }) => (
          <Box>
            <TitlePageTopBarBusiness
              label={t("business_client.title")}
              result={data}
            />
            <Box fontWeight="800" fontSize="24px" marginBottom="20px">
              {t("business_client.welcome_title")}
            </Box>
            <Box>{t("business_client.welcome_subtitle")}</Box>
          </Box>
        )}
      </AsyncDataWrapper>
    </Box>
  );
};
