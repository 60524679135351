import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";

import { usePasswordReset } from "../../hooks";

const validationSchema = yup
  .object()
  .required()
  .shape({
    password: yup.string().required("Password is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Password must match"),
  });

export const ResetPassword = ({ phoneNumber, resetToken }) => {
  const passwordResetRequest = usePasswordReset();
  const [showPassword, setShowPassword] = useState(false);

  const { handleSubmit, control } = useForm({
    // defaultValues,
    criteriaMode: "onSubmit",
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const onSubmit = handleSubmit((payload) => {
    passwordResetRequest({
      ...payload,
      phone_number: phoneNumber,
      token: resetToken,
    });
  });

  const { t } = useTranslation();

  return (
    <Card
      sx={{
        maxWidth: 310,
        borderRight: "1px solid rgba(0, 0, 0, 0.25)",
        boxShadow: "none",
      }}
    >
      <form noValidate onSubmit={onSubmit}>
        <CardContent>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "3.5rem",
                fontWeight: "600",
                fontFamily: "Ubuntu",
              }}
              variant="h5"
            >
              {t("password_reset_box_title")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                fontWeight: "200",
              }}
            >
              {t("password_reset_description")}
            </Typography>
            <Box display="flex" flexDirection="column">
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <FormControl
                    error={!!error}
                    variant="outlined"
                    size="normal"
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      {t("password_reset_field")}
                    </InputLabel>
                    <OutlinedInput
                      {...fieldProps}
                      inputRef={ref}
                      error={!!error}
                      label={t("password_reset_field")}
                      id="outlined-adornment-password"
                      aria-describedby="password-error-text"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {error?.message && (
                      <FormHelperText id="password-error-text">
                        {error?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ marginBottom: "5rem" }}
            >
              <Controller
                name="passwordConfirmation"
                control={control}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <FormControl
                    error={!!error}
                    variant="outlined"
                    size="normal"
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel htmlFor="outlined-adornment-password-confirmation">
                      {t("password_reset_confirmation_field")}
                    </InputLabel>
                    <OutlinedInput
                      {...fieldProps}
                      inputRef={ref}
                      error={!!error}
                      label={t("password_reset_confirmation_field")}
                      id="outlined-adornment-password-confirmation"
                      aria-describedby="password-confirmation-error-text"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {error?.message && (
                      <FormHelperText
                        id="password-confirmation-error-text"
                        sx={{ marginBottom: "1rem" }}
                      >
                        {error?.message}
                      </FormHelperText>
                    )}
                    <FormHelperText id="password-confirmation-helper-text">
                      Hasło powinno zawierać minimum jedną wielką literę, jedną
                      liczbą oraz znak specjalny (.,!?-_^%$#@+=)
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Box>
            <ButtonGroup orientation="vertical" fullWidth>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
              >
                {t("buttons.password_reset")}
              </Button>
            </ButtonGroup>
          </Box>
        </CardContent>
      </form>
    </Card>
  );
};
