import i18n from "../../i18n";

export const parkingSpotPermissions = {
  viewParkingSpot: {
    permission: "view_parkingspot",
  },
  freeAccessParkingSpot: {
    permission: "free_access_parkingspot",
  },
  shareParkingSpot: {
    permission: "share_parkingspot",
  },
  assignPermissionsParkingSpot: {
    permission: "assign_permissions_parkingspot",
  },
};

export const parkingSpotPermissionsCheckboxesList = [
  {
    label: i18n.t("parking_spots.checkboxes.view_parkingspot"),
    value: parkingSpotPermissions.viewParkingSpot.permission,
    default: true,
    disabled: true,
  },
  {
    label: i18n.t("parking_spots.checkboxes.free_access_parkingspot"),
    value: parkingSpotPermissions.freeAccessParkingSpot.permission,
    default: true,
    disabled: false,
  },
  {
    label: i18n.t("parking_spots.checkboxes.share_parkingspot"),
    value: parkingSpotPermissions.shareParkingSpot.permission,
    default: true,
    action: "can_share_parking_spot",
    disabled: false,
  },
  {
    label: i18n.t("parking_spots.checkboxes.assign_permissions_parkingspot"),
    value: parkingSpotPermissions.assignPermissionsParkingSpot.permission,
    default: false,
    action: "can_assign_permissions",
    disabled: false,
  },
];
