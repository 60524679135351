import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";

import { usePasswordResetRequest } from "../../hooks";

const validationSchema = yup
  .object()
  .required()
  .shape({
    phone_number: yup
      .string()
      .required("Phone number is required")
      .phone("", false, "Must be a valid phone number"),
  });

export const ResetPasswordRequest = ({ setStep, setPhoneNumber }) => {
  const passwordResetRequest = usePasswordResetRequest(setStep, setPhoneNumber);

  const { handleSubmit, control } = useForm({
    // defaultValues,
    criteriaMode: "onSubmit",
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit((payload) => {
    setPhoneNumber(payload.phone_number);
    passwordResetRequest(payload);
  });

  const { t } = useTranslation();

  return (
    <Card
      sx={{
        maxWidth: 310,
        borderRight: "1px solid rgba(0, 0, 0, 0.25)",
        boxShadow: "none",
      }}
    >
      <form noValidate onSubmit={onSubmit}>
        <CardContent>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "3.5rem",
                fontWeight: "600",
                fontFamily: "Ubuntu",
              }}
              variant="h5"
            >
              {t("password_reset_box_title")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                fontWeight: "200",
              }}
            >
              {t("password_reset_request_description")}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ marginBottom: "5rem" }}
            >
              <Controller
                name="phone_number"
                control={control}
                defaultValue=""
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <TextField
                    {...fieldProps}
                    margin="normal"
                    required
                    inputRef={ref}
                    label={t("login_box_mobile_nr")}
                    placeholder="eg.: +48726060603"
                    variant="outlined"
                    fullWidth
                    size="normal"
                    error={!!error}
                    helperText={error?.message || ""}
                  />
                )}
              />
            </Box>

            <ButtonGroup orientation="vertical" fullWidth>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
              >
                {t("buttons.password_reset_request")}
              </Button>
            </ButtonGroup>
          </Box>
        </CardContent>
      </form>
    </Card>
  );
};
