import React from "react";
import { useTranslation } from "react-i18next";

import { GenericContentBox } from "../../components/commons/GenericContentBox";
import { useParkings } from "../../libs/parkings";

import { ParkingsListTable } from "./components/ParkingsListTable";

export const ParkingsList = () => {
  const { t } = useTranslation();
  const parkings = useParkings();
  const breadcrumbs = React.useMemo(
    () => [
      {
        title: t("parkings.breadcrumbs.main"),
        path: "",
      },
    ],
    []
  );
  return (
    <GenericContentBox
      title={t("parkings.main_title")}
      breadcrumbs={breadcrumbs}
    >
      <ParkingsListTable data={parkings?.data?.data ?? []} />
    </GenericContentBox>
  );
};
