import { Box, Button, Checkbox, TextField } from "@material-ui/core";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { SubTypeContent } from "./SubTypeContent";
import { PricingDurationContent } from "./PricingDurationContent";

// import { usePricingState } from "../AddPricingContext";

const validationSchema = yup
  .object()
  .required()
  .shape({
    billByStartedPeriod: yup.boolean(),
    currency: yup.string().required("Waluta jest wymagana"),
    timespan: yup
      .number()
      .typeError("Okres musi mieć wartość numeryczną")
      .required("Okres jest wymagany"),
    price: yup
      .number()
      .min(0, "Cena nie może być ujemna")
      .typeError("Cena musi mieć wartość numeryczną")
      .required("Cena jest wymagana"),
    days: yup.array().of(yup.boolean()),
  });

export const SubDurationStep = ({ onClose }) => {
  // const data = usePricingState();
  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      billByStartedPeriod: false,
      currency: "PLN",
      timespan: 30,
      price: 0,
      days: [false, false, false, false, false, false, false],
    },
    criteriaMode: "all",
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(() => {
    // api submit should happen here - dodać endpoint do abonamentów !!!!!
    // console.log(data, payload);
    onClose();
  });

  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const { t } = useTranslation();
  return (
    <form noValidate onSubmit={onSubmit}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginTop="40px"
        marginBottom="30px"
        bgcolor="rgba(196, 196, 196, 0.18)"
        padding="5px 15px"
        borderRadius={8}
        fontFamily="Ubuntu"
      >
        <Box
          fontSize="20px"
          fontWeight="800"
          marginRight="10px"
          paddingRight="15px"
          fontFamily="Ubuntu"
        >
          {t("sub_setup.period")}
        </Box>
      </Box>
      <Box>
        <SubTypeContent
          parkingDuration={t("sub_setup.define_period")}
          control={control}
          label={t("sub_setup.days_period")}
        />
        <PricingDurationContent
          parkingDuration={t("sub_setup.define_price")}
          control={control}
          labelAmount={t("sub_setup.amount")}
          labelCurrency={t("sub_setup.currency")}
        />
        <Box>
          <Box paddingLeft="15px">
            <Box marginBottom="10px" fontSize="18px">
              {t("sub_setup.weekdays")}
            </Box>
            <Controller
              name="days"
              control={control}
              render={({ field: { value, onChange } }) => {
                const onUpdate = (index) =>
                  onChange([
                    ...value.slice(0, index),
                    !value[index],
                    ...value.slice(index + 1),
                  ]);
                return (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                  >
                    {[...Array(7).keys()].map((i) => (
                      <Box display="flex" flexDirection="column" key={i}>
                        <Checkbox
                          checked={value[i]}
                          onChange={() => onUpdate(i)}
                          color="primary"
                          size="medium"
                        />
                        <Box paddingLeft="10px">{weekDays[i]}</Box>
                      </Box>
                    ))}
                  </Box>
                );
              }}
            />
          </Box>
        </Box>
        <Box
          paddingBottom="20px"
          marginTop="20px"
          marginBottom="20px"
          fontSize="18px"
          fontFamily="Ubuntu"
        >
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              marginTop="40px"
              marginBottom="30px"
              bgcolor="rgba(196, 196, 196, 0.18)"
              padding="5px 15px"
              borderRadius={8}
            >
              {t("sub_setup.email_approve")}{" "}
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Controller
                name="billByStartedPeriod"
                control={control}
                render={({ field: { ref, ...fieldProps } }) => (
                  <Checkbox
                    defaultChecked
                    {...fieldProps}
                    checked={fieldProps.value}
                    color="primary"
                    size="medium"
                  />
                )}
              />
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                alignItems="center"
              >
                <Box
                  fontSize="16px"
                  fontFamily="Ubuntu"
                  width="30%"
                  display="flex"
                  marginRight="20px"
                >
                  {t("sub_setup.approve_requi_box")}
                </Box>
                <Box width="70%">
                  <Controller
                    name="e-mail"
                    control={control}
                    render={({
                      field: { ref, ...fieldProps },
                      fieldState: { invalid, error },
                    }) => (
                      <TextField
                        {...fieldProps}
                        fullWidth
                        label={t("sub_setup.label_email")}
                        variant="outlined"
                        size="small"
                        error={invalid}
                        helperText={error?.message}
                      >
                        ))
                      </TextField>
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          disabled={!formState.isValid}
          variant="contained"
          color="primary"
          size="large"
        >
          {t("buttons.save_2")}
        </Button>
      </Box>
    </form>
  );
};
