import { Box, Grid } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

import { config } from "../../libs/config";

export const ParkingEntitlementsItem = ({
  licensePlate,
  parkingSpot,
  start,
  end,
  entitlementReason,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      bgcolor="rgba(196, 196, 196, 0.18)"
      padding="20px 15px 20px 15px"
      marginBottom="5px"
      textAlign="left"
      borderRadius={8}
    >
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={2}>
          <Box fontSize="16px" color="#252733" fontWeight="800">
            <Box>{licensePlate}</Box>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box fontSize="16px" fontWeight="400" color="black">
            {parkingSpot}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box fontSize="16px" fontWeight="400" color="black">
            {format(Date.parse(start), config.DATE_TIME_FORMAT)}
          </Box>
        </Grid>
        <Grid item xs={3}>
          {end === null ? (
            <Box fontSize="16px" fontWeight="400" color="black">
              ---
            </Box>
          ) : (
            <Box fontSize="16px" fontWeight="400" color="black">
              {format(Date.parse(end), config.DATE_TIME_FORMAT)}
            </Box>
          )}
        </Grid>
        <Grid item xs={2}>
          <Box fontSize="16px">
            {t(`entitlement_reason.${entitlementReason.toLowerCase()}`)}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
